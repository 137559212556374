//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['options', 'providerLabel'],
  data() {
    return {
      values: {},
    };
  },
  methods: {
    onInput(event, option) {
      this.values[option.name] = this.values[option.name].replaceAll(
        '\\n',
        '\n',
      );
    },
  },
  mounted() {
    this.options.forEach((option) => {
      this.$set(this.values, option.name, '');
    });
  },
};
