//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['map'],
  data: () => ({
    layers: [],
  }),
  mounted() {
    this.map.on('load', () => {
      this.layers = this.map
        .getStyle()
        .layers
        .filter(
          (layer) => ![
            'mapbox-mapbox-satellite',
            'mapbox-gl-draw-cold',
            'mapbox-gl-draw-hot',
            'composite',
            'mapbox://mapbox.satellite',
          ].includes(layer.source) && layer.id !== 'background',
        )
        .map((layer) => ({
          id: layer.id,
          visible: true,
        }));
      console.log(this.layers);
    });
  },
  methods: {
    layerVisibilityChange(layerId, checked) {
      this.map.setLayoutProperty(layerId, 'visibility', checked ? 'visible' : 'none');
    },
  },
};
