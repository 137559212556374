//
//
//
//
//
//
//
//
//

import ApexChart from '@components/Charts/Apex/Default.vue';
import api from '@/api';

export default {
  components: {
    ApexChart,
  },
  data() {
    return {
      ready: false,
      series: [],
      options: {
        plotOptions: {
        },
        yaxis: {
          max: 14000,
          min: 0,
          tickAmount: 5,
          labels: {
            style: {
              fontSize: '12px',
            },
            formatter(value) {
              return `${Math.floor(value) / 1000}kW`;
            },
          },
        },
        xaxis: {
          labels: {
            datetimeUTC: false,
          },
          type: 'datetime',
          format: 'dd/MM, HH:mm',
        },
        chart: {
          // id: 'gauge',
          // group: 'dashboard-1',
          type: 'area',
        //   stacked: true,
        },
        tooltip: {
          x: {
            format: 'dd/MM, HH:mm',
          },
        },
        // title: {
        //   text: 'Leistungsabnahme nach Unternehmen',
        // },
      },
    };
  },
  async mounted() {
    const result = await api.getResource('functions/dfa_history/run');
    // console.log(result);

    this.series = [{
      name: 'Test',
      color: 'rgb(250, 180, 80)',
      data: result,
    }];
    this.ready = true;
  },
};
