//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import debounce from 'debounce';
import ActionBar from '@/components/generic/ActionBar.vue';

export default {
  data() {
    return {
      checkedRows: [],
      things: [],
      paginationPerPage: 17,
      // needed since we access $refs in computed property.
      // see https://stackoverflow.com/a/43531779
      isMounted: false,
      // state for pagination
      total: 0,
      loading: false,
      sortBy: this.$route.query.sb || 'lastupdated',
      sortOrder: this.$route.query.so || 'desc',
      defaultSortOrder: 'desc',
      page: parseInt(this.$route.query.p, 10) || 1,
      itemsPerPage: null,
      searchTerm: this.$route.query.q || '',
      hoveredRow: null,
    };
  },
  metaInfo() {
    return {
      title: 'ONE | entities',
      // ...
    };
  },
  computed: {
    thingsForTable() {
      return this.things;
    },
    paginationTotal() {
      // return this.$store.state.things.totalAmount;
      return this.things.length;
    },
    actions() {
      return [
        {
          type: 'search',
          ref: 'search',
        },
      ];
    },
  },
  watch: {
    itemsPerPage() {
      this.loadThings();
    },
  },
  methods: {
    searchTermChanged: debounce(function searchTermChanged() {
      this.searchTerm = this.$refs.actionBar.inputs.search;
      this.$router.push({
        name: 'things',
        query: { ...this.$route.query, q: this.$refs.actionBar.inputs.search },
      });
      this.loadThings();
    }, 500),
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ) {
        return true;
      }
      return false;
    },
    rowEnter(row) {
      this.hoveredRow = row;
    },
    calculateItemsPerPage: debounce(function calculateItemsPerPage() {
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      const margin = 220;
      this.itemsPerPage = Math.floor((vh - margin) / 38);
    }, 150),
    async loadThings() {
      this.loading = true;
      const result = await this.$store.dispatch('things/getMultiple', {
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
        offset: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        includesText: this.$refs.actionBar.inputs.search,
      });
      this.loading = false;
      this.things = result.things;
      this.total = result.total;
    },
    onPageChange(page) {
      this.page = page;
      this.$router.push({
        name: 'things',
        query: { ...this.$route.query, p: page },
      });
      this.loadThings();
    },
    onSort(field, order) {
      this.sortBy = field;
      this.sortOrder = order;
      this.$router.push({
        name: 'things',
        query: { ...this.$route.query, sb: field, so: order },
      });
      this.loadThings();
    },
    deleteCheckedRows() {
      if (this.checkedRows.length === 0) return;

      // // ask the user how to continue
      // const answer = window.confirm(
      //   `Are you sure to delete these ${this.checkedRows.length} things?`
      // );

      // // check his answer and act accordingly
      // if (!answer) return;

      this.loading = true;

      const ids = this.checkedRows.map((row) => row.id);

      this.$store.dispatch('things/delete', ids).then(() => {
        this.loading = false;
        this.checkedRows = [];
        this.loadThings();
      });
    },
    tableClick(row) {
      this.$router.push({ name: 'thing', params: { id: row.id } });
    },
    keyDownHandler(e) {
      if (e.ctrlKey && e.key === 'f') {
        this.$refs.actionBar.focus('search');
        e.preventDefault();
      }
    },
  },
  mounted() {
    this.calculateItemsPerPage();
    window.addEventListener('resize', this.calculateItemsPerPage);
    window.addEventListener('keydown', this.calculateItemsPerPage);
    this.isMounted = true;
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
    window.removeEventListener('keydown', this.keyDownHandler);
  },
  components: {
    ActionBar,
  },
};
