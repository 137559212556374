//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AddDeviceForm from './AddDeviceForm.vue';
import Drawer from './Drawer/Drawer.vue';
import { colorGradient } from '../util';

const DEVICE_ID_TODO_REPLACE = 'c585acef9495';
export default {
  methods: {
    addDeviceModalSuccess(addedDevices) {
      this.isAddDeviceModalActive = false;
      this.addedDevices = new Set([...this.addedDevices, ...addedDevices]);
    },
    polygonAdded(newPolygon) {
      const polygon = newPolygon;
      if (this.mode === 'door') {
        polygon.config.stroke = '#8B4513';
        polygon.config.strokeWidth = 3;
        this.doorPolygon = {
          config: polygon.config,
          boundTo: DEVICE_ID_TODO_REPLACE,
        };
        [this.doorPolygon.doorOriginalX, this.doorPolygon.doorOriginalY] = polygon.config.points;
        const center = {
          x: this.doorPolygon.config.points[2],
          y: this.doorPolygon.config.points[3],
        };
        const point = {
          x: this.doorPolygon.config.points[0],
          y: this.doorPolygon.config.points[1],
        };
        const angle = 90 * (Math.PI / 180);
        this.doorPolygon.rotatedX = Math.cos(angle) * (point.x - center.x)
          - Math.sin(angle) * (point.y - center.y)
          + center.x;

        this.doorPolygon.rotatedY = Math.sin(angle) * (point.x - center.x)
          + Math.cos(angle) * (point.y - center.y)
          + center.y;
      }
    },
    selectTool(tool) {
      if (tool === 'door') {
        this.mode = 'door';
        this.$refs.drawer.selectTool('polygon');
      } else if (tool === 'thermo') {
        if (DEVICE_ID_TODO_REPLACE in this.$store.state.devices.byId) {
          this.$refs.drawer.addObject('text', {
            x: 10,
            y: 15,
            text: `${Math.round(
              this.$store.state.devices.byId[DEVICE_ID_TODO_REPLACE].state.temperature * 100,
            ) / 100} °C`,
            fontSize: 20,
            fontFamily: 'Verdana',
            fill: colorGradient(
              (this.$store.state.devices.byId[DEVICE_ID_TODO_REPLACE].state.temperature - 16)
                / (32 - 16),
              { red: 0, green: 0, blue: 255 },
              { red: 255, green: 0, blue: 0 },
            ),
            draggable: true,
          });
        }
        this.$refs.drawer.selectTool('pointer');
      } else {
        this.mode = '';
        this.$refs.drawer.selectTool(tool);
      }
    },
    save() {
      this.$store.dispatch('plans/save', this.$refs.drawer.objects);
    },
  },
  mounted() {
    this.$store.dispatch('plans/sync').then((data) => {
      this.$refs.drawer.objects = data;
    });
    this.$store.subscribe((mutation) => {
      if (this.doorPolygon === null) return;
      this.doorPolygon.config.x = 0;
      if (mutation.type === 'devices/setDeviceInternalStateAttributeById') {
        if (this.doorPolygon.boundTo !== mutation.payload.deviceId) return;
        if (mutation.payload.value === 'off') {
          this.doorPolygon.config.points[0] = this.doorPolygon.rotatedX;
          this.doorPolygon.config.points[1] = this.doorPolygon.rotatedY;
        } else if (mutation.payload.value === 'on') {
          this.doorPolygon.config.points[0] = this.doorPolygon.doorOriginalX;
          this.doorPolygon.config.points[1] = this.doorPolygon.doorOriginalY;
        }
        this.$refs.drawer.draw();
      }
    });
  },
  components: { AddDeviceForm, Drawer },
  data() {
    return {
      isAddDeviceModalActive: false,
      addedDevices: new Set(),
      mode: '',
      doorPolygon: null,
    };
  },
};
