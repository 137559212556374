//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import LineChart from '@components/Charts/Line';
// import { DateTime } from 'luxon';
import axios from 'axios';
import api from '@/api';
import graphUtils from './graphs';

const chargingData = [
  {
    t: 0,
    y: 0,
  },
  {
    t: 2,
    y: 22000,
  },
  {
    t: 5,
    y: 0,
  },
  {
    t: 9,
    y: 22000,
  },
  {
    t: 9,
    y: 36000,
  },
  {
    t: 13,
    y: 36000,
  },
  {
    t: 13,
    y: 36000,
  },
  {
    t: 16,
    y: 0,
  },
  {
    t: 24,
    y: 0,
  },
];

export default {
  props: ['thing'],
  components: {
    // LineChart,
  },
  mounted() {
    this.makeGraph();
  },
  watch: {
    async thing() {
      console.log('thing changed');
      console.log(this.thing);
      this.makeGraph();
    },
  },
  methods: {
    async deleteEntity() {
      await api.deleteResource(`things/${this.thing.id}`);
      this.$emit('deleted');
    },
    async makeGraph() {
      switch (this.thing.type) {
        case 'car':
          {
            this.options = graphUtils.makeOptions((value) => `${value} L`, 1);
            const property = 'remaining_fuel';
            let history = await axios.post(
              'https://1src.tech/hasura/v1/graphql',
              graphUtils.makeThingHistoryQuery(this.thing.id, property),
            );
            history = history.data.data.thing_state_history;
            this.datacollection.datasets[0].data = history.map((point) => ({
              t: Date.parse(point.time),
              y: point.value,
            }));
            this.datacollection.datasets[0].label = 'Tankladung';
          }
          break;

        default:
          this.datacollection.datasets[0].data = chargingData;
          this.options = graphUtils.makeOptions((value) => `${value / 1000} kWh`, 1);
          break;
      }
    },
  },
  data() {
    return {
      datacollection: {
        datasets: [
          {
            label: 'Ladeleistung',
            backgroundColor: 'rgba(250, 115, 0, 0.7)',
            steppedLine: true,
            data: chargingData,
          },
        ],
      },
      options: graphUtils.makeOptions((value) => `${value} L`, 1),
    };
  },
};
