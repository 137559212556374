//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MonacoEditor from 'vue-monaco';

import debounce from 'debounce';
// import LogTable from '../generic/LogTable.vue';
import LiveTable from '@components/generic/LiveTable.vue';
import api from '@/api';
import { ProcessLogHistory } from '../../queries';

export default {
  components: {
    MonacoEditor,
    // LogTable,
    LiveTable,
  },
  async mounted() {
    this.app = await api.getApp(this.$route.params.id);
    this.code = this.app.content;
    this.$nextTick(() => { this.inSync = true; });
  },
  // sockets: {
  //   processLogs(payload) {
  //     // TODO: subscribe based on app id
  //     if (String(this.$route.params.id) === String(payload.processid)) {
  //       this.logs.push(payload);
  //       this.$nextTick(() => {
  //         this.$refs.logTable.$el.scrollTop = this.$refs.logTable.$el.scrollHeight;
  //       });
  //     }
  //   },
  // },
  computed: {
    query() {
      const context = this;
      return ProcessLogHistory({
        queryVariables() {
          return {
            appid: context.$route.params.id,
          };
        },
        subscriptionVariables() {
          return {
            appid: context.$route.params.id,
            minTime: new Date().toISOString(),
          };
        },
      });
    },
  },
  watch: {
    code() {
      this.inSync = false;
      console.log('code changed');
    },
  },
  data() {
    return {
      code: null,
      inSync: true,
      syncing: false,
      app: null,
      logs: [],
    };
  },
  methods: {
    async save() {
      this.syncing = true;
      await this.$store.dispatch('apps/patch', {
        id: this.$route.params.id,
        patch: {
          content: this.code,
        },
      });
      this.syncing = false;
      this.inSync = true;
    },
    onInput: debounce(async function onInput(event) {
      console.log(this);
      await this.$store.dispatch('apps/patch', {
        id: this.$route.params.id,
        patch: {
          name: event.target.textContent,
        },
      });
    }, 500),
  },
};
