// @ts-ignore
import Vue from 'vue';
import { createElement } from '../domUtil.ts';
window.__editorNumVueControls = 0;
const EventEmitter = require('events');
export default class VueControl extends EventEmitter {
    constructor(component) {
        super();
        this._map = null;
        this.vueInstance = null;
        this._component = component;
        this._container = createElement('div', 'mapboxgl-ctrl vue-ctrl');
        this._layerId = `vue-instance-${window.__editorNumVueControls}`;
        window.__editorNumVueControls += 1;
        // create submenu
        createElement('div', 'vue-instance', this._container, this._layerId);
    }
    onAdd(map) {
        Vue.nextTick(() => {
            this.vueInstance = new Vue({
                data: () => ({
                    ...this._component.data,
                    selectedMesh: null,
                }),
                ...this._component,
                propsData: {
                    ...this._component.propsData, map,
                },
            });
            this.vueInstance.$mount(`#${this._layerId}`);
        });
        return this._container;
    }
    onRemove() {
        this.vueInstance?.$destroy();
        this._container?.parentNode?.removeChild(this._container);
    }
}
