import ChartJS from './ChartJS.vue';
import ApexCharts from './ApexCharts.vue';
import Text from './Text.vue';
import Canvas from './Canvas.vue';
import ChargHis from './ChargHist.vue';
import Gauge from './Gauge.vue';
import TransactionStats from './TransactionStats.vue';
import TransactionList from './TransactionList.vue';
import DFAStats from './DFAStats.vue';
import ZumtobelStats from './ZumtobelStats.vue';
import SmartChargingPower from './SmartChargingPower.vue';

export default {
  dashboardItemTypes: [
    { icon: 'map', text: 'Karte', type: 'mapbox' },
    { icon: 'chart-line', text: 'ChartJS', type: 'chart' },
    { icon: 'canvas', text: 'Canvas', type: 'canvas' },
    { icon: 'sign-text', text: 'Text', type: 'text' },
    { icon: 'rectangle-outline', text: 'ChargHis', type: 'chargHis' },
    { icon: 'rectangle-outline', text: 'Platzhalter', type: 'whitespace' },
    { icon: 'progress-check', text: 'Nachricht mit Status', type: 'message' },
    { icon: 'progress-check', text: 'DFA', type: 'dfaStats' },
    { icon: 'progress-check', text: 'Zumtobel', type: 'zumtobelStats' },
    { icon: '', text: 'Smart Charging Power', type: 'smartChargingPower' },
    { icon: '', text: 'ApexCharts', type: 'apexCharts' },
  ],
  components: {
    apexCharts: ApexCharts,
    chart: ChartJS,
    text: Text,
    canvas: Canvas,
    chargHis: ChargHis,
    gauge: Gauge,
    transactionStats: TransactionStats,
    transactionList: TransactionList,
    dfaStats: DFAStats,
    zumtobelStats: ZumtobelStats,
    smartChargingPower: SmartChargingPower,
  },
};
