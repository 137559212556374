//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    login() {
      this.$store.dispatch('login', {
        name: this.$data.name,
        password: this.$data.password,
      });
    },
    loginExternal() {
      if (!this.$msal.isAuthenticated()) {
        this.$msal.signIn();
      }
    },
  },
  data() {
    return {
      name: '',
      password: '',
    };
  },
};
