//
//
//
//
//
//
//
//
//
//

import ApexChart from '@components/Charts/Apex/Default.vue';
// import gql from 'graphql-tag';
// import { DateTime } from 'luxon';
// import Chartist from 'chartist';

export default {
  props: ['title'],
  components: {
    ApexChart,
  },
  data() {
    return {
      ready: true,
      series: [77, 73],
      options: {
        colors: ['#5ab25b', '#ffbc59'],
        dataLabels: {
          dropShadow: {
            enabled: true,
          },
          textAnchor: 'start',
          style: {
            fontSize: '22px',
            fontFamily: 'Arial',
            fontWeight: '300',
            // colors: ['black'],
          },
          background: {
            enabled: true,
            foreColor: 'black',
          },
          enabled: true,
          formatter(val, opts) {
            // console.log(opts);
            switch (opts.seriesIndex) {
              case 0: return [`${(Math.floor(val) / 100) * 150} kWh`, 'in Verwendung'];
              case 1: default: return [`${(Math.floor(val) / 100) * 150} kWh`, 'noch verfügbar'];
            }
          },

        },
        chart: {
          // id: 'gauge',
          // group: 'dashboard-1',
          type: 'donut',
        },
        // fill: {
        //   type: 'gradient',
        // },
        title: {
          text: 'Leistungsverfügbarkeit',
        },
        labels: ['In Verwendung', 'Noch verfügbar'],
        yaxis: {
          min: 0,
          max: 1,
          labels: {
            style: {
              fontSize: '12px',
            },
            formatter(value) {
              return `${Math.floor(value * 100)}%`;
            },
          },
        },
      },
    };
  },
  mounted() {
  },
};
