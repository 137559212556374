//
//
//
//
//
//
//
//
//
//
//

import ApexChart from '@components/Charts/Apex/Default.vue';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import Chartist from 'chartist';

export default {
  props: ['title'],
  components: {
    ApexChart,
  },
  data() {
    return {
      ready: false,
      series: [],
      datacollection: {
        datasets: [],
      },
      refreshFinalPointInterval: () => {},
      options: {
        chart: {
          id: 'charging',
          group: 'dashboard-1',
          type: 'area',
        },
        title: {
          text: 'Parkraumauslastung',
        },
        annotations: {
          position: 'back',
          yaxis: [
            {
              label: {
                text: '',
                position: 'left',
              },
              y: 0,
              y2: 0.75,
              opacity: 0.2,
              fillColor: 'green',
            },
            {
              label: {
                text: '',
                position: 'left',
              },
              y: 0.75,
              y2: 0.9,
              opacity: 0.1,
              fillColor: 'yellow',
            },
            {
              label: {
                text: '',
                position: 'left',
              },
              y: 0.9,
              y2: 1,
              opacity: 0.1,
              fillColor: 'red',
            },
          ],
        },
        yaxis: {
          min: 0,
          max: 1,
          labels: {
            style: {
              fontSize: '12px',
            },
            formatter(value) {
              return `${Math.floor(value * 100)}%`;
            },
          },
        },
      },
    };
  },
  destroyed() {
    clearInterval(this.refreshFinalPointIntervalId);
  },
  mounted() {
    const data = {
      // A labels array that can contain any sort of values
      labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
      // Our series array that contains series objects or in this case series data arrays
      series: [
        [5, 2, 4, 2, 0],
      ],
    };

    // Create a new line chart object where as first parameter we pass in a selector
    // that is resolving to our chart container element. The Second parameter
    // is the actual data object.
    Chartist.Line('.ct-chart', data);

    this.series.push({
      name: 'Parkplatzauslastung',
      data: [],
    });

    this.datacollection = {
      datasets: [
        {
          label: 'Anzahl ',
          backgroundColor: '#323553A3',
          steppedLine: true,
          data: [],
        },
      ],
    };

    // const startDate = DateTime.fromISO('2020-11-18T10:05:09+00:00');
    const startDate = DateTime.local().minus({ days: 7 });

    const endDate = DateTime.local(); // .set({ hour: 20 });

    const setFinalPoint = () => {
      const mostRecent = this.datacollection.datasets[0].data[
        this.datacollection.datasets[0].data.length - 1
      ];
      this.datacollection.datasets[0].data.push({
        t: Date.parse(DateTime.local().toISO()),
        y: mostRecent.y,
      });
    };

    const removeFinalPoint = () => {
      this.datacollection.datasets[0].data.pop();
    };

    const updateFinalPoint = () => {
      removeFinalPoint();
      setFinalPoint();
      this.$refs.chart.syncChart();
    };

    this.refreshFinalPointIntervalId = setInterval(
      () => updateFinalPoint(),
      1000,
    );

    this.$apollo.addSmartQuery('bla', {
      query() {
        // from provided query
        return gql(`query MyQuery {
            thing_state_history(where: {time: {_gte: "${startDate.toISO()}", _lte: "${endDate.toISO()}"}, property: {_eq: "freeParking"}, thing: {providedby: {_eq: 36}}}, order_by: {time: asc}) {
              value
              time
            }
            thing_state_history_aggregate(where: {time: {_lt: "${startDate.toISO()}"}, property: {_eq: "freeParking"}, thing: {providedby: {_eq: 36}}, value: {}}, distinct_on: [thingid, value], order_by: {thingid: asc, value: asc, time: desc}) {
              nodes {
                value
                thingid
                time
              }
            }
          }`);
      },
      subscribeToMore: {
        document: gql(`subscription MyQuery {
              thing_state_history(where: {time: {_gte: "${startDate.toISO()}"}, property: {_eq: "freeParking"}, thing: {providedby: {_eq: 36}}}, order_by: {time: desc}, limit: 1) {
                value
                time
              }
            }
          `),
        // Mutate the previous result
        updateQuery: (previousResult, update) => {
          if (this.datacollection.datasets[0].data.length === 0) return;
          removeFinalPoint();
          const newItem = update.subscriptionData.data.thing_state_history[0];
          const mostRecent = this.datacollection.datasets[0].data[
            this.datacollection.datasets[0].data.length - 1
          ];
          this.datacollection.datasets[0].data.push({
            t: Date.parse(newItem.time),
            y: mostRecent.y + (newItem.value === 0 ? -1 : 1),
          });
          setFinalPoint();
          this.$refs.chart.syncChart();
        },
      },
      result(result) {
        console.log('result called');
        // eslint-disable-next-line
        const stateToReconstruct =
          result.data.thing_state_history_aggregate.nodes;
        const things = [];
        for (let i = 0; i < stateToReconstruct.length - 1; i += 2) {
          // console.log(stateToReconstruct);
          // eslint-disable-next-line
          const moreRecent =
            // eslint-disable-next-line
            Date.parse(stateToReconstruct[i].time) >
            Date.parse(stateToReconstruct[i + 1].time)
              ? stateToReconstruct[i]
              : stateToReconstruct[i + 1];
          things.push(moreRecent);
        }
        let sum = things
          .map((thing) => thing.value)
          .reduce((prev, curr) => prev + curr, 0);
        sum = 24 - sum;
        // eslint-disable-next-line
        this.datacollection.datasets[0].data.push({
          t: Date.parse(startDate.toISO()),
          y: sum / 24,
        });
        const deltas = result.data.thing_state_history;
        // let inDeadRange = false;
        deltas.forEach(({ value, time }) => {
          if (value === 0) sum += 1;
          else sum -= 1;
          // const timeParsed = DateTime.fromISO(time);

          // // eslint-disable-next-line max-len
          // if (false) {
          //   if (!inDeadRange) {
          //     this.datacollection.datasets[0].data.push({ t: Date.parse(time), y: 0 });
          //     inDeadRange = true;
          //   }
          // } else {
          // inDeadRange = false;
          this.datacollection.datasets[0].data.push({
            t: Date.parse(time),
            y: sum / 24,
          });
          this.series[0].data.push({
            x: time,
            y: sum / 24,
          });
          // }
        });
        setFinalPoint();
      },
      update() {
        console.log('updated');
        return {};
        // return getDescendantProp(data, query.path);
      },
      // subscribeToMore: {
      //   document: gql(query.subscription),
      //   // Mutate the previous result
      //   updateQuery: (previousResult, { subscriptionData }) => subscriptionData.data,
      // },
    });
  },
};
