// initial state
const persistedState = {
  mouseX: 0,
  mouseY: 0,
};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  setMouseXY(state, { x, y }) {
    state.mouseX = x;
    state.mouseY = y;
  },
  mouseClicked() {
    // dummy mutation to activate subscribers :-)
    // not sure if that's the best way to do it.
  },
};

export default {
  namespaced: true,
  state: persistedState,
  getters,
  actions,
  mutations,
};
