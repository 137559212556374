//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api';

export default {
  data() {
    return {
      executing: false,
      result: null,
      error: null,
    };
  },
  methods: {
    async runFunction() {
      this.executing = true;
      this.result = null;
      this.error = null;
      try {
        this.result = await api.createFunction(this.functionPath);
        this.executing = false;
        this.$emit('close');
        this.$router.push({
          name: 'function',
          params: { path: this.functionPath },
        });
      } catch (err) {
        this.executing = false;
        this.error = err.message;
      }
    },
  },
};
