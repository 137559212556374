//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['actions', 'value'],
  data() {
    return {
      inputs: {
        search: this.value?.search || '',
      },
    };
  },
  methods: {
    focus(ref) {
      if (ref in this.$refs) this.$refs[ref][0].focus();
    },
  },
};
