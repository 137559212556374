export default {
  endpoints: {
    websocket: 'wss://1src.tech',
    api: 'https://1src.tech/',
    static_assets: 'https://static.1src.tech',
  },
  api: {
    defaultOnError: process.env.NODE_ENV === 'development',
  },
};
