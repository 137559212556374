//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-unreachable */
import ApexChart from '@components/Charts/Apex/Default.vue';
import api from '@/api';

export default {
  components: {
    ApexChart,
  },
  props: ['options'],
  data() {
    return {
      ready: false,
      series: [],
      graphOptions: {
        colors: ['#d00000', '#dc2f02', '#e85d04', '#f48c06', '#faa307', '#ffba08'],
        theme: {
          mode: 'light',
          // monochrome: {
          //   enabled: true,
          //   color: '#FAA307',
          //   shadeTo: 'dark',
          //   shadeIntensity: 1,
          // },
        },
        plotOptions: {
        },
        legend: {
          show: true,
          floating: true,
          position: 'top',
        },
        yaxis: {
          //   tickAmount: 10,
          // max: 45,
          min: 0,
          tickAmount: 5,
          labels: {
            style: {
              fontSize: '12px',
            },
            formatter(value) {
              return `${Math.floor(value)}W`;
            },
          },
        },
        tooltip: {
          x: {
            format: 'dd/MM, HH:mm',
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC: false,
          },
        },
        chart: {
          // id: 'gauge',
          // group: 'dashboard-1',
          type: 'area',
          animations: {
            enabled: true,
            dynamicAnimation: {
              enabled: false,
            },
          },
          //   stacked: true,
        },
        // title: {
        //   text: 'Leistungsabnahme nach Unternehmen',
        // },
      },
    };
  },
  watch: {
    options() {
      console.log('options changed');
    },
  },
  methods: {
    beforeZoom(_chart, { xaxis }) {
      console.log('beforeZoom', xaxis);
      // const now = Date.now();
      return {
        xaxis: {
          min: xaxis.min,
          max: xaxis.max > Date.now() ? Date.now() : xaxis.max,
        },
      };
      // await this.refreshDataFromSource(xaxis.min, xaxis.max);
    },
    zoomed(_chart, { xaxis }) {
      this.refreshDataFromSource(xaxis.min, xaxis.max);
    },
    async refreshDataFromSource(timeMin, timeMax) {
      const result = await api.getResource(`functions/${this.options.datasource}/run`, { timeMin, timeMax });
      this.series = result;
    },
  },
  async mounted() {
    await this.refreshDataFromSource();
    this.ready = true;
  },
};
