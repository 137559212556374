//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint import/no-unresolved: 0 */
import { mapState } from 'vuex';
import ThingAndPropertySelector from '@components/generic/ThingAndPropertySelector.vue';
import ThingAndMethodSelector from '@components/generic/ThingAndMethodSelector.vue';

import ValueEnumSelector from '@components/generic/ValueEnumSelector.vue';
import ValueNumberInput from '@components/generic/ValueNumberInput.vue';
import ValueTextInput from '@components/generic/ValueTextInput.vue';
import MethodArgumentsSelector from '@components/generic/MethodParametersSelector.vue';
import EditableText from '@components/generic/EditableText.vue';

const triggerTypes = {
  stateChangeTo: {
    label: 'Attributwert ändert sich auf...',
    sublabel: 'Attributwert ändert sich',
    icon: 'redo',
    parameters: {
      thingAndProperty:
      {
        label: 'Eigenschaft?',
        type: 'thingAndProperty',
      },
      changesTo: {
        label: 'Wert?',
        type: 'propertyValueSelect',
        for: 'thingAndProperty',
      },
    },
  },
  stateChangeFromTo: {
    label: 'Attributwert ändert sich von ... auf...',
    sublabel: 'Attributwert ändert sich',
    icon: 'redo',
    parameters: {
      thingAndProperty: {
        label: 'Eigenschaft?',
        type: 'thingAndProperty',
      },
      changesFrom: {
        label: 'Von?',
        type: 'propertyValueSelect',
        for: 'thingAndProperty',
      },
      changesTo: {
        label: 'Auf?',
        type: 'propertyValueSelect',
        for: 'thingAndProperty',
      },
    },
  },
  proximity: {
    label: 'Örtliche Annäherung',
    sublabel: 'Annäherung',
    icon: 'map-marker-distance',
  },
};

const actionTypes = {
  runMethod: {
    label: 'Methode ausführen',
    icon: 'arrow-right-bold-hexagon-outline',
    parameters: {
      thingAndMethod: {
        label: 'Methode?',
        type: 'thingAndMethod',
      },
      methodArguments: {
        label: 'Arguments?',
        type: 'methodArgumentsSelect',
        for: 'thingAndMethod',
      },
    },
  },
  proximity: {
    label: 'Zustand ändern',
    icon: 'file-document-edit-outline',
  },
};

export default {
  components: {
    ThingAndPropertySelector,
    ThingAndMethodSelector,
    ValueEnumSelector,
    ValueNumberInput,
    ValueTextInput,
    MethodArgumentsSelector,
    EditableText,
  },
  props: ['id'],
  data() {
    return {
      trigger: {
        type: '',
        arguments: {},
      },
      triggerTypes,
      action: {
        type: '',
        arguments: {},
      },
      actionTypes,
      refs: this.$refs,
      name: '',
      test: {},
    };
  },
  methods: {
    selectTrigger(type) {
      this.trigger.type = type;
    },
    selectAction(type) {
      this.action.type = type;
    },
    nameChanged(name) {
      this.name = name;
      this.$store.dispatch('actions/setName', {
        id: this.id,
        name,
      });
    },
    dispatch() {
      this.$store.dispatch('actions/dispatch', {
        trigger: this.trigger,
        action: this.action,
        id: this.id,
      });
    },
  },
  mounted() {
    if (!this.id) return;

    // TODO: is there a better way?
    const clonedAction = JSON.parse(
      JSON.stringify(this.$store.state.actions.byId[this.id]),
    );

    this.trigger = clonedAction.trigger;
    this.action = clonedAction.action;
    this.name = clonedAction.name;
  },
  computed: {
    ...mapState('providers', {
      providers: (state) => state.blueprints,
    }),
  },
};
