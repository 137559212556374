//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api';

export default {
  props: ['thingid'],
  data() {
    return {
      executing: false,
      result: null,
      error: null,
      state: {
        property: null,
        value: null,
        datatype: null,
      },
    };
  },
  methods: {
    async insertState() {
      this.executing = true;
      this.result = null;
      this.error = null;
      // console.log(
      //   this.connectorType,
      //   this.connectorLabel,
      //   this.connectorParameters,
      // );
      if (this.state.datatype === 'object') this.state.value = JSON.parse(this.state.value);
      try {
        this.result = await api.postResource(
          `things/${this.thingid}/state`,
          {
            ...this.state,
            forceInsert: true,
          },
        );
        this.executing = false;
      } catch (err) {
        this.executing = false;
        this.error = err.message;
      }
      if (this.state.datatype === 'object') this.state.value = JSON.stringify(this.state.value);
    },
  },
};
