// directive to change width of inputs dynamically based on content
// @ts-ignore
// ECharts
// @ts-ignore
import 'echarts/lib/chart/line';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/polar';
// Buefy / Bulma
// import "buefy/dist/buefy.css";
import 'vue-resize/dist/vue-resize.css';
import Vue from 'vue';
import moment from 'moment';
import { Chart } from 'chart.js';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { split } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
// Microsoft Authentification Library
// import msal from 'vue-msal';
// Vuex store
// @ts-ignore
import Buefy from 'buefy';
// @ts-ignore
import L from 'leaflet';
import VueApollo from 'vue-apollo';
// @ts-ignore
import ECharts from 'vue-echarts'; // refers to components/ECharts.vue in webpack
// @ts-ignore
import VueInputAutowidth from 'vue-input-autowidth';
// a
// canvas drawing
import VueKonva from 'vue-konva';
// import "./importPNG.d.ts";
// import iconRetinaUrl from './assets/markers/marker-icon.png';
// @ts-ignore
import VueResize from 'vue-resize';
import VueRouter from 'vue-router';
import Trend from 'vuetrend';
import VueApexCharts from 'vue-apexcharts';
// import * as Sentry from '@sentry/browser';
// import { Integrations } from '@sentry/tracing';
import VueMeta from 'vue-meta';
import { audience, clientId, domain } from '../auth_config.json';
// Import the plugin here
import { Auth0Plugin } from './auth';
// main component
// @ts-ignore
import App from './components/App.vue';
// SPA routing
// @ts-ignore
import routes from './routes';
// socketio
// @ts-ignore
import initializeSocketIO from './socketio';
// @ts-ignore
import store from './store';
import populateStore from './store/populateStore';
Vue.use(VueApexCharts);
Vue.use(VueMeta);
// monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
//   target: monaco.languages.typescript.ScriptTarget.ES2016,
//   allowNonTsExtensions: true,
//   moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
//   module: monaco.languages.typescript.ModuleKind.CommonJS,
//   noEmit: true,
// });
Chart.pluginService.register({
    id: 'threshold',
    // eslint-disable-next-line
    afterDraw: (chart) => {
        if (chart.chart.options?.plugins?.threshold?.enabled !== true)
            return;
        chart.chart.ctx.restore();
        chart.chart.ctx.beginPath();
        // eslint-disable-next-line
        chart.chart.ctx.lineWidth = 2;
        // eslint-disable-next-line
        chart.chart.ctx.strokeStyle = '#fc7979';
        chart.chart.ctx.moveTo(45, 134);
        chart.chart.ctx.lineTo(10000, 134);
        chart.chart.ctx.stroke();
    },
});
// Sentry.init({
//   Vue,
//   dsn: 'https://d0d1fcf50f714d9d9c39d51db1a77bd3@o485713.ingest.sentry.io/5541424',
//   integrations: [new Integrations.BrowserTracing()],
//   tracingOptions: {
//     trackComponents: true,
//   },
//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });
// moment.js
moment.locale('de');
// moment.locale('en');
Vue.prototype.moment = moment;
// register components and directives etc.
Vue.component('v-chart', ECharts);
Vue.component('apexchart', VueApexCharts);
Vue.use(Buefy);
Vue.use(VueApollo);
Vue.use(VueKonva);
Vue.use(VueRouter);
Vue.use(VueInputAutowidth);
Vue.use(VueResize);
Vue.use(Trend);
// HTTP connection to the API
const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: 'https://1src.tech/hasura/v1/graphql',
});
// Create the subscription websocket link
const wsLink = new WebSocketLink({
    uri: 'wss://1src.tech/hasura/v1/graphql',
    options: {
        reconnect: true,
    },
});
// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
// split based on operation type
({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
}, wsLink, httpLink);
// Cache implementation
const cache = new InMemoryCache();
// Create the apollo client
const apolloClient = new ApolloClient({
    link,
    cache,
    connectToDevTools: true,
});
const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
});
// Vue.use(msal, {
//   auth: {
//     clientId: '045605f9-e21e-47f2-adf4-7cd3663e4d75',
//     authority: 'https://login.microsoftonline.com/41ac4295-1834-4fc1-a321-ba6aff134ffa/',
//     redirectUri: process.env.NODE_ENV === 'development' ? 'http://localhost:8000/auth-return' : 'https://1src.tech/auth-return',
//   },
//   request: {
//     scopes: ['user.read', 'Calendars.Read', 'Calendars.ReadWrite'],
//   },
// });
initializeSocketIO();
const router = new VueRouter({
    routes,
    mode: 'history',
});
// Install the authentication plugin here
Vue.use(Auth0Plugin, {
    domain,
    clientId,
    audience,
    onCreated: async () => {
        // if (Vue.prototype.$auth.isAuthenticated) {
        //   const token = await Vue.prototype.getTokenSilently();
        //   const { data } = await axios.get('https://1src.tech/api-secured/authorized', {
        //     headers: {
        //       Authorization: `Bearer ${token}`,
        //     },
        //   });
        // }
        if (Vue.prototype.$auth.isAuthenticated) {
            populateStore(store);
        }
        else {
            // nothing to populate
            store.commit('bootstrap/setIsInitializing', false);
        }
    },
    onRedirectCallback: (appState) => {
        router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
    },
    postAuthCallback: () => {
        populateStore(store);
    },
});
// make viewport on mobile work properly
window.addEventListener('resize', () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});
// https://github.com/Leaflet/Leaflet/issues/4968
// https://github.com/vue-leaflet/Vue2Leaflet/issues/28#issuecomment-320227460
(async () => {
    // @ts-ignore
    // eslint-disable-next-line
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: (await import('leaflet/dist/images/marker-icon-2x.png')).default,
        iconUrl: (await import('leaflet/dist/images/marker-icon.png')).default,
        shadowUrl: (await import('leaflet/dist/images/marker-shadow.png')).default,
    });
})();
const vueInstance = new Vue({
    store,
    router,
    apolloProvider,
    // eslint-disable-next-line
    render: h => h(App),
});
vueInstance.$mount('#app');
