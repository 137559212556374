import Vue from 'vue';

import api from '@/api';
import { arrayToObject } from '@/util';

// initial state
const persistedState = {
  things: [],
  byId: {},
  drafts: {},
  error: null,
  committing: false,
  changesNotCommitedYet: false,
  loading: true,
  totalAmount: 0,
};

// getters
const getters = {};

// actions
const actions = {
  sync({ commit }) {
    return api
      .getThings()
      .then((response) => {
        commit('setThings', response.things);
        commit('setThingsTotal', response.total);
        commit('loadingFinished');
      })
      .catch((error) => commit('setError', error));
  },
  getOne(operations, id) {
    return api
      .getThing(id);
    // .then((response) => {

    //   // TODO: cache
    //   // commit('setThing', response.things);
    // })
    // .catch((error) => commit('setError', error));
  },
  getMultiple(operations, params) {
    return api
      .getThings(params);
  },
  delete({ dispatch }, ids) {
    return api.deleteThings(ids).then(() => dispatch('sync'));
  },
  runMethod(_, { id, name }) {
    // TODO: track status through websockets
    // commit('setMethodStatus', { id, name, running: true });
    return api.runThingMethod(id, name);
  },
  getThingState({ commit }, id) {
    return new Promise((resolve, reject) => api
      .getThingState(id)
      .then((state) => {
        commit('setThingState', { id, newState: state });
        resolve(state);
      })
      .catch((error) => reject(error)));
  },
  pushNewThing({ commit, dispatch }, thing) {
    commit('setCommitting', true);
    return new Promise((resolve, reject) => {
      api
        .pushThing(thing)
        .finally(() => commit('setCommitting', true))
        .then((newThing) => {
          // commit("pushThing", thing);
          dispatch('sync').then(() => {
            commit('setChangesNotCommitedYet', false);
          });

          resolve(newThing);
        })
        .catch((error) => {
          commit('setError', error);
          reject(error);
        });
    });
  },
  applyDrafts({ commit, state }) {
    return new Promise((resolve, reject) => {
      const promises = [];
      state.drafts.forEach((id) => {
        promises.push(
          api.putThing(id, state.drafts[id]).then(() => {
            commit('applyChange', { id, change: state.drafts[id] });
          }),
        );
        Promise.all(promises)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  },
  setStateAttribute({ dispatch }, change) {
    return api.setStateAttribute(change).then(() => dispatch('getThingState'));
  },
};

// mutations
const mutations = {
  /*
   * 'things' is an array of things in the form above
   */
  setThings(state, things) {
    // TODO: rename to "state.byIndex"
    state.things = things;
    state.things.sort((a, b) => a.id - b.id);
    state.byId = arrayToObject(state.things, 'id');
  },
  setThingsTotal(state, total) {
    state.totalAmount = total;
  },
  updateDraft(state, { id, key, value }) {
    if (typeof state.drafts[id] === 'undefined') state.drafts[id] = {};
    state.drafts[id][key] = value;
    console.log(state.drafts[id]);
  },
  setThingState(state, { id, newState }) {
    const thing = state.things.find((currentThing) => currentThing.id === id);
    if (thing !== undefined) thing.state = newState;
    else console.log(`tried to set state on non-present thing with id ${id}`);
  },
  applyChange(state, { id, change }) {
    const thing = state.things.find((currentThing) => currentThing.id === id);
    if (typeof thing === 'undefined') return;
    Object.keys(change).forEach((key) => {
      thing[key] = change[key];
    });
  },
  setMethodStatus(state, { id, name, running }) {
    const thing = state.things.find((currentThing) => currentThing.id === id);
    if (typeof thing === 'undefined') return console.log('thing undefined');

    if (!(name in thing.methods)) return console.log('unknown method');

    Vue.set(thing.methods[name], 'running', running);

    return undefined;
  },
  loadingFinished(state) {
    state.loading = false;
  },
  pushThing(state, thing) {
    state.things.push(thing);
  },
  setChangesNotCommitedYet(state, commitedYet) {
    state.changesNotCommitedYet = commitedYet;
  },
  setError(state, error) {
    state.error = error;
  },
  removeThing(state, id) {
    state.things = state.things.filter((thing) => thing.id !== id);
  },
  setCommitting(state, committing) {
    state.commiting = !!committing;
  },
};

export default {
  namespaced: true,
  state: persistedState,
  getters,
  actions,
  mutations,
};
