//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios';
import SideBar from './SideBar.vue';
// import NavBar from './NavBar.vue';
import Login from './Login.vue';

export default {
  methods: {
    toggleSidebar() {
      this.$refs.sidebar.toggle();
    },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
    },
  },
  data() {
    return {
      isFullscreen: false,
    };
  },
  async mounted() {
    // TODO: create this in another place?
    axios.interceptors.response.use(
      (response) => response,
      (err) => {
        this.$buefy.snackbar.open({
          message: `Anfrage an '${
            err.config.url.split('/api')[1]
          }' fehlgeschlagen: <br/>${err.message}`,
          duration: 10000,
          queue: false,
          type: 'is-warning',
          position: 'is-bottom-right',
        });
      },
    );
  },
  components: {
    SideBar,
    // NavBar,
    Login,
  },
};
