//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import debounce from 'debounce';
import VueJsonPretty from 'vue-json-pretty';
import ActionBar from '@/components/generic/ActionBar.vue';
import api from '@/api';
import 'vue-json-pretty/lib/styles.css';

export default {
  props: {
    resourceName: {
      required: true,
      type: String,
    },
    columns: {
      type: Object,
      required: true,
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  mounted() {
    this.calculateItemsPerPage();
    window.addEventListener('resize', this.calculateItemsPerPage);
    window.addEventListener('keydown', this.calculateItemsPerPage);
    this.isMounted = true;
  },
  data() {
    return {
      checkedRows: [],
      resource: [],
      paginationPerPage: 17,
      // needed since we access $refs in computed property.
      // see https://stackoverflow.com/a/43531779
      isMounted: false,
      // state for pagination
      total: 0,
      loading: false,
      sortBy: this.$route.query.sb || 'id',
      sortOrder: this.$route.query.so || 'desc',
      defaultSortOrder: 'desc',
      page: parseInt(this.$route.query.p, 10) || 1,
      itemsPerPage: null,
    };
  },
  computed: {
    searchTerm() {
      if (!this.isMounted) return undefined;
      if (!('actionBar' in this.$refs)) return undefined;
      return this.$refs?.actionBar?.inputs.search;
    },
    paginationTotal() {
      // return this.$store.state.things.totalAmount;
      return this.things.length;
    },
  },
  watch: {
    itemsPerPage() {
      this.loadResource();
    },
  },
  methods: {
    searchTermChanged: debounce(function loadAfterChange() {
      this.loadResource();
    }, 500),
    calculateItemsPerPage: debounce(function calculateItemsPerPage() {
      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0,
      );
      const margin = 220;
      this.itemsPerPage = Math.floor((vh - margin) / 38);
    }, 150),
    async loadResource() {
      this.loading = true;
      try {
        const result = await api.getResource(this.resourceName, {
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
          offset: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
          includesText: this.$refs?.actionBar?.inputs.search,
        });
        this.resource = result.resource;
        this.total = result.total;
      } finally {
        this.loading = false;
      }
    },
    onPageChange(page) {
      this.page = page;
      this.$router.push({
        name: 'things',
        query: { ...this.$route.query, p: page },
      });
      this.loadResource();
    },
    onSort(field, order) {
      this.sortBy = field;
      this.sortOrder = order;
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, sb: field, so: order },
      });
      this.loadResource();
    },
    deleteCheckedRows() {
      if (this.checkedRows.length === 0) return;

      // // ask the user how to continue
      // const answer = window.confirm(
      //   `Are you sure to delete these ${this.checkedRows.length} things?`
      // );

      // // check his answer and act accordingly
      // if (!answer) return;

      this.loading = true;

      const ids = this.checkedRows.map((row) => row.id);

      this.$store.dispatch('things/delete', ids).then(() => {
        this.loading = false;
        this.checkedRows = [];
        this.loadResource();
      });
    },
    tableClick(row) {
      this.$emit('clickRow', row);
    },
    keyDownHandler(e) {
      if (e.ctrlKey && e.key === 'f') {
        this.$refs.actionBar.focus('search');
        e.preventDefault();
      }
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
    window.removeEventListener('keydown', this.keyDownHandler);
  },
  components: {
    ActionBar,
    VueJsonPretty,
  },
};
