//
//
//
//

export default {
  props: ['config'],
  mounted() {
    /* for (let i = 0; i < this.config.points.length; i += 2) {
      this.joints.push({
        x: this.config.points[i],
        y: this.config.points[i + 1],
        radius: 3,
        fill: "lightgrey",
        stroke: "#666",
        strokeWidth: 2
      });
    } */
  },
  methods: {},
  computed: {},
  data() {
    return {};
  },
};
