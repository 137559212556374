//
//
//
//
//

import ProvidersList from './ProvidersList.vue';
import ProviderCreation from './Creation/ProviderCreation.vue';

export default {
  components: {
    ProvidersList,
    ProviderCreation,
  },
  data() {
    return {
      defaultRoute: 'list',
    };
  },
  computed: {
    mode() {
      const { mode } = this.$route.query;
      if (typeof mode === 'undefined' || mode.length === 0) return 'list';
      return mode; // TODO: check if route is known
    },
  },
};
