import gql from 'graphql-tag';

export default ({ queryVariables, subscriptionVariables }) => ({
  create() {
    return {
      query() {
        return gql`query ThingHistoryQuery($thingid: Int = 0, $propertyFilter: [String!] = null) {
          thing_state_history(where: {thingid: {_eq: $thingid}, property: { _in: $propertyFilter }}, order_by: {time: desc}, limit: 150) {
            datatype
            property
            time
            value
          }
        }`;
      },
      variables: queryVariables,
      subscribeToMore: {
        document: gql`
        subscription ThingHistoryQuery($thingid: Int = 0, $propertyFilter: [String!] = null, $minTime: timestamptz = "") {
          thing_state_history(where: {thingid: {_eq: $thingid}, property: {_in: $propertyFilter}, time: {_gte: $minTime}}, order_by: {time: desc}, limit: 50) {
            datatype
            property
            time
            value
          }
        }
        `,
        variables: subscriptionVariables,
        updateQuery: (previousResult, { subscriptionData }) => {
          if (previousResult) {
            // append to existing array
            previousResult.thing_state_history.unshift(
              ...subscriptionData.data.thing_state_history,
            );
            return previousResult;
          }
          return { thing_state_history: [] };
        },
      },
    };
  },
  key: 'thing_state_history',
  columns: [
    { label: 'Eigenschaft', field: 'property', type: 'text' },
    { label: 'Zeitpunkt', field: 'time', type: 'datetime' },
    { label: 'Wert', field: 'value', type: 'text' },
    { label: 'Datentyp', field: 'datatype', type: 'text' },
  ],
});
