//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  props: ['options', 'providerLabel'],
  data() {
    return {
      values: {},
    };
  },
  computed: {
    ...mapState('things', ['newThing']),
  },
  mounted() {
    this.options.forEach((option) => {
      this.values[option.name] = '';
    });
  },
};
