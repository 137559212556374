//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueJsonPretty from 'vue-json-pretty';
import api from '@/api';
import 'vue-json-pretty/lib/styles.css';

export default {
  props: {
    functionPath: {
      type: String,
      required: true,
    },
  },
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      executing: false,
      hasResult: false,
      result: null,
      error: null,
      functionInput: '{}',
    };
  },
  methods: {
    customValueFormatter(data, key, path, defaultFormatResult) {
      // console.log(defaultFormatResult);
      return defaultFormatResult
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
    },
    async runFunction() {
      this.executing = true;
      this.result = null;
      this.error = null;
      this.hasResult = false;
      try {
        this.result = await api.runFunction(this.functionPath, JSON.parse(this.functionInput));
        this.hasResult = true;
        this.executing = false;
      } catch (err) {
        this.executing = false;
        if (err instanceof api.RequestError) {
          this.error = err.cause.details;
        } else this.error = err.message;
        // this.result = err.message;
      }
    },
  },
};
