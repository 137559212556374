import api from '@/api';
import { arrayToObject } from '../../util';

// initial state
const persistedState = {
  byIndex: [],
};

// getters
const getters = {};

// actions
const actions = {
  async sync({ commit }) {
    const apps = await api.getApps();
    commit('setApps', apps);
  },
  async create({ dispatch }) {
    const newApp = await api.createApp();
    dispatch('sync');
    return newApp;
  },
  async save({ commit }, app) {
    await api.saveApp(app.id, app.content);
    commit('setAppContent', app);
  },
  async delete({ dispatch }, ids) {
    await api.deleteApps(ids);
    dispatch('sync');
  },
  async patch({ dispatch }, { id, patch }) {
    await api.patchApp(id, patch);
    dispatch('sync');
  },
};

// mutations
const mutations = {
  setApps(state, apps) {
    console.log('setting apps', apps);
    state.byIndex = apps;
    state.byId = arrayToObject(state.byIndex, 'id');
  },
  setAppContent(state, app) {
    state.byId[app.id].content = app.content;
  },
};

export default {
  namespaced: true,
  state: persistedState,
  getters,
  actions,
  mutations,
};
