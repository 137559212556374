//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api';

export default {
  data() {
    return {
      connector: {},
      resourceName: `connectors/${this.$route.params.id}`,
      columns: {
        parameters: {
          label: 'Nachricht',
          width: 100,
          sortable: false,
          json: true,
        },
      },
    };
  },
  async mounted() {
    const result = await api.getResource(this.resourceName);
    this.connector = result.resource;
  },
};
