//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api';

export default {
  props: ['referenceId'],
  data() {
    return {
      executing: false,
      result: null,
      error: null,
      relationship: {
        first: null,
        second: null,
        type: null,
      },
    };
  },
  methods: {
    setToReferenceId(which) {
      this.relationship[which] = this.referenceId;
    },
    async createRelationship() {
      this.executing = true;
      this.result = null;
      this.error = null;
      // console.log(
      //   this.connectorType,
      //   this.connectorLabel,
      //   this.connectorParameters,
      // );
      try {
        this.result = await api.postResource('relationships', this.relationship);
        this.executing = false;
      } catch (err) {
        this.executing = false;
        this.error = err.message;
      }
    },
  },
};
