var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"has-text-weight-bold is-family-monospace"},[(_vm.stateEntry.datatype === 'geography' ||
      _vm.stateEntry.datatype === 'geography:point')?[_c('EditableText',{staticClass:"point-first",attrs:{"value":_vm.stateEntry.value.coordinates[1]},on:{"changed":function (newValue) { return _vm.changed({
          latitude: parseFloat(newValue),
          longitude: _vm.stateEntry.value.coordinates[0]
        }); }}}),_c('EditableText',{attrs:{"value":_vm.stateEntry.value.coordinates[0]},on:{"changed":function (newValue) { return _vm.changed({
          latitude: _vm.stateEntry.value.coordinates[1],
          longitude: parseFloat(newValue)
        }); }}})]:(_vm.stateEntry.datatype === 'geography:polygon')?[_vm._v(" ["),_vm._l((_vm.stateEntry.value[0]),function(coordinate,index){return [_vm._v(" ["),_c('EditableText',{key:index + ':0',attrs:{"value":coordinate[0]}}),_vm._v(", "),_c('EditableText',{key:index + ':1',attrs:{"value":coordinate[1]}}),_vm._v("], ")]}),_vm._v("] ")]:(_vm.stateEntry.datatype === 'geography:line')?[_vm._v(" ["),_vm._l((_vm.stateEntry.value.coordinates),function(coordinate,index){return [_vm._v(" ["),_c('EditableText',{key:index + ':0',attrs:{"value":coordinate[0]}}),_vm._v(", "),_c('EditableText',{key:index + ':1',attrs:{"value":coordinate[1]}}),_vm._v("], ")]}),_vm._v("] ")]:(_vm.stateEntry.datatype === 'boolean')?[_c('EditableText',{attrs:{"value":_vm.stateEntry.value},on:{"changed":_vm.changed}})]:(_vm.stateEntry.datatype === 'object')?[_c('vue-json-pretty',{attrs:{"data":_vm.stateEntry.value,"deep":0}})]:_c('EditableText',{attrs:{"value":_vm.stateEntry.value},on:{"changed":_vm.changed}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }