// initial state
const persistedState = {
  processes: {},
};

// getters
const getters = {};

// actions
const actions = {
  updateProcessFromEvent({ commit, dispatch }, event) {
    const { processid } = event;
    dispatch('init', processid);
    commit('setProcessStepState', {
      processid,
      stateToInsert: {
        // TODO: make these translations obsolete
        name: event.step,
        state: event.progress,
        parameters: event.parameters,
      },
    });
  },
  init({ commit }, processid) {
    if (!(processid in persistedState.processes)) commit('setProcessData', { processid, data: { steps: [] } });
  },
};

// mutations
const mutations = {
  setProcessData(state, { processid, data }) {
    state.processes[processid] = data;
  },
  setProcessStepState(state, { processid, stateToInsert }) {
    // figure out if step already known
    const existingStepIndex = state.processes[processid].steps.findIndex(
      (val) => val.name === stateToInsert.name,
    );

    // if yes, simply update
    if (existingStepIndex !== -1) {
      state.processes[processid].steps.splice(existingStepIndex, 1, stateToInsert);
      // otherwise insert step
    } else state.processes[processid].steps.push(stateToInsert);

    // mark as finished if name is "finished"
    // TODO: mark this server-side in the future
    if (stateToInsert.name === 'finished') state.processes[processid].finished = true;
  },
};

export default {
  namespaced: true,
  state: persistedState,
  getters,
  actions,
  mutations,
};
