//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      selection: {
        entity: null,
        mesh: null,
      },
      opacity: 0,
    };
  },
  watch: {
    opacity() {
      this.selection.mesh.material.opacity = this.opacity / 100;
      this.$emit('dirty');
    },
    selection() {
      this.opacity = this.selection.mesh.material.opacity * 100;
    },
  },
  methods: {
    navigateToEntityPage() {
      window.open(`/things/${this.selection.entity.id}`, '_blank');
    },
  },
};
