function colorGradient(fadeFraction, rgbColor1, rgbColor2, rgbColor3) {
  let color1 = rgbColor1;
  let color2 = rgbColor2;
  let fade = fadeFraction;

  // Do we have 3 colors for the gradient? Need to adjust the params.
  if (rgbColor3) {
    fade *= 2;

    // Find which interval to use and adjust the fade percentage
    if (fade >= 1) {
      fade -= 1;
      color1 = rgbColor2;
      color2 = rgbColor3;
    }
  }

  const diffRed = color2.red - color1.red;
  const diffGreen = color2.green - color1.green;
  const diffBlue = color2.blue - color1.blue;

  const gradient = {
    red: parseInt(Math.floor(color1.red + diffRed * fade), 10),
    green: parseInt(Math.floor(color1.green + diffGreen * fade), 10),
    blue: parseInt(Math.floor(color1.blue + diffBlue * fade), 10),
  };

  return `rgb(${gradient.red},${gradient.green},${gradient.blue})`;
}

function randomBetween(min, max) {
  const num = Math.random() * (max - min + 1) + min;
  return Math.round(num * 100) / 100;
}

const arrayToObject = (array, groupBy) => array.reduce((obj, item) => {
  const grouped = {};
  grouped[item[groupBy]] = item;
  return Object.assign(obj, grouped);
}, {});

const deepCopy = (o) => {
  if (o === null) return null;

  let v;
  const output = Array.isArray(o) ? [] : {};
  Object.keys(o).forEach((key) => {
    v = o[key];
    output[key] = typeof v === 'object' ? deepCopy(v) : v;
  });
  return output;
};

// const asyncForEach = async (array, callback) => {
//   for (let index = 0; index < array.length; index += 1) {
//     await callback(array[index], index, array);
//   }
// };

const asyncForEachParallel = async (array, callback) => {
  const promises = [];
  for (let index = 0; index < array.length; index += 1) {
    promises.push(callback(array[index], index, array));
  }
  await Promise.all(promises);
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    });
  }

  return mergeDeep(target, ...sources);
}

export {
  colorGradient,
  randomBetween,
  arrayToObject,
  deepCopy,
  asyncForEachParallel,
  sleep,
  mergeDeep,
  isObject,
};
