const makeThingHistoryQuery = (thingId, property) => ({
  operationName: 'ThingHistoryQuery',
  query: `query ThingHistoryQuery($thingid: Int = 0, $propertyFilter: [String!] = null)
        {
          thing_state_history(
            where: {
              thingid: {_eq: $thingid},
              property: {_in: $propertyFilter}
            }
            order_by: { time: desc }
            limit: 150
          ) {
            datatype
            property
            time
            value
            __typename
          }
        }`,
  variables: {
    thingid: thingId,
    propertyFilter: [property],
  },
});

const makeOptions = (yTickCallback, xMaxTicks) => ({
  responsive: true,
  legend: {
    display: false,
  },
  animation: {
    duration: 0,
  },
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    xAxes: [
      {
        display: true,
        type: 'time',
        gridLines: {
          color: 'rgba(0, 0, 0, 0.2)',
        },
        time: {
          unit: 'day',
          displayFormats: {
            minute: 'HH:mm',
            hour: 'HH:mm',
          },
        },
        ticks: {
          minRotation: 90,
          maxRotation: 90,
          maxTicksLimit: xMaxTicks,
          callback() {
            return '';
          },
        },
      },
    ],
    yAxes: [
      {
        display: true,
        gridLines: {
          color: 'rgba(0, 0, 0, 0.1)',
        },
        ticks: {
          min: 0,
          // max: 50000,
          fontSize: 10,
          maxTicksLimit: 5,
          callback: yTickCallback,
        },
      },
    ],
  },
});

export default { makeThingHistoryQuery, makeOptions };
