//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['value'],
  data() {
    return {
      thingId: -1,
      methodName: '',
    };
  },
  beforeMount() {
    if (this.value) {
      this.thingId = this.value.thingId;
      this.methodName = this.value.method.name;
    }
    this.updateThingAndMethod();
  },
  methods: {
    thingChanged(event) {
      this.thingId = event.target.value;
      this.updateThingAndMethod();
    },
    methodChanged(event) {
      this.methodName = event.target.value;
      this.updateThingAndMethod();
    },
    updateThingAndMethod() {
      let methodToEmit = {};
      if (
        this.thingId in this.$store.state.things.byId
        && this.methodName in this.$store.state.things.byId[this.thingId].methods
      ) {
        methodToEmit = this.$store.state.things.byId[this.thingId].methods[
          this.methodName
        ];
        methodToEmit.name = this.methodName;
      }

      this.$emit('input', {
        thingId: this.thingId,
        method: methodToEmit,
      });
    },
  },
};
