//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { mapState } from 'vuex';
import api from '@/api';
import CreateFunctionForm from '@/components/generic/CreateFunctionForm.vue';
import DefaultTable from '@/components/generic/tables/DefaultTable.vue';

export default {
  components: { DefaultTable },
  data() {
    return {
      checkedRows: [],
      foldersAndFunctions: [],
      currentFolder: undefined,
      columns: [
        {
          field: 'type',
          label: 'Type',
          type: 'icon',
          content: (row) => this.typeToIcon(row.type),
        },
        {
          field: 'name',
          label: 'Name',
          type: 'text',
          content: (row) => row.path || row.folder,
        },
      ],
    };
  },
  metaInfo() {
    return {
      title: 'ONE | functions',
      // ...
    };
  },
  mounted() {
    this.currentFolder = this.$router.currentRoute.query.folder;
    this.syncFolder();
  },
  watch: {
    $route(to, from) {
      this.currentFolder = this.$router.currentRoute.query.folder;
      this.syncFolder();
      console.log(to, from);
    },
  },
  computed: {
    actions() {
      return {
        add: {
          type: 'button',
          callback: this.addFunction,
          icon: 'plus',
          label: 'Funktion hinzufügen',
        },
        delete: {
          type: 'button',
          disabled: this.$refs.table ? this.$refs.table.selectedRows.length === 0 : true,
          callback: this.deleteCheckedRows,
          isDanger: true,
          icon: 'delete',
          label: 'Delete',
        },
      };
    },
    // ...mapState('functions', {
    //   functionsList: (state) => state.byIndex,
    // }),
  },
  methods: {
    async syncFolder() {
      const [functions, folders] = await api.getResource('functions', {
        folder: this.currentFolder,
      });
      this.foldersAndFunctions = [
        ...folders.map((folder) => ({ ...folder, type: 'folder' })),
        ...functions.map((func) => ({ ...func, type: 'function' })),
      ];
    },
    async actionClicked(action) {
      console.log(action);
      switch (action) {
        case 'add':
          this.$buefy.modal.open({
            parent: this,
            component: CreateFunctionForm,
            hasModalCard: true,
            trapFocus: true,
          });
          break;
        case 'delete': {
          const names = this.checkedRows.map((row) => row.path);
          if (names.length === 0) return;
          if (names.length === 1) await api.deleteResource(`functions/${names[0]}`, {});
          else await api.deleteResource('functions', { names });
          await this.syncFolder();
          break;
        }
        default:
      }
    },
    typeToIcon(type) {
      switch (type) {
        case 'function':
          return 'function-variant';
        case 'folder':
          return 'folder';
        default:
          return '';
      }
    },
    deleteCheckedRows() {
      const ids = this.$refs.table.selectedRows.map((row) => row.id);

      this.$store.dispatch('apps/delete', ids).then(() => {
        this.$refs.table.selectedRows = [];
      });
    },
    rowAction(row) {
      console.log(this.$refs.table.selectedRows);
      if (row.type === 'folder') {
        this.$router
          .push({ name: 'functions', query: { folder: row.folder } })

          .catch(() => {});
        this.currentFolder = row.folder;
        console.log(this.currentFolder);
        this.syncFolder();
      }
      if (row.type === 'function') {
        this.$router.push({ name: 'function', params: { path: row.path } });
      }
    },
  },
};
