import Vue from 'vue';

/**
 * Mocking client-server processing
 * put all api calls here
 */
import axios from 'axios';

import config from '../config';

const API_HOST = config.endpoints.api;
const HTTP_VERBS = ['get', 'head', 'post', 'put', 'delete', 'connect', 'options', 'trace', 'patch'];

class RequestError extends Error {
  constructor(message, cause) {
    super(message);
    this.cause = cause;
  }
}

const request = async (verb, path, options) => {
  const url = API_HOST + path;

  const { params, payload, axiosOptions } = options || {};

  if (!HTTP_VERBS.includes(verb)) {
    throw new Error(`invalid http verb '${verb}'`);
  }

  let response;

  if (payload) {
    response = await axios[verb](url, payload, {
      params,
      headers: {
        Authorization: `Bearer ${await Vue.prototype.$auth.getTokenSilently()}`,
      },
      ...(axiosOptions ?? {}),
    });
  } else {
    response = await axios[verb](url, {
      params,
      headers: {
        Authorization: `Bearer ${await Vue.prototype.$auth.getTokenSilently()}`,
      },
      ...(axiosOptions ?? {}),
    });
  }
  if (!response) throw new Error('response invalid');
  if (response.data.result === 'success') return response.data.data;
  if (response.data.result === 'error') {
    throw new RequestError(`error on request to '${path}': ${response.data.error}`, response.data.error);
  } else throw new Error(`api: unknown result value '${response.data.result}'`);
};

const getFrom = async (path, options) => request('get', path, options);
const patchTo = async (path, options) => request('patch', path, options);
const postTo = async (path, options) => request('post', path, options);
const putTo = async (path, options) => request('put', path, options);
// eslint-disable-next-line no-unused-vars
const deleteFrom = async (path, options) => request('delete', path, options);

export default {
  RequestError,
  // resources in general
  getResource(resourcePath, params) {
    return getFrom(`api/${resourcePath}`, { params });
  },
  postResource(resourcePath, payload) {
    return postTo(`api/${resourcePath}`, { payload });
  },
  patchResource(resourcePath, payload) {
    return patchTo(`api/${resourcePath}`, { payload });
  },
  deleteResource(resourcePath, payload) {
    return deleteFrom(`api/${resourcePath}`, { axiosOptions: { data: payload } });
  },
  // functions
  getFunction(id) {
    return getFrom(`api/functions/${id}`);
  },
  setFunctionContent(id, content) {
    return postTo(`api/functions/${id}`, { payload: { content, overwrite: true } });
  },
  runFunction(path, input) {
    return postTo(`api/functions/${path}/run`, { payload: input });
  },
  createFunction(path, overwrite) {
    return postTo(`api/functions/${path}`, { payload: { overwrite } });
  },
  deleteFunction(path) {
    return deleteFrom(`api/functions/${path}`);
  },
  getFunctions(params) {
    return getFrom('api/functions', { params });
  },
  // connectors
  getConnectors(params) {
    return getFrom('api/connectors', { params });
  },
  // things
  getThings(params) {
    return getFrom('api/things', { defaultTo: [], params });
  },
  createThingsFromGeoJSON(features, schemas) {
    return postTo('api/things', { payload: { features, schemas, inputFormat: 'geojson' } }, []);
  },
  setStateAttribute({ id, property, value }) {
    return postTo(`api/things/${id}/state`, { payload: { property, value } });
  },
  getThingState(id) {
    return getFrom(`api/things/${id}/state`);
  },
  getThing(id) {
    return getFrom(`api/things/${id}`);
  },
  getThingsInArea(params) {
    return getFrom('api/things/inArea', { defaultTo: [], params });
  },
  getThingHistory(id, property, { limit, time, asSeries }) {
    const params = {};

    if (limit) {
      params.limit = limit;
    }

    if (asSeries) {
      params.asSeries = asSeries;
    }

    if (time) {
      if ('max' in time) params.maxTime = time.max;
      if ('min' in time) params.minTime = time.min;
    }

    const esc = encodeURIComponent;
    const query = Object.keys(params)
      // eslint-disable-next-line
      .map(k => `${esc(k)}=${esc(params[k])}`)
      .join('&');

    // TODO: convert to usage of "params"
    return getFrom(`api/things/${id}/state/${property}/history?${query}`);
  },
  // eslint-disable-next-line
  getThingStateHistory(id, property, { limit, time, asSeries, numSamples }) {
    const params = {};

    if (limit) {
      params.limit = limit;
    }

    if (asSeries) {
      params.asSeries = asSeries;
    }

    if (time) {
      if ('max' in time) params.maxTime = time.max;
      if ('min' in time) params.minTime = time.min;
    }

    if (numSamples) params.numSamples = numSamples;

    const esc = encodeURIComponent;
    const query = Object.keys(params)
      // eslint-disable-next-line
      .map(k => `${esc(k)}=${esc(params[k])}`)
      .join('&');

    return getFrom(`api/things/${id}/state/${property}/history?${query}`);
  },
  runThingMethod(id, name) {
    return getFrom(`api/things/${id}/methods/${name}/run?awaitResult`);
  },
  fetchProviders() {
    return getFrom('api/providers', { defaultTo: [] });
  },
  fetchProviderBlueprints() {
    return getFrom('api/providers?blueprints', { defaultTo: [] });
  },
  createProvider(provider) {
    return postTo('api/providers', { payload: provider });
  },
  getDashboardItems(dashboardid) {
    return getFrom(`api/dashboards/${dashboardid}`);
  },
  saveDashboardItems(dashboardid, items) {
    return postTo(`api/dashboards/${dashboardid}`, { payload: items });
  },
  fetchFlows() {
    return getFrom('api/flows', { defaultTo: [] });
  },
  // addFlow() {
  //   return postTo('api/flows');
  // },
  // deleteFlows(ids) {
  //   return postTo('api/flows?delete', { payload: { ids } });
  // },
  // saveFlow(id, flow) {
  //   return putTo(`api/flows/${id}`, { payload: flow });
  // },
  // getFlow(id) {
  //   return getFrom(`api/flows/${id}`);
  // },
  getApps() {
    return getFrom('api/apps');
  },
  getApp(id) {
    return getFrom(`api/apps/${id}`);
  },
  deleteApps(ids) {
    return postTo('api/apps?delete', { payload: { ids } });
  },
  createApp() {
    return postTo('api/apps', { payload: { name: 'new-app' } });
  },
  saveApp(id, content) {
    return putTo(`api/apps/${id}`, { payload: { content } });
  },
  patchApp(id, patch) {
    return patchTo(`api/apps/${id}`, { payload: patch });
  },
  deleteProviders(ids) {
    return postTo('api/providers?delete', { payload: { ids } });
  },
};
