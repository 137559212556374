// initial state
const persistedState = { isInitializing: true };

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  setIsInitializing(state, isOrIsnt) {
    state.isInitializing = isOrIsnt;
  },
};

export default {
  namespaced: true,
  state: persistedState,
  getters,
  actions,
  mutations,
};
