//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PropertyEditor from '@components/generic/PropertyEditor.vue';
import ThingHistoryTable from '@components/generic/ThingHistoryTable.vue';
import gql from 'graphql-tag';
import CreateRelationshipForm from '@/components/generic/CreateRelationshipForm.vue';
// eslint-disable-next-line import/no-unresolved
import CreateStateForm from '@/components/generic/CreateStateForm.vue';
import api from '@/api';

export default {
  data() {
    return {
      thing: {
        name: '',
        attributes: {},
        methods: {},
        state: [],
      },
      currentTab: 0,
      runningMethods: [],
      animateMethodButton: {},
    };
  },
  metaInfo() {
    return {
      title: `ONE | ${this.thing.name || 'loading...'}`,
      // ...
    };
  },
  watch: {
    '$route.name': function changed(newRoute) {
      this.setTabByRoute(newRoute);
    },
  },
  apollo: {
    thing: {
      query() {
        return gql`
          query MyQuery($thingid: Int = -1) {
            things(where: { id: { _eq: $thingid } }) {
              id
              name
              providedby
              type
              thing_methods {
                displayicon
                displayname
                name
                parameters
              }
              relationships_as_first {
                type
                first_entity {
                  name
                  id
                }
                second_entity {
                  name
                  id
                }
              }
              relationships_as_second {
                type
                first_entity {
                  name
                  id
                }
                second_entity {
                  name
                  id
                }
              }
              thing_states {
                property
                value
                datatype
                time
                valid
              }
              thing_state_spatials {
                property
                value
                datatype
                time
                valid
              }
            }
          }
        `;
      },
      update: (data) => {
        const thing = data.things[0];
        const combinedState = [
          ...thing.thing_states,
          ...thing.thing_state_spatials,
        ];
        thing.state = combinedState;
        const combinedRelationships = [
          ...thing.relationships_as_first,
          ...thing.relationships_as_second,
        ];
        thing.relationships = combinedRelationships;
        return thing;
      },
      variables() {
        return {
          thingid: this.$route.params.id,
        };
      },
      subscribeToMore: {
        document: gql`
          subscription MyQuery($thingid: Int = -1) {
            things(where: { id: { _eq: $thingid } }) {
              id
              name
              providedby
              type
              thing_methods {
                displayicon
                displayname
                name
                parameters
              }
              relationships_as_first {
                type
                first_entity {
                  name
                  id
                }
                second_entity {
                  name
                  id
                }
              }
              relationships_as_second {
                type
                first_entity {
                  name
                  id
                }
                second_entity {
                  name
                  id
                }
              }
              thing_states {
                property
                value
                datatype
                time
                valid
              }
              thing_state_spatials {
                property
                value
                datatype
                time
                valid
              }
            }
          }
        `,
        variables() {
          return {
            thingid: this.$route.params.id,
          };
        },
        // eslint-disable-next-line
        updateQuery: (previousResult, { subscriptionData }) =>
          // eslint-disable-next-line
          subscriptionData.data,
      },
    },
  },
  computed: {
    stateAsList() {
      return null;
      // return Object.keys(this.thing.state).map((key) => ({
      //   property: key,
      //   value: this.thing.state[key].value,
      //   time: this.thing.state[key].time,
      //   valid: this.thing.state[key].valid,
      //   datatype: this.thing.state[key].datatype,
      // }));
    },
  },
  mounted() {
    this.setTabByRoute(this.$route.name);
  },
  sockets: {
    // stateDelta(payload) {
    //   // did we receive update for selected thing?
    //   // NOTE: we have to cast to strings here,
    //   //       as $route.params are sometimes of type
    //   //       String and sometimes of type int for some reason.
    //   if (payload.thingId.toString() === this.$route.params.id.toString()) {
    //     const update = {};
    //     update[payload.property] = payload.to;
    //     update[payload.property].datatype = payload.datatype;
    //     update[payload.property].valid = true;
    //     this.applyUpdate(update);
    //   }
    // },
  },
  methods: {
    propertyInput(ev) {
      console.log(ev);
      if (ev.inputType === 'insertParagraph') {
        ev.preventDefault();
        setTimeout(() => {
          ev.target.blur();
        }, 0);
        return false;
      }
      return true;
    },
    deleteStateEntry(row) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (!confirm('Sicher?')) {
        return;
      }
      console.log('deleting', row);
      api.deleteResource(`things/${this.$route.params.id}/state`, {
        property: row.property,
      });
    },
    nameBlur(ev) {
      api.patchResource(`things/${this.$route.params.id}`, {
        name: ev.target.innerText,
      });
    },
    typeBlur(ev) {
      api.patchResource(`things/${this.$route.params.id}`, {
        type: ev.target.innerText,
      });
    },
    addState() {
      this.$buefy.modal.open({
        parent: this,
        component: CreateStateForm,
        hasModalCard: true,
        trapFocus: true,
        props: { thingid: this.$route.params.id },
      });
    },
    addRelationship() {
      this.$buefy.modal.open({
        parent: this,
        component: CreateRelationshipForm,
        hasModalCard: true,
        trapFocus: true,
        props: { referenceId: this.$route.params.id },
      });
    },
    async deleteRelationship(relationship) {
      console.log('deleting');
      await api.deleteResource('relationships', {
        first: relationship.first_entity.id,
        type: relationship.type,
        second: relationship.second_entity.id,
      });
    },
    async deleteEntity() {
      await api.deleteResource(`things/${this.$route.params.id}`);
      this.$router.replace({
        name: 'things',
      });
    },
    tabChanged(tab) {
      if (tab === 0) {
        this.$router.push({
          name: 'thing',
          params: { id: this.$route.params.id },
        });
      } else if (tab === 1) {
        this.$router.push({
          name: 'thing-history',
          params: { id: this.$route.params.id },
        });
      }
    },
    setTabByRoute(route) {
      if (route === 'thing') {
        this.currentTab = 0;
      } else if (route === 'thing-history') this.currentTab = 1;
    },
    mapGoto(latlng) {
      this.$router.push({
        name: 'map-at',
        params: {
          center: `${latlng.coordinates[1]},${latlng.coordinates[0]},18,0,0`,
        },
      });
    },
    async runMethod(methodName) {
      this.runningMethods.push(methodName);
      await this.$store.dispatch('things/runMethod', {
        id: this.$route.params.id,
        name: methodName,
      });
      this.runningMethods = this.runningMethods.filter(
        (item) => item !== methodName,
      );
    },
    commitStateValue({ property, from, to }) {
      if (to !== from) {
        this.$store.dispatch('things/setStateAttribute', {
          id: this.thing.id,
          property,
          value: to,
        });
      }
    },
  },
  components: {
    PropertyEditor,
    ThingHistoryTable,
  },
};
