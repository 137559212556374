//
//
//
//

import exampleImg from '../../../assets/download.jpeg';

export default {
  mounted() {
    this.canvas = this.$refs.canvas.getContext('2d');
    this.drawImage();
    // console.log(exampleImg);
  },
  methods: {
    drawImage() {
      const ctx = this.canvas;
      const image = new Image();
      image.onload = () => {
        ctx.width = image.width;
        ctx.height = image.height;
        ctx.drawImage(image, 0, 0, image.width, image.height);
        // ctx.drawImage(this, 0, 0, this.width, this.height);
        this.drawRect();
      };
      image.src = exampleImg;
    },
    drawRect() {
      const ctx = this.canvas;
      ctx.strokeStyle = 'red';
      ctx.lineWidth = 2;
      ctx.strokeRect(201, 52, 439, 282);
      ctx.strokeRect(108, 197, 143, 253);
    },
  },
};
