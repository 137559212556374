import Vue from 'vue';
import Vuex from 'vuex';
import plans from './modules/plans';
import drawer from './modules/drawer';
import common from './modules/common';
import things from './modules/things';
import functions from './modules/functions';
import actions from './modules/actions';
import providers from './modules/providers';
import apps from './modules/apps';
import user from './modules/user';

import bootstrap from './modules/bootstrap';
import flows from './modules/flows';
import remoteProcesses from './modules/remoteProcesses';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    plans,
    drawer,
    common,
    things,
    actions,
    functions,
    providers,
    apps,
    bootstrap,
    flows,
    user,
    remoteProcesses,
  },
  strict: debug,
});
