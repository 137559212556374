//
//
//
//
//
//
//
//

/* eslint-disable */
import ApexChart from '@components/Charts/Apex/Default.vue';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import { ma } from 'moving-averages';
import colors from 'nice-color-palettes';
import api from '@/api';

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export default {
  props: ['title'],
  components: {
    ApexChart,
  },
  data() {
    return {
      ready: false,
      series: [],
      datacollection: {
        datasets: [],
      },
      refreshFinalPointInterval: () => {},
      options: {
        chart: {
          id: 'charging',
          group: 'dashboard-1',
          type: 'area',
        },
        title: {
          text: 'Leistungsabnahme',
        },
        annotations: {
          position: 'back',
          yaxis: [
            {
              label: {
                text: '3.7 kW',
                position: 'left',
              },
              y: 0,
              y2: 3700,
              opacity: 0.2,
              fillColor: 'lightgrey',
            },
            {
              label: {
                text: '7.2kW',
                position: 'left',
              },
              y: 3700,
              y2: 7200,
              opacity: 0.1,
              fillColor: 'yellow',
            },
            {
              label: {
                text: '11kW',
                position: 'left',
              },
              y: 7200,
              y2: 11000,
              opacity: 0.1,
              fillColor: 'orange',
            },
            {
              label: {
                text: ' ',
              },
              y: 11000,
              y2: 100000,
              opacity: 0.15,
              fillColor: 'red',
            },
          ],
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '12px',
            },
            formatter(value) {
              return `${Math.floor(value / 10) / 100}kW`;
            },
          },
        },
      },
    };
  },
  destroyed() {
    clearInterval(this.refreshFinalPointIntervalId);
  },
  mounted() {
    // return;
    // this.datacollection = {
    //   datasets: [
    //     // {
    //     //   label: '#1',
    //     //   backgroundColor: '#f9caa7',
    //     //   borderColor: '#f9caa7',
    //     //   // steppedLine: true,
    //     //   data: [],
    //     //   fill: false,
    //     // },
    //     // {
    //     //   label: '#2',
    //     //   backgroundColor: '#323553A3',
    //     //   borderColor: '#323553',
    //     //   // steppedLine: true,
    //     //   data: [],
    //     //   fill: false,
    //     // },
    //   ],
    // };

    // const startDate = DateTime.fromISO('2020-11-18T10:05:09+00:00');
    // const startDate = DateTime.local().set({ hour: 8 }).minus({ days: 3 });

    // const endDate = DateTime.local().set({ hour: 20 });

    // const setFinalPoint = () => {
    //   const mostRecent =
    //     this.datacollection.datasets[0].data[
    //       this.datacollection.datasets[0].data.length - 1
    //     ];
    //   this.datacollection.datasets[0].data.push({
    //     t: Date.parse(DateTime.local().toISO()),
    //     y: mostRecent.y,
    //   });
    // };

    // const removeFinalPoint = () => {
    //   this.datacollection.datasets[0].data.pop();
    // };

    // const updateFinalPoint = () => {
    //   removeFinalPoint();
    //   setFinalPoint();
    //   this.$refs.chart.syncChart();
    // };

    // this.refreshFinalPointIntervalId = setInterval(
    //   () => updateFinalPoint(),
    //   1000,
    // );

    const startDate = DateTime.local().set({ hour: 8 }).minus({ days: 7 });

    api.getResource('functions/chargingHistory/run').then((datasets) => {
      // this.datacollection.datasets.push({
      //   label: namesGrouped[thingid][0].name,
      //   backgroundColor: colors[1][index % 5] + '11',
      //   borderColor: colors[1][index % 5],
      //   steppedLine: true,
      //   data: result,
      //   // fill: false,
      // });
      // console.log(datasets);
      // this.datacollection.datasets = datasets;
      datasets.forEach((dataset, index) => {
        this.series.push({
          name: dataset.name,
          data: dataset.history.map((entry) => [Date.parse(entry.t), entry.y]),
        });
        this.datacollection.datasets.push({
          label: dataset.name,
          backgroundColor: colors[1][index % 5] + '31',
          borderWidth: 2,
          borderColor: colors[1][index % 5],
          steppedLine: true,
          data: dataset.history.map((entry) => ({
            // y: entry.value,
            y: entry.y,
            // t: new Date(entry.time * 1000) || 0,
            t: entry.t,
          })),
          // fill: false,
        });
      });
      this.ready = true;
    });

    return;
    this.$apollo.addSmartQuery('bla', {
      // variables: { thingids: [25807, 26370] },
      query() {
        // from provided query
        return gql(`
        query MyQuery {
            thing_state_history(where: {time: {_gte: "${startDate.toISO()}"}, property: {_eq: "meterValue"}}, order_by: {time: desc}) {
              value
              time
              thingid
            },
            things(where: {type: {_eq: "_builtin/e-mobility/chargingpoint"}}) {
              id
              name
            }
          }
        `);
      },
      // subscribeToMore: {
      //   document: gql(`subscription MyQuery {
      //         thing_state_history(where: {time: {_gte: "${startDate.toISO()}"}, property: {_eq: "freeParking"}, thing: {providedby: {_eq: 36}}}, order_by: {time: desc}, limit: 1) {
      //           value
      //           time
      //         }
      //       }
      //     `),
      //   // Mutate the previous result
      //   updateQuery: (previousResult, update) => {
      //     // if (this.datacollection.datasets[0].data.length === 0) return;
      //     // removeFinalPoint();
      //     // const newItem = update.subscriptionData.data.thing_state_history[0];
      //     // const mostRecent = this.datacollection.datasets[0].data[this.datacollection.datasets[0].data.length-1];
      //     // this.datacollection.datasets[0].data.push({
      //     //   t: Date.parse(newItem.time),
      //     //   y: mostRecent.y + (newItem.value === 0 ? -1 : 1),
      //     // });
      //     // setFinalPoint();
      //     // this.$refs.chart.syncChart();
      //   },
      // },
      result(result) {
        // const maxDate = Date.parse(result.data.thing_state_history[0].time);
        const globalMinDate = Date.parse(
          result.data.thing_state_history[result.data.thing_state_history.length - 1].time,
        );
        // console.log(minDate, maxDate);
        const namesGrouped = groupBy(result.data.things, 'id');
        const grouped = groupBy(result.data.thing_state_history, 'thingid');
        Object.entries(grouped).forEach(([thingid, data], index) => {
          let minDate = Infinity;
          let maxDate = -Infinity;
          const array_chunks = (array, chunk_size) =>
            Array(Math.ceil(array.length / chunk_size))
              .fill()
              .map((_, index) => index * chunk_size)
              .map((begin) => array.slice(begin, begin + chunk_size));

          const sloped = [];
          data.forEach((point, idx) => {
            if (idx === 0) return;

            if (data[idx].value === null) return;

            const timeFirst = Date.parse(data[idx].time);
            const timeSecond = Date.parse(data[idx - 1].time);

            const factor = data[idx].value.unit === 'kWh' ? 1000 : 1;

            const valueDelta = Math.abs(
              data[idx].value.value * factor - data[idx - 1].value.value * factor,
            );
            const timeDelta = Math.abs(timeFirst - timeSecond);

            // console.log(timeDelta);

            // if (timeDelta > (maxDate - minDate) / 1000)
            //   console.log(data[idx].time, data[idx - 1].time, timeDelta, valueDelta);

            if (timeSecond > maxDate) maxDate = timeSecond;
            if (timeSecond < minDate);
            minDate = timeSecond;

            // console.log({valueDelta, timeDelta});

            sloped.push({
              t: point.time,
              y: (valueDelta / (timeDelta / 3600)) * 1000,
            });
          });

          // for (let i = 0; i < iterations; i++) {}

          const size = 1; //6*2;

          const sloped_averaged = ma(
            sloped.map((item) => item.y),
            size,
          );
          sloped_averaged.splice(0, size - 1);

          const time_averaged = ma(
            sloped.map((item) => Date.parse(item.t)),
            size,
          );
          time_averaged.splice(0, size - 1);
          const result = Array.from(Array(sloped_averaged.length)).map((_, index) => ({
            y: sloped_averaged[index],
            t: new Date(time_averaged[index]),
          }));

          // insert points between last point and end
          const stepSize = 50000;
          const now = Date.now();
          const deltaToEnd = Math.abs(now - maxDate);
          if (Number.isFinite(deltaToEnd) && deltaToEnd > stepSize) {
            result.unshift({ t: new Date(maxDate), y: 0 });
            result.unshift({ t: new Date(now), y: 0 });
          }

          const deltaToStart = Math.abs(minDate - globalMinDate);
          if (Number.isFinite(deltaToStart) && deltaToStart > stepSize) {
            result.push({ t: new Date(minDate - stepSize), y: 0 });
            result.push({ t: new Date(globalMinDate), y: 0 });
          }
          result.push({ t: startDate.toJSDate(), y: 0 });
          // if (Number.isFinite(numIterationsToStart)) {
          //   for (let i = 0; i < numIterationsToStart; i++) {
          //     sloped.push({ t: new Date(minDate - stepSize * i), y: 0 });
          //   }
          // }

          // if(deltaToStart)

          // console.log(colors[1][index % 5]);

          this.datacollection.datasets.push({
            label: namesGrouped[thingid][0].name,
            backgroundColor: colors[1][index % 5] + '11',
            borderColor: colors[1][index % 5],
            steppedLine: true,
            data: result,
            // fill: false,
          });

          // console.log(this.datacollection.datasets);

          // this.datacollection.datasets[index].label = namesGrouped[thingid][0].name;

          // this.datacollection.datasets[index].data =
          // return console.log(time_averaged, sloped_averaged);
          // this.datacollection.datasets[0].data = array_chunks(sloped, chunk_size).map(chunk => ({
          //   // y: chunk.map(item => parseInt(item.y)).reduce((a, b) => a + b, 0)/chunk.length,
          //   t: new Date(chunk.map(item => Date.parse(item.t)).reduce((a, b) => a + b, 0)/chunk.length).toISOString()
          // }));
          // console.log(this.datacollection.datasets[0].data.map(item => item.t));
        });

        // this.$refs.chart.syncChart();

        this.ready = true;

        // this.datacollection.datasets[0].data.push({
        //   t: Date.parse(time),
        //   y: sum / 24,
        // });
        // // eslint-disable-next-line
        // const stateToReconstruct =
        //   result.data.thing_state_history_aggregate.nodes;
        // const things = [];
        // for (let i = 0; i < stateToReconstruct.length; i += 2) {
        //   // eslint-disable-next-line
        //   const moreRecent =
        //     // eslint-disable-next-line
        //     Date.parse(stateToReconstruct[i].time) >
        //     Date.parse(stateToReconstruct[i + 1].time)
        //       ? stateToReconstruct[i]
        //       : stateToReconstruct[i + 1];
        //   things.push(moreRecent);
        // }
        // let sum = things
        //   .map((thing) => thing.value)
        //   .reduce((prev, curr) => prev + curr, 0);
        // sum = 24 - sum;
        // // eslint-disable-next-line
        // this.datacollection.datasets[0].data.push({
        //   t: Date.parse(startDate.toISO()),
        //   y: sum / 24,
        // });
        // const deltas = result.data.thing_state_history;
        // // let inDeadRange = false;
        // deltas.forEach(({ value, time }) => {
        //   if (value === 0) sum += 1;
        //   else sum -= 1;
        //   // const timeParsed = DateTime.fromISO(time);

        //   // // eslint-disable-next-line max-len
        //   // if (false) {
        //   //   if (!inDeadRange) {
        //   //     this.datacollection.datasets[0].data.push({ t: Date.parse(time), y: 0 });
        //   //     inDeadRange = true;
        //   //   }
        //   // } else {
        //   // inDeadRange = false;
        //   this.datacollection.datasets[0].data.push({
        //     t: Date.parse(time),
        //     y: sum / 24,
        //   });
        //   // }
        // });
        // setFinalPoint();

        // this.datacollection.datasets[0].data = [
        //   { t: startDate.toJSDate(), y: 30 },
        //   { t: startDate.plus({ hours: 1 }).toJSDate(), y: 70 },
        //   { t: startDate.plus({ hours: 3 }).toJSDate(), y: 20 },
        // ];
      },
      update() {
        // console.log('updated');
        return {};
        // return getDescendantProp(data, query.path);
      },
      // subscribeToMore: {
      //   document: gql(query.subscription),
      //   // Mutate the previous result
      //   updateQuery: (previousResult, { subscriptionData }) => subscriptionData.data,
      // },
    });
  },
};
