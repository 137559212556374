//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ActionBar from '@/components/generic/ActionBar.vue';

export default {
  props: {
    typeToIcon: Function,
    rows: Array,
    columns: Array,
    actions: Object,
  },
  components: {
    ActionBar,
  },
  data() {
    return {
      selectedRows: [],
    };
  },
  methods: {
    rowClass(row) {
      return this.selectedRows.includes(row) ? 'selected' : '';
    },
    rowClick(row, event) {
      this.selectedRows = [row];
      console.log(row, event);
    },
    rowShiftClick(row) {
      console.log('left click');
      for (let i = 0; i < this.selectedRows.length; i += 1) {
        if (this.selectedRows[i] === row) {
          delete this.selectedRows[i];
          return;
        }
      }
      this.selectedRows.push(row);
    },
    rowDblClick(row) {
      this.$emit('rowAction', row);
      //   if (row.type === 'folder') {
      //     this.$router
      //       .push({ name: 'functions', query: { folder: row.folder } })

      //       .catch(() => {});
      //     this.currentFolder = row.folder;
      //     console.log(this.currentFolder);
      //     this.syncFolder();
      //   }
      //   if (row.type === 'function') {
      //     this.$router.push({ name: 'function', params: { path: row.path } });
      //   }
    },
  },
};
