import api from '@/api';

import { arrayToObject } from '@/util';

// initial state
const persistedState = {
  byIndex: [],
  byId: {},
};

// getters
const getters = {};

// actions
const actions = {
  sync({ commit }) {
    return api.getActions().then((allActions) => {
      commit('setActions', allActions);
      return allActions;
    });
  },
  dispatch({ dispatch }, action) {
    console.log('dispatching ', action);
    if (typeof action.id !== 'undefined') return api.dispatchAction(action.id, action).then(() => dispatch('sync'));
    return api.dispatchNewAction(action).then(() => dispatch('sync'));
  },
  delete({ dispatch }, ids) {
    return api.deleteActions(ids).then(() => dispatch('sync'));
  },
  setName({ commit }, { id, name }) {
    return api.setActionName({ id, name }).then(() => commit('setActionName', name));
  },
};

// mutations
const mutations = {
  setActions(state, allActions) {
    state.byIndex = allActions;
    state.byId = arrayToObject(allActions, 'id');
  },
  setActionName(state, { id, name }) {
    if (!(id in state.byId)) return;

    state.byId[id].name = name;
  },
};

export default {
  namespaced: true,
  state: persistedState,
  getters,
  actions,
  mutations,
};
