//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios';

// import RippleLoader from './generic/RippleLoader.vue';

import entitySubscribers from './entitySubscribers';

export default {
  data() {
    return {
      query: null,
      result: '',
      querying: false,
    };
  },
  components: {
    // RippleLoader,
  },
  methods: {
    toggle() {
      this.$emit('toggleSidebar');
    },
    dispatchQuery() {
      this.querying = true;
      this.result = '';
      axios
        .get(`https://api.wit.ai/message?v=20200321&q=${this.query}`, {
          headers: { Authorization: 'Bearer GQQO7V2TFXIFDVNOSYVXZWE7TLBQG4OO' },
        })
        .then((response) => {
          const { entities } = response.data;
          const entityNames = Object.keys(entities);

          console.log(entities);

          let bestMatch = null;

          entitySubscribers.forEach((subscriber) => {
            const subscriberWants = Object.keys(subscriber.wants);

            // intersection between knownEntities and subscribers wanted attributes
            const intersection = entityNames.filter(
              (x) =>
                // eslint-disable-next-line
                subscriberWants.includes(x)
              // eslint-disable-next-line
            );

            // if the intersection length is equal to the number of retrieved
            // entities then all entities can be mapped to a subscriber and
            // therefore this subscriber is the best match
            if (intersection.length === subscriberWants.length) {
              const allMatch = Object.entries(subscriber.wants).every(
                ([key, filter]) => {
                  if (filter instanceof RegExp) {
                    return entities[key][0].value.match(filter);
                  }
                  if (key in entities && entities[key][0].value === filter) {
                    return true;
                  }
                  return false;
                  // eslint-disable-next-line
                }
              );

              if (allMatch) {
                bestMatch = subscriber;
              }
            }
          });

          console.log(`best match is ${bestMatch ? bestMatch.name : null}`);

          const bestValues = {};
          Object.entries(entities).forEach(([key, value]) => {
            bestValues[key] = value[0].value;
          });

          if (bestMatch === null) {
            this.querying = false;
            this.result = `not understood: ${Object.entries(entities).map(
              // eslint-disable-next-line
              ([key, value]) => `${key}:${value[0].value}`
            )}`;
            return;
          }

          const reject = () => {
            this.querying = false;
          };
          const resolve = (result) => {
            this.result = result ? result.value : '';
            this.querying = false;
          };

          bestMatch.onData({ resolve, reject }, this.$store, bestValues);

          // console.log(response.data);
        });
    },
  },
};
