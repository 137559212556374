import api from '@/api';

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {
  // eslint-disable-next-line no-unused-vars
  save({ commit }, plan) {
    return api.savePlan(plan);
  },
  // eslint-disable-next-line no-unused-vars
  sync({ commit }) {
    return api.fetchPlan();
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
