//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueGridLayout from 'vue-grid-layout';
import moment from 'moment';
import debounce from 'debounce';
import MapBox from '@components/GeoMap/Mapbox.vue';
import QueryInterpolator from '@components/generic/QueryInterpolator.vue';
import ThingSelector from '@components/generic/ThingSelector.vue';
import api from '@/api';
import { deepCopy, asyncForEachParallel } from '@/util';

import Items from './items';

const chartsIndex = {};

// TODO: migrate option management to backend
const defaultOptions = {
  apexCharts: {
    datasource: '',
    title: 'Titel',
  },
  whitespace: {},
  chart: {
    thingId: '',
    property: '',
    // echartsOptions: makeOptions(),
    historyLimit: 300,
  },
  message: {
    text: '',
    type: 'is-success',
  },
  chargHis: {},
  canvas: {},
  text: {
    queryKey: '',
    query: '',
    subscription: '',
    path: '',
    template: '',
  },
  mapbox: {
    center: [],
    query: '',
  },
};

export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    // eslint-disable-next-line
    ThingSelector,
    MapBox,
    QueryInterpolator,
  },
  metaInfo() {
    return {
      title: 'ONE | dashboards',
      // ...
    };
  },
  data() {
    return {
      layout: [],
      refreshingFromInterval: false,
      typingTimer: null,
      isFlipped: [],
      datacollection: null,
      timeRange: {
        min: {
          asJSDate: null,
          asMoment: null,
        },
        max: {
          asJSDate: null,
          asMoment: null,
        },
      },
      selectedRange: '',
      dashboardItemTypes: Items.dashboardItemTypes,
      itemComponents: Items.components,
    };
  },
  watch: {
    selectedRange() {
      this.refreshRange();
      this.fetchHistoryForAllItems();
    },
    layout(newLayout) {
      if (!newLayout) this.layout = [];
    },
  },
  sockets: {
    stateDelta(payload) {
      const itemsToUpdate = this.layout.filter(
        (item) => String(item.thingId) === String(payload.thingId),
      );
      itemsToUpdate.forEach((item) => {
        const seriesToUpdate = item.options.series.find(
          (series) => series.name === payload.property,
        );
        if (!seriesToUpdate) return;
        // eslint-disable-next-line no-param-reassign
        seriesToUpdate.data.push([payload.to.time, payload.to.value]);
      });
    },
  },
  async mounted() {
    // get board layout from backend
    const board = await api.getDashboardItems(1);
    // console.log('board', board);
    if (board) this.layout = board.items;
    // this.layout = [
    //   {
    //     h: 8,
    //     i: 155,
    //     w: 21,
    //     x: 0,
    //     y: 21,
    //     type: 'mapbox',
    //     moved: false,
    //     options: {
    //       query: '',
    //       center: [],
    //       positionals: {
    //         zoom: '18.552198',
    //         pitch: '0.000000',
    //         center: {
    //           lat: 50.78393750110908,
    //           lng: 6.0428545192710885,
    //         },
    //         bearing: '0.000000',
    //       },
    //     },
    //   },
    //   {
    //     h: 10,
    //     i: 26,
    //     w: 10,
    //     x: 0,
    //     y: 11,
    //     type: 'chart',
    //     moved: false,
    //     options: {
    //       thingId: '',
    //       property: '',
    //       historyLimit: 300,
    //     },
    //   },
    //   {
    //     h: 11,
    //     i: 31,
    //     w: 5,
    //     x: 10,
    //     y: 11,
    //     type: 'gauge',
    //     moved: false,
    //     options: {
    //       thingId: '',
    //       property: '',
    //       historyLimit: 300,
    //     },
    //   },
    //   {
    //     h: 11,
    //     i: 30,
    //     w: 5,
    //     x: 15,
    //     y: 11,
    //     type: 'transactionList',
    //     moved: false,
    //     options: {
    //       thingId: '',
    //       property: '',
    //       historyLimit: 300,
    //     },
    //   },
    //   {
    //     h: 11,
    //     i: 32,
    //     w: 10,
    //     x: 10,
    //     y: 11,
    //     type: 'transactionStats',
    //     moved: false,
    //     options: {
    //       thingId: '',
    //       property: '',
    //       historyLimit: 300,
    //     },
    //   },
    //   {
    //     h: 11,
    //     i: 808,
    //     w: 10,
    //     x: 0,
    //     y: 0,
    //     type: 'chargHis',
    //     moved: false,
    //     options: {},
    //   },
    // ];

    // populate each dashboard item
    // await this.populateAllItems();

    // set default range
    this.selectedRange = '-1h';

    // refresh range regularly
    // setInterval(async () => {
    //   if (this.refreshingFromInterval) return;
    //   this.refreshingFromInterval = true;
    //   await this.refreshRange();
    //   this.refreshingFromInterval = false;
    // }, 1000);
  },
  methods: {
    setOption(item, options) {
      // eslint-disable-next-line no-param-reassign
      if (!item.options) {
        // eslint-disable-next-line no-param-reassign
        item.options = {};
      }
      // merge original and provided options
      Object.assign(item.options, options);
      this.save();
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    flip(id) {
      // eslint-disable-next-line max-len
      if (this.isFlipped.includes(id)) {
        this.isFlipped = this.isFlipped.filter((item) => item !== id);
        this.map.dragPan.enable();
      } else {
        this.isFlipped.push(id);
        this.map.dragPan.disable();
      }
    },
    saveItemOptions(item) {
      console.log(item.options);
      this.save();
    },
    addItem(type) {
      if (!Object.keys(defaultOptions).includes(type)) {
        console.log('no default options for type', type);
        // return;
      }

      // find free index
      // TODO: is this needed?
      let newIndex = 0;
      if (this.layout.length === 1) {
        if (this.layout[0].i === 0) {
          newIndex = 1;
        }
      } else if (this.layout.length > 1) {
        const indices = this.layout.map((item) => item.i);
        indices.sort();
        console.log(indices);
        // find first free index
        if (indices[0] !== 0) {
          for (let i = 1; i < indices.length; i += 1) {
            if (indices[i] - indices[i - 1] > 1) {
              newIndex = indices[i] + 1;
              break;
            }
          }
          if (newIndex === 0) {
            newIndex = indices[indices.length - 1] + 1;
          }
        }
      }

      this.layout.push({
        type,
        x: 0,
        y: 0,
        w: 4,
        h: 10,
        // i: newIndex,
        i: parseInt(Math.random() * 1000, 10),
        options: defaultOptions[type] ?? [],
      });

      this.save();
    },
    removeItem(itemId) {
      this.layout = this.layout.filter((item) => item.i !== itemId);
      this.save();
    },
    refreshRange() {
      const range = this.selectedRange;

      // eslint-disable-next-line no-useless-escape
      const rangeRegex = /(\-?\d+)([s|m|h|d|]*)/;
      const matches = range.match(rangeRegex);

      if (matches.length !== 3) {
        console.log('refreshRange: invalid range', range);
        return;
      }

      const amount = parseInt(matches[1], 10);
      const unit = matches[2];

      let start;
      let end;
      const now = moment();
      if (amount >= 0) {
        start = now;
        end = now.clone().add(amount, unit);
      } else if (amount < 0) {
        start = now.clone().add(amount, unit);
        end = now;
      }

      this.timeRange = {
        min: {
          asJSDate: start.toDate(),
          asMoment: start,
        },
        max: {
          asJSDate: end.toDate(),
          asMoment: end,
        },
      };
    },
    fetchHistoryForAllItems() {
      this.layout.forEach((item) => this.fetchStateHistory(item));
    },
    async fetchStateHistory(/* item */) {
      // if (!this.timeRange.min.asMoment || !this.timeRange.max.asMoment) return;
      // if (!item.thingId) return;
      // const dashboardItem = item;
      // const histories = await api.getThingStateHistory(item.thingId, '*', {
      //   time: {
      //     min: this.timeRange.min.asMoment.format('YYYY-MM-DD HH:MM:ss.SSSZZ'),
      //     max: this.timeRange.max.asMoment.format('YYYY-MM-DD HH:MM:ss.SSSZZ'),
      //   },
      //   asSeries: true,
      //   numSamples: 1000,
      // });
      // dashboardItem.options.series = [];
      // // distribute y axes properly to left and right side
      // const numAttributes = Object.keys(histories).length;
      // if (numAttributes % 2 === 0) {
      //   // even amount of axes
      //   setYAxes(dashboardItem, {
      //     numAxis: { left: numAttributes / 2, right: numAttributes / 2 },
      //   });
      // } else {
      //   // uneven amount of axes
      //   setYAxes(dashboardItem, {
      //     numAxis: { left: numAttributes / 2 + 1, right: numAttributes / 2 },
      //   });
      // }
      // // add all series
      // let yAxisIndex = 0;
      // Object.keys(histories).forEach((property) => {
      //   // dashboardItem.propertyYAxis[property] = yAxisIndex;
      //   dashboardItem.options.series.push(
      //     makeSeries(property, histories[property], yAxisIndex),
      //   );
      //   yAxisIndex += 1;
      // });
    },
    async updateChart(item) {
      if (!item) return;
      if (!item.thingId || item.thingId === '-1') return;
      // if (!(item.thingId in chartsIndex)) chartsIndex[item.thingId] = {};
      // if (!(item.property in chartsIndex[item.thingId])) {
      //   chartsIndex[item.thingId][item.property] = [];
      // }
      // chartsIndex[item.thingId][item.property].push(item);
      await this.fetchStateHistory(item);
    },
    releaseChartBinding(item) {
      if (!(item.thingId in chartsIndex)) return;
      if (!(item.property in chartsIndex[item.thingId])) return;

      chartsIndex[item.thingId][item.property] = chartsIndex[item.thingId][item.property].filter(
        (boundItem) => boundItem.i !== item.i,
      );
    },
    thingChanged(item, change) {
      const dashboardItem = item;
      this.releaseChartBinding(item);
      dashboardItem.thingId = change.thingId;
      this.save();
      this.updateChart(item);
    },
    async populateAllItems() {
      await asyncForEachParallel(this.layout, (item) => this.updateChart(item));
    },
    historyLimitChanged(item, value) {
      const dashboardItem = item;
      clearTimeout(this.typingTimer);
      dashboardItem.historyLimit = value;
      this.typingTimer = setTimeout(() => {
        this.fetchStateHistory(item);
      }, 300);
    },
    save: debounce(function save() {
      const items = [];

      this.layout.forEach((item) => {
        // deep copy as we remove data now
        const copiedItem = deepCopy(item);

        // remove data
        // TODO: abstract this for all widgets
        if (copiedItem.type === 'chart') {
          // Object.keys(copiedItem.options.series).forEach((seriesItem) => {
          //   copiedItem.options.series[seriesItem].data = [];
          // });
        }

        // store data-less copy
        items.push(copiedItem);
      });

      // push to server
      api.saveDashboardItems(1, items);
    }, 500),
  },
};
