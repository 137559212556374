//
//
//
//
//
//
//
//
//
//

import QueryInterpolator from '@/components/generic/QueryInterpolator.vue';

export default {
  props: ['options'],
  components: {
    QueryInterpolator,
  },
};
