//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import debounce from 'debounce';

export default {
  props: ['value'],
  data() {
    return {
      thingId: -1,
      propertyName: '',
      data: [],
      selected: null,
      isFetching: false,
      state: {},
      thing: null,
    };
  },
  beforeMount() {
  },
  mounted() {
    if (this.value) {
      this.thingId = this.value.thingId;
      this.propertyName = this.value.property.name;
    }
  },
  watch: {
    async thingId(newId) {
      if (newId === -1) return;
      const thing = await this.$store.dispatch('things/getOne', newId);
      if (!thing) {
        console.log('thing undefined');
        return;
      }
      this.state = Object.keys(thing.state);
      this.$refs.autocomplete.setSelected({ id: thing.id, name: thing.name, type: thing.type });
      this.updateThingAndProperty();
      this.updateSuggestions();
    },
  },
  methods: {
    async thingChanged(option) {
      if (!option) { this.thingId = -1; return; }
      this.thingId = option.id;
    },
    propertyChanged(event) {
      this.propertyName = event.target.value;
      this.updateThingAndProperty();
    },
    updateSuggestions: debounce(async function fetch(name) {
      this.isFetching = true;
      const result = await this.$store.dispatch('things/getMultiple', {
        limit: 10,
        includesText: typeof name === 'string' ? name : '',
      });
      this.data = result.things.map((thing) => ({ id: thing.id, name: thing.name }));
      this.isFetching = false;
    }, 500),
    updateThingAndProperty() {
      this.$emit('input', {
        thingId: this.thingId,
        property: this.propertyName,
      });
    },
  },
};
