//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable */
export default {
  props: ['config'],
  data() {
    return {
      originalStroke: '',
      hovering: false,
      aboutToBeSelected: false,
      mouseJustUpJoint: false,
      isSelected: false,
      joints: [],
      draggingJoint: -1,
    };
  },
  mounted() {
    for (let i = 0; i < this.config.points.length; i += 2) {
      this.joints.push({
        x: this.config.points[i],
        y: this.config.points[i + 1],
        radius: 3,
        fill: 'lightgrey',
        stroke: '#666',
        strokeWidth: 2,
      });
    }
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'drawer/setMouseXY') {
        this.mouseMoveJoint(mutation.payload.mouseX, mutation.payload.mouseY);
      } else if (mutation.type === 'drawer/mouseClicked') {
        if (this.aboutToBeSelected) {
          this.isSelected = true;
          this.aboutToBeSelected = false;
        } else if (!this.mouseJustUpJoint) this.isSelected = false;
        else this.mouseJustUpJoint = false;
      }
    });
  },
  methods: {
    mouseEnterLine() {
      if (this.draggingJoint > -1) return;
      this.originalStroke = this.config.stroke;
      this.config.stroke = 'grey';
      this.hovering = true;
    },
    mouseLeaveLine() {
      if (this.draggingJoint > -1) return;
      this.config.stroke = this.originalStroke;
      this.hovering = false;
    },
    mouseClickLine() {
      if (this.hovering) {
        this.aboutToBeSelected = true;
      }
    },
    mouseEnterJoint(index) {
      this.joints[index].fill = 'black';
    },
    mouseLeaveJoint(index) {
      this.joints[index].fill = 'lightgrey';
    },
    mouseDownJoint(index) {
      this.draggingJoint = index;
    },
    mouseUpJoint() {
      this.draggingJoint = -1;
      this.mouseJustUpJoint = true;
    },
    mouseMoveJoint(x, y) {
      if (this.draggingJoint > -1) {
        this.joints[this.draggingJoint].x = x;
        this.joints[this.draggingJoint].y = y;
        this.config.points[this.draggingJoint * 2] = x;
        this.config.points[this.draggingJoint * 2 + 1] = y;
      }
    },
  },
  computed: {},
};
