import api from '@/api';
import { arrayToObject } from '../../util';

// initial state
const persistedState = {
  byIndex: [],
};

// getters
const getters = {};

// actions
const actions = {
  async sync({ commit }) {
    const functions = await api.getFunctions();
    commit('setFunctions', functions);
  },
  async create({ dispatch }) {
    const newFunction = await api.createFunction();
    dispatch('sync');
    return newFunction;
  },
  async save({ commit }, function_) {
    await api.saveFunction(function_.id, function_.content);
    commit('setFunctionContent', function_);
  },
  async delete({ dispatch }, ids) {
    await api.deleteFunctions(ids);
    dispatch('sync');
  },
  async patch({ dispatch }, { id, patch }) {
    await api.patchFunction(id, patch);
    dispatch('sync');
  },
};

// mutations
const mutations = {
  setFunctions(state, functions) {
    console.log('setting functions', functions);
    state.byIndex = functions;
    state.byId = arrayToObject(state.byIndex, 'id');
  },
  setFunctionContent(state, function_) {
    state.byId[function_.id].content = function_.content;
  },
};

export default {
  namespaced: true,
  state: persistedState,
  getters,
  actions,
  mutations,
};
