import gql from 'graphql-tag';

export default ({ queryVariables, subscriptionVariables }) => ({
  create() {
    return {
      query() {
        return gql`query FunctionLogs($path: String = "") {
          function_logs(where: {path: {_eq: $path}}, order_by: {time: desc}, limit: 50) {
            time,
            invocationuuid,
            path,
            message,
            level
          }
}`;
      },
      variables: queryVariables,
      subscribeToMore: {
        document: gql`
        subscription FunctionLogs($path: String = "", $minTime: timestamptz = "") {
          function_logs(where: {path: {_eq: $path}, time: {_gte: $minTime}}, order_by: {time: desc}, limit: 50) {
            time,
            invocationuuid,
            path,
            message,
            level
          }
        }
        `,
        variables: subscriptionVariables,
        updateQuery: (previousResult, { subscriptionData }) => {
          if (previousResult) {
            // append to existing array
            previousResult[this.key].unshift(
              ...subscriptionData.data[this.key],
            );
            return previousResult;
          }
          const result = {};
          result[this.key] = [];
          return result;
        },
      },
    };
  },
  key: 'function_logs',
  columns: [
    { label: 'Zeitpunkt', field: 'time', type: 'datetime' },
    { label: 'Nachricht', field: 'message', type: 'object' },
  ],
});
