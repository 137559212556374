//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      entities: [],
      selectedId: '33077',
    };
  },
  watch: {
    entities() {
      console.log('entities changed');
    },
    selectedId(val) {
      const myElement = document.getElementById(`entry-for-${val}`);
      const topPos = myElement.offsetTop;
      document.getElementById('entity-list').scrollTop = topPos - document.getElementById('entity-list').clientHeight / 2;
    },
  },
};
