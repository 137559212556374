//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import ActionBar from '@/components/generic/ActionBar.vue';

export default {
  data() {
    return {
      checkedRows: [],
      paginationPerPage: 14,
      paginationCurrent: 1,
      // needed since we access $refs in computed property.
      // see https://stackoverflow.com/a/43531779
      isMounted: false,
    };
  },
  methods: {
    deleteCheckedRows() {
      // // ask the user how to continue
      // const answer = window.confirm(
      //   `Are you sure to delete these ${this.checkedRows.length} actions?`
      // );

      // // check his answer and act accordingly
      // if (!answer) return;

      const ids = this.checkedRows.map((row) => row.id);

      this.$store.dispatch('actions/delete', ids).then(() => this.uncheckAll());
    },
    uncheckAll() {
      this.checkedRows = [];
    },
    tableClick(row) {
      console.log(row);
      this.$router.push({ name: 'action', params: { id: row.id } });
    },
    keyDownHandler(e) {
      if (e.ctrlKey && e.key === 'f') {
        this.$refs.actionBar.focus('search');
        e.preventDefault();
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {
          highlight: undefined,
        },
      });
    }, 3500);
    window.addEventListener('keydown', this.keyDownHandler);
    this.isMounted = true;
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDownHandler);
  },
  components: {
    ActionBar,
  },
  watch: {
    paginationCurrent(newValue) {
      if (newValue === 0 && this.actions.length > 0) this.paginationCurrent = 1;
    },
  },
  computed: {
    searchTerm() {
      if (!this.isMounted) return '';
      if (!('actionBar' in this.$refs)) return '';
      return this.$refs.actionBar.inputs.search;
    },
    ...mapState('actions', {
      actions(state) {
        // TODO: do this server-side
        if (this.searchTerm === '') return state.byIndex;
        return state.byIndex.filter((item) => {
          if (item.name.toLowerCase().includes(this.searchTerm.toLowerCase())) return true;
          if (
            item.id
              .toString()
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase())
          ) return true;
          return false;
        });
      },
    }),
    actionsForTable() {
      return this.actions.slice(
        (this.paginationCurrent - 1) * this.paginationPerPage,
        (this.paginationCurrent - 1) * this.paginationPerPage + this.paginationPerPage,
      );
    },
    paginationTotal() {
      // return this.$store.state.things.totalAmount;
      return this.actions.length;
    },
    actionBarItems() {
      return [
        {
          type: 'button',
          label: 'Create',
          route: '/actions?mode=create',
          icon: 'plus',
        },
        {
          type: 'button',
          label: 'Delete',
          icon: 'delete',
          disabled: this.checkedRows.length === 0,
          callback: this.deleteCheckedRows,
          isDanger: true,
        },
        {
          type: 'search',
          ref: 'search',
        },
      ];
    },
  },
};
