export default {
  theme: {
    //   mode: 'dark',
    palette: 'palette2',
    //   monochrome: {
    //     enabled: true,
    //     color: 'green',
    //     shadeTo: 'light',
    //     shadeIntensity: 0.65,
    //   },
  },
  title: {
    //   text: 'Abgenommene Leistung',
    floating: false,
    align: 'center',
    margin: 2,
    style: {
      fontSize: '30px',
    },
  },
  legend: {
    fontSize: '15px',
    //   floating: true,
    position: 'bottom',
  },
  // fill: {
  //   type: 'gradient',
  //   gradient: {
  //     shadeIntensity: 1,
  //     inverseColors: false,
  //     opacityFrom: 0.5,
  //     opacityTo: 0.1,
  //     stops: [20, 100, 100, 100],
  //   },
  // },
  dataLabels: {
    enabled: false,
  },
  chart: {
    // id: 'vuechart-example',
    // type: 'area',
    //   stacked: true,
    stacked: false,
    zoom: {
      autoScaleYaxis: true,
    },
  },
  xaxis: {
    type: 'datetime',
    tickAmount: 22,
    tooltip: {
      enabled: false,
    },
  },
  stroke: {
    width: 2,
    curve: 'stepline',
  },
  yaxis: {
    labels: {
      minWidth: 40,
      style: {
        fontSize: '12px',
      },
    },
  },
};
