//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import ActionBar from '@/components/generic/ActionBar.vue';

export default {
  components: { ActionBar },
  data() {
    return {
      datacollection: null,
      checkedRows: [],
    };
  },
  computed: {
    actions() {
      return [
        {
          type: 'button',
          callback: this.createNewApp,
          icon: 'plus',
          label: 'Create',
        },
        {
          type: 'button',
          route: '/apps?mode=create',
          icon: 'rocket-launch-outline',
          label: 'Launch from template',
        },
        {
          type: 'button',
          disabled: this.checkedRows.length === 0,
          callback: this.deleteCheckedRows,
          isDanger: true,
          icon: 'delete',
          label: 'Delete',
        },
      ];
    },
    ...mapState('apps', {
      apps: (state) => state.byIndex,
    }),
  },
  methods: {
    async createNewApp() {
      const { id } = await this.$store.dispatch('apps/create');
      this.$router.push({ name: 'app', params: { id } });
    },
    deleteCheckedRows() {
      // // ask the user how to continue
      // const answer = window.confirm(
      //   `Are you sure to delete these ${this.checkedRows.length} providers?`
      // );

      // // check his answer and act accordingly
      // if (!answer) return;

      const ids = this.checkedRows.map((row) => row.id);

      this.$store.dispatch('apps/delete', ids).then(() => {
        this.checkedRows = [];
      });
    },
    tableClick(row) {
      this.$router.push({ name: 'app', params: { id: row.id } });
    },
  },
};
