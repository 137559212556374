/* eslint-disable */

import mapboxgl from 'mapbox-gl';
import * as THREE from 'threebox-plugin/src/three';
import threebox from 'threebox-plugin';
import * as turf from '@turf/turf';
import EventEmitter from 'events';
import polylabel from '@mapbox/polylabel';
// import { Matrix4 } from 'three';
// Import three.js IFCLoader
// import { IFCLoader } from 'web-ifc-three/IFCLoader';
// import {
//     IFCLoader
// } from "three/examples/jsm/loaders/IFCLoader";
import { acceleratedRaycast, computeBoundsTree, disposeBoundsTree } from 'three-mesh-bvh';

const calculateModelTransform = (latlng) => {
  // parameters to ensure the model is georeferenced correctly on the map
  const modelOrigin = latlng;
  const modelAltitude = 0;
  const modelRotate = [Math.PI, 0, 0];

  const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
    modelOrigin,
    modelAltitude,
  );

  // const firstX = [50.77215733125806, 6.200449731831912]
  // const second =

  // transformation parameters to position, rotate and scale the 3D model onto the map
  const modelTransform = {
    translateX: modelAsMercatorCoordinate.x,
    translateY: modelAsMercatorCoordinate.y,
    translateZ: modelAsMercatorCoordinate.z,
    rotateX: modelRotate[0],
    rotateY: modelRotate[1],
    rotateZ: modelRotate[2],
    /* Since our 3D model is in real world meters, a scale transform needs to be
     * applied since the CustomLayerInterface expects units in MercatorCoordinates.
     */
    scaleX: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
    scaleY: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
    scaleZ: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
  };

  return modelTransform;
};

class ThreeLayer extends EventEmitter {
  constructor(id) {
    super();
    this.id = id;
    this.type = 'custom';
    this.renderingMode = '3d';
    this.ifcModels = [];
    // this.ifcLoader = new IFCLoader();
    // this.setupIfcLoader();
    this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.25, 20);
    // this.camera.position.set(-1.8, 0.9, 2.7);
    this.scene = new THREE.Scene();
    this.raycaster = new THREE.Raycaster();
    this.raycasterArrow = new THREE.ArrowHelper(
      this.raycaster.ray.direction,
      this.raycaster.ray.origin,
      30000,
      0xff0000,
    );
    this.selectedObject = null;
    this.mouse = new THREE.Vector2();

    // this.shape = shape;
    // this.lnglat = lnglat;

    // this.lightIntensity = lightIntensity;

    // this.modelTransform = calculateModelTransform(lnglat);

    // console.log('modelTransform', this.modelTransform.scaleX * 52);

    // const axesHelper = new THREE.AxesHelper(100);
    // this.scene.add(axesHelper);
  }

  init() {
    this.setupIfcLoader();
  }

  setupThreeMeshBVH() {
    // this.ifcLoader.ifcManager.setupThreeMeshBVH(
    //   computeBoundsTree,
    //   disposeBoundsTree,
    //   acceleratedRaycast,
    // );
  }

  remove = false;

  async editSubset(type) {
    const ids = await this.ifcLoader.ifcManager.getAllItemsOfType(0, type, false);
    if (this.remove) this.ifcLoader.ifcManager.removeFromSubset(0, ids);
    else
      this.ifcLoader.ifcManager.createSubset({
        modelID: 0,
        ids,
        applyBVH: false,
        removePrevious: false,
      });
  }

  async setupIfcLoader() {
    console.log(this.ifcLoader);
    // await this.ifcLoader.ifcManager.useWebWorkers(true, 'IFCWorker.js');
    this.setupThreeMeshBVH();
    this.setupFileOpener();

    window.addEventListener('keydown', async (event) => {
      if (event.code === 'KeyX') {
        this.remove = !this.remove;
      }
      if (event.code === 'KeyB') {
        await this.editSubset(IFCWALLSTANDARDCASE);
      }
      if (event.code === 'KeyC') {
        await this.editSubset(IFCSLAB);
      }
      if (event.code === 'KeyD') {
        await this.editSubset(IFCWINDOW);
      }
    });
  }

  // TODO: CleanUp() method to release webgl memory of IFCLoader
  releaseMemory() {
    // this.ifcLoader.ifcManager.disposeMemory();
  }

  setupFileOpener() {
    const input = document.querySelector('input[type="file"]');
    if (!input) {
      console.log('did not find input');
      return;
    }
    input.addEventListener(
      'change',
      async (changed) => {
        // await this.ifcLoader.ifcManager.useJSONData();
        await this.loadIFC(changed);
      },
      false,
    );
  }

  onClick(event) {}

  onMouseMove(event) {
    // this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    // this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    // console.log(this.mouse.x, this.mouse.y);
    // this.raycaster.setFromCamera(this.mouse, this.camera);
    // this.raycasterArrow.setDirection(this.raycaster.ray.direction);
    // // setTimeozut
    // this.raycasterArrow.position.set(
    //   this.raycaster.ray.origin.x,
    //   this.raycaster.ray.origin.y,
    //   this.raycaster.ray.origin.z,
    // );
    // console.log('moving raycaster to', this.raycasterArrow.position);
    // var intersects = this.raycaster.intersectObject(this.scene, true);
    // console.log(this.mouse.x, this.mouse.y);
    // if (intersects.length > 0) {
    //   var object = intersects[0].object;
    //   console.log(intersects);
    //   object.material.color.set(Math.random() * 0xffffff);
    // } else {
    //   // console.log("no intersections");
    // }
  }

  async loadIFC(changed) {
    console.log('loading ifc');
    const start = window.performance.now();

    const ifcURL = URL.createObjectURL(changed.target.files[0]);
    this.ifcLoader.ifcManager.setOnProgress((event) => console.log(event));

    const firstModel = Boolean(this.ifcModels.length === 0);

    await this.ifcLoader.ifcManager.applyWebIfcConfig({
      COORDINATE_TO_ORIGIN: firstModel,
      USE_FAST_BOOLS: false,
    });

    const ifcMesh = await this.ifcLoader.loadAsync(ifcURL);
    // console.log(ifcMesh);

    if (firstModel) {
      const matrixArr = await this.ifcLoader.ifcManager.ifcAPI.GetCoordinationMatrix(
        ifcMesh.modelID,
      );
      const matrix = new THREE.Matrix4().fromArray(matrixArr);
      this.ifcLoader.ifcManager.setupCoordinationMatrix(matrix);
    }

    this.ifcModels.push(ifcMesh);
    const tbObj = tb.Object3D({ obj: ifcMesh }).setCoords([6.07215410814925, 50.7793559720197, 0]);
    this.threebox.add(tbObj);
    console.log(tbObj);
    // this.scene.add(ifcModel);
    // console.log(ifcMesh.coordinates)
    // this.threebox.add(ifcModel);

    const stop = window.performance.now();

    console.log(`Time Taken to load = ${(stop - start) / 1000} seconds`);
  }

  onAdd(map, gl) {
    // use the Mapbox GL JS map canvas for three.js
    // this.renderer = new THREE.WebGLRenderer({
    //   canvas: map.getCanvas(),
    //   context: gl,
    //   antialias: true,
    // });

    // this.render.shadowMapEnabled = true;
    // this.renderer.autoClear = false;
    // this.renderer.domElement.addEventListener(
    //   'mousemove',
    //   (event) => this.onMouseMove(event),
    //   false,
    // );
    // const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 1);
    // hemiLight.position.set(0, 60, 0);
    // hemiLight.castShadow = true;
    // this.scene.add(hemiLight);
    // const hhelper = new THREE.HemisphereLightHelper(hemiLight, 5);
    // this.scene.add(hhelper);

    // const dirLight = new THREE.DirectionalLight(0xffffff, this.lightIntensity);
    // this.dirLight = dirLight;
    // // const helper = new THREE.DirectionalLightHelper(dirLight, 5);
    // // this.scene.add(helper);
    // dirLight.position.set(-10, 100, 5);
    // dirLight.target.position.set(0, 100, 0);
    // dirLight.name = 'dirlight';
    // // dirLight.shadowCameraVisible = true;

    // this.scene.add(dirLight);

    // dirLight.castShadow = true;
    // dirLight.receiveShadow = true;
    // dirLight.shadow.mapSize.width = 1024 * 2;
    // dirLight.shadow.mapSize.height = 1024 * 2;

    // const d = 300;

    // dirLight.shadow.camera.left = -d;
    // dirLight.shadow.camera.right = d;
    // dirLight.shadow.camera.top = d;
    // dirLight.shadow.camera.bottom = -d;

    // dirLight.shadow.camera.far = 3500;
    // dirLight.shadow.bias = -0.0001;

    // const loader = new GLTFLoader();
    // loader.load(
    //   this.path,
    //   (gltf) => {
    //     // gltf.scene.receiveShadow = true;
    //     // gltf.scene.castShadow = true;
    //     var material = new THREE.MeshPhongMaterial({
    //       color: 0xc3c3c3,
    //       opacity: 0.9,
    //       specular: 0x6b6b6b,
    //       shininess: 0,
    //       transparent: true,
    //       shading: THREE.FlatShading,
    //       emissive: 1
    //     });
    //     gltf.scene.children.forEach(child => {
    //       child.material = material;
    //     })
    //     this.scene.add(gltf.scene);
    //     console.log(gltf.scene);
    //   },
    // );
    this.map = map;

    this.threebox = window.tb = new threebox.Threebox(map, map.getCanvas().getContext('webgl'), {
      defaultLights: true,
      enableIterativeSelection: true,
      enableSelectingFeatures: true, //change this to false to disable fill-extrusion features selection
      enableSelectingObjects: true, //change this to false to disable 3D objects selection
      enableDraggingObjects: true, //change this to false to disable 3D objects drag & move once selected
      enableRotatingObjects: true, //change this to false to disable 3D objects rotation once selected
      enableTooltips: true, // change this to false to disable default tooltips on fill-extrusion and 3D models
    });
    // const height = 5;

    // const earthRadius = 6371e3;

    // material.depthTest = false;
    // material.opacity = 0.3;
    // material.transparent = true;

    // var sphere = this.threebox.sphere({ material, opacity: 0.5, transparent: true }).setCoords([this.lnglat[0], this.lnglat[1], 1]);
    // console.log("sphere", sphere)
    // sphere.addEventListener('ObjectMouseOver', () => console.log("mouseover"), false);
    // sphere.addEventListener('ObjectMouseOut', () => console.log("mouseout"), false);
    // // add sphere to the scene
    // this.threebox.add(sphere);

    // // calculate haversine distance
    // const haversineDistance = (lat1, lon1, lat2, lon2) => {
    //   // metres
    //   const φ1 = lat1 * (Math.PI / 180); // φ, λ in radians
    //   const φ2 = lat2 * (Math.PI / 180);
    //   const Δφ = (lat2 - lat1) * (Math.PI / 180);
    //   const Δλ = (lon2 - lon1) * (Math.PI / 180);

    //   const a =
    //     Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    //     Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    //   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    //   const d = earthRadius * c; // in metres
    //   return d;
    // };

    // // calculate manhattan distance from latitude and longitude
    // const manhattanDistance = (lnglat) => {
    //   console.log('calculating distance', lnglat, this.lnglat);
    //   const [lng, lat] = lnglat;
    //   const [originLng, originLat] = this.lnglat;
    //   const longitudeDistance =
    //     (lng > originLng ? 1 : -1) * haversineDistance(0, lng, 0, originLng);
    //   const latitudeDistance = (lat > originLat ? -1 : 1) * haversineDistance(lat, 0, originLat, 0);

    //   return { latitudeDistance, longitudeDistance };
    // };

    // console.log(manhattanDistance([6.043558775304916, 50.784379552131185]));

    // const distance = manhattanDistance([6.042259727496088, 50.78437415945757]);

    // const shape = new THREE.Shape();
    // const firstInWebMercator = manhattanDistance(this.shape[0][0]);
    // shape.moveTo(firstInWebMercator.longitudeDistance, firstInWebMercator.latitudeDistance);
    // this.shape[0].forEach((edge) => {
    //   const edgeInWebMercator = manhattanDistance(edge);
    //   console.log(edgeInWebMercator);
    //   // apply values from translation matrix
    //   shape.lineTo(edgeInWebMercator.longitudeDistance, edgeInWebMercator.latitudeDistance);
    // });
    // shape.lineTo(firstInWebMercator.longitudeDistance, firstInWebMercator.latitudeDistance);

    // const extrudeSettings = {
    //   steps: 10,
    //   depth: 10,
    //   bevelEnabled: false,
    //   bevelThickness: 1,
    //   bevelSize: 1,
    //   bevelOffset: 0,
    //   bevelSegments: 1,
    // };

    // const extrudeGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
    // const extrudeMaterial = material;
    // const extrudeMesh = new THREE.Mesh(extrudeGeometry, extrudeMaterial);
    // this.scene.add(extrudeMesh);

    // const geometry = new THREE.BoxGeometry(height, height, height)
    //   .translate(0, height / 2, 0)
    //   .rotateX(Math.PI / 2);

    // const cube = new THREE.Mesh(geometry, material);
    // const wireframe = new THREE.WireframeGeometry(geometry);

    // const line = new THREE.LineSegments(wireframe, material);
    // line.material.depthTest = false;
    // // line.material.opacity = 1;
    // // line.material.transparent = false;

    // this.scene.add(cube);

    // var edges = new THREE.LineSegments(
    //   new THREE.EdgesGeometry(extrudeGeometry),
    //   new THREE.LineBasicMaterial({
    //     color: 0xffffff,
    //     linewidth: 1,
    //     linecap: 'round', //ignored by WebGLRenderer
    //     linejoin: 'round', //ignored by WebGLRenderer
    //   }),
    // );
    // this.scene.add(edges);

    // this.scene.add(this.raycasterArrow);
  }

  clear() {
    this.threebox.clear();
  }

  removeExtrusion(name) {
    this.threebox.removeByName(name);
  }

  addExtrusion(opts) {
    const { geometry: inputGeometry } = opts;

    const material = new THREE.MeshPhongMaterial({
      color: opts.color ?? 'white',
      // side: THREE.FrontSide,
      opacity: opts.opacity ?? 0.4,
      transparent: true,
      // depthWrite: false,
      // depthTest: opts.depthTest ?? false,
    });

    // create threebox extrusion
    const extrusion = this.threebox.extrusion({
      coordinates: inputGeometry.coordinates,
      // TODO: where does the factor (0.041) for depth come from
      //       and how can we correlate it to elevation / height?
      geometryOptions: { depth: (opts.height ?? 1) * 0.041, bevelEnabled: false },
      materials: material,
    });

    // calculate center
    const center = turf.center(inputGeometry).geometry.coordinates;

    // move extrusion to center of geometry, since extrusion is anchored in center
    extrusion.setCoords([...center, opts.elevation ?? 0]);

    const threeMesh = extrusion.children[0].children[0];
    const { geometry: threeGeometry } = threeMesh;

    let mouseOver = false;

    // poly center not working yet...
    // const polyCenter = polylabel(inputGeometry.coordinates);
    // if(opts.tooltip) extrusion.addTooltip(opts.tooltip, true, polyCenter, true, 1, true);
    if (opts.tooltip) extrusion.addTooltip(opts.tooltip, true);
    // if(opts.tooltip) extrusion.addTooltip(opts.tooltip, true, polyCenter, true, 1, true);

    extrusion.addEventListener(
      'SelectedChange',
      () => {
        const previousSelection = this.selectedObject;
        this.selectedObject = { group: extrusion, mesh: threeMesh };
        // this.emit('extrusionSelected', opts.name)
        this.emit('SelectedChange', { now: this.selectedObject, prev: previousSelection });
      },
      false,
    );

    extrusion.addEventListener(
      'ObjectMouseOver',
      () => {
        if (mouseOver) return;
        // console.log('enter', threeMesh);
        mouseOver = true;
        // console.log('mousenter');
      },
      false,
    );
    extrusion.addEventListener(
      'ObjectMouseOut',
      () => {
        mouseOver = false;
        // console.log('leave', threeMesh);
      },
      false,
    );

    // return;

    // // create wireframe around the edges
    // const wireframe = new THREE.WireframeGeometry(threeGeometry);

    // const line = new THREE.LineSegments(wireframe, material);
    // line.material.depthTest = false;
    // // line.material.opacity = 1;
    // // line.material.transparent = false;

    // var edges = new THREE.LineSegments(
    //   new THREE.EdgesGeometry(threeGeometry),
    //   new THREE.LineBasicMaterial({
    //     color: 0xffffff,
    //     linewidth: 1,
    //     linecap: 'round', //ignored by WebGLRenderer
    //     linejoin: 'round', //ignored by WebGLRenderer
    //   }),
    // );

    // const wireframeObject = this.threebox.Object3D({ obj: edges });
    // wireframeObject.setCoords(center);

    // const wireframeGroup = new THREE.Group();
    // wireframeGroup.add(extrusion);
    // // wireframeGroup.add(wireframeObject);

    // add to scene
    // this.threebox.add(threeboxified);
    // this.threebox.add(wireframeGroup);
    this.threebox.add(extrusion);
    console.log(extrusion);

    return { object: extrusion, mesh: threeMesh };
  }

  render(gl, matrix) {
    // this.dirLight.position.x += 0.0001;
    // console.log(matrix);
    // const rotationX = new THREE.Matrix4().makeRotationAxis(
    //   new THREE.Vector3(1, 0, 0),
    //   this.modelTransform.rotateX,
    // );
    // const rotationY = new THREE.Matrix4().makeRotationAxis(
    //   new THREE.Vector3(0, 1, 0),
    //   this.modelTransform.rotateY,
    // );
    // const rotationZ = new THREE.Matrix4().makeRotationAxis(
    //   new THREE.Vector3(0, 0, 1),
    //   this.modelTransform.rotateZ,
    // );

    // const m = new THREE.Matrix4().fromArray(matrix);
    // const l = new THREE.Matrix4()
    //   .makeTranslation(
    //     this.modelTransform.translateX,
    //     this.modelTransform.translateY,
    //     this.modelTransform.translateZ,
    //   )
    //   .scale(
    //     new THREE.Vector3(
    //       // no clue where this offset factor is
    //       // coming from yet <3
    //       this.modelTransform.scaleX * 0.6339,
    //       -this.modelTransform.scaleY,
    //       -this.modelTransform.scaleZ,
    //     ),
    //   )
    //   .multiply(rotationX)
    //   .multiply(rotationY)
    //   .multiply(rotationZ);

    // this.camera.projectionMatrix = m.multiply(l);
    // this.renderer.state.reset();
    // this.renderer.render(this.scene, this.camera);
    // this.map.triggerRepaint();

    tb.update();
  }
}

export default ThreeLayer;
