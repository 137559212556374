//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['thingId', 'property', 'value'],
  computed: {
    possibleValues() {
      if (this.property.name === 'updateReason') {
        return [
          'CHARGINGSTARTED',
          'CHARGINGDONE',
          'VEHCSHUTDOWN_SECURED',
          'CHARGING_INTERRUPED',
          'CHARGING_PAUSED',
          'CHARGING_DONE',
          'CHARGING_STARTED',
          'CYCLIC_RECHARGING',
          'DOOR_STATE_CHANGED',
          'NO_CYCLIC_RECHARGING',
          'NO_LSC_TRIGGER',
          'ON_DEMAND',
          'PREDICTION_UPDATE',
          'TEMPORARY_POWER_SUPPLY_FAILURE',
          'UNKNOWN',
          'VEHICLE_MOVING',
          'VEHICLE_SECURED',
          'VEHICLE_SHUTDOWN',
          'VEHICLE_SHUTDOWN_SECURED',
          'VEHICLE_UNSECURED',
        ];
      }
      return [];
    },
  },
  methods: {
    valueChanged() {
      this.$emit('input', this.$refs.select.value);
    },
  },
};
