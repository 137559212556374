//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Loader from '@/components/generic/Loader.vue';

export default {
  props: ['processid', 'providerLabel'],
  sockets: {
    processData() {
      this.evaluateProcessState += 1;
    },
  },
  components: {
    Loader,
  },
  methods: {
    prepareDescription(step) {
      let description = this.description[step.name][step.state];
      if (step.parameters !== undefined) {
        Object.keys(step.parameters).forEach((parameter) => {
          description = description.replace(
            new RegExp(`\\$\\{${parameter}\\}`, 'g'),
            step.parameters[parameter],
          );
        });
      }
      return description;
    },
  },
  data() {
    return {
      evaluateProcessState: 1,
      description: {
        auth: {
          'in-progress': 'Authentifiziere mit externem Dienst...',
          finished: 'Authentifiziert with externem Dienst.',
          // eslint-disable-next-line no-template-curly-in-string
          error: 'Failed to authenticate with external service: ${message}',
        },
        'get-devices': {
          'in-progress': 'Empfange Geräteliste...',
          finished: 'Geräteliste empfangen.',
          // eslint-disable-next-line no-template-curly-in-string
          error: 'Failed to get device list: ${message}',
        },
        'enable-http-endpoint': {
          'in-progress': 'Exposing HTTP endpoint...',
          finished: 'HTTP endpoint exposed.',
        },
        'create-dataconnector': {
          'in-progress': 'Creating data connector...',
          finished: 'Data connector created.',
        },
        'sync-dataconnector': {
          'in-progress': 'Syncing data...',
          finished: 'Data synced.',
        },
        'persist-provider': {
          'in-progress': 'Lege Integration an...',
          finished: 'Integration angelegt.',
        },
        'persist-things': {
          'in-progress': 'Lege Objekte an...',
          // eslint-disable-next-line no-template-curly-in-string
          finished: '${numberOfThings} neue Objekte angelegt.',
          // eslint-disable-next-line no-template-curly-in-string
          error: 'Error registering things: ${message}',
        },
        finished: {
          finished: 'Fertig!',
        },
      },
    };
  },
};
