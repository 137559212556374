//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['value'],
  data() {
    return {
      editing: false,
      valueTracked: this.value,
    };
  },
  watch: {
    value() {
      this.valueTracked = this.value;
    },
  },
  methods: {
    saveValue() {
      this.$emit('changed', this.valueTracked);
      this.editing = false;
    },
    editValue() {
      this.editing = true;
    },
  },
};
