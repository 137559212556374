//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TreeItem from './TreeItem.vue';

export default {
  data() {
    return {
      populateChild: () => {},
      mouseover: () => {},
      mouseleave: () => {},
      onCollapse: () => {},
      eyeClick: () => {},
      entities: [],
    };
  },
  components: {
    TreeItem,
  },
  methods: {
    navigateToEntityPage() {
      window.open(`/things/${this.selection.entity.id}`, '_blank');
    },
  },
};
