//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['keyName', 'values', 'defaultValue'],
  data() {
    return {
      previousKey: this.keyName,
      currentKey: this.keyName,
      currentValue: this.defaultValue,
    };
  },
  methods: {
    emitChange() {
      this.$emit('update', {
        key: this.currentKey,
        previousKey: this.previousKey,
        value: this.currentValue,
      });
    },
    keyChanged() {
      this.emitChange();
      this.previousKey = this.currentKey;
    },
    valueChanged() {
      this.emitChange();
    },
  },
};
