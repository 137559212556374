// eslint-disable-next-line
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
// import config from '@/config';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'threebox-plugin/dist/threebox.css';
import * as turf from '@turf/turf';
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import Vue from 'vue';
import api from '@/api';
import LayerControl from './controls/layers/index.ts';
import VueControl from './controls/VueControl.ts';
import ThingSummaryPanel from '../generic/ThingSummaryPanel.vue';
import EntitySelection from './controls/EntitySelection.vue';
import EntityListControl from './controls/EntityListControl.vue';
import EntityTreeView from './controls/EntityTreeView.vue';
import InfrastructureCreationControl from './draw-modes/infrastructure-creation/index.ts';
import arraymode from './draw-modes/arraymode';
import ActionBarControl from './controls/ActionBarControl.ts';
// import { createElement } from './domUtil.ts';
import VPopup from './popup/Popup.vue';
// load svgs
// TODO: try to load these on-demand somehow,
//       most likely through some mapbox facility.
import mdiCar from '../../assets/map-icons/car.svg';
import mdiCarKey from '../../assets/map-icons/car-key.svg';
import mdiCarElectric from '../../assets/map-icons/car-electric.svg';
import mdiEvStation from '../../assets/map-icons/ev-station.svg';
import mdiFlashCircle from '../../assets/map-icons/flash-circle.svg';
import mdiMapMarkerQuestionOutline from '../../assets/map-icons/map-marker-question-outline.svg';
import mdiScooterElectric from '../../assets/map-icons/scooter-electric.svg';
import mdiFileQuestionOutline from '../../assets/map-icons/file-question-outline.svg';
import armchair from '../../assets/map-icons/armchair.svg';
import circle from '../../assets/map-icons/circle.svg';
import projector from '../../assets/map-icons/projector.svg';
import chartBox from '../../assets/map-icons/chart-box.svg';
import speaker from '../../assets/map-icons/speaker.svg';
import business001 from '../../assets/map-icons/business/001-businesswoman.svg';
import business002 from '../../assets/map-icons/business/002-businessman.svg';
import business003 from '../../assets/map-icons/business/003-businessman.svg';
import business004 from '../../assets/map-icons/business/004-businessman.svg';
import business005 from '../../assets/map-icons/business/005-businessman.svg';
import business006 from '../../assets/map-icons/business/006-businessman.svg';
import business007 from '../../assets/map-icons/business/007-businesswoman.svg';
import business008 from '../../assets/map-icons/business/008-businesswoman.svg';
import business009 from '../../assets/map-icons/business/009-businesswoman.svg';
import business010 from '../../assets/map-icons/business/010-businesswoman.svg';
import business011 from '../../assets/map-icons/business/011-businessman.svg';
import business012 from '../../assets/map-icons/business/012-businesswoman.svg';
import business013 from '../../assets/map-icons/business/013-businessman.svg';
import business014 from '../../assets/map-icons/business/014-businessman.svg';
import business015 from '../../assets/map-icons/business/015-businessman.svg';
import business016 from '../../assets/map-icons/business/016-businessman.svg';
import business017 from '../../assets/map-icons/business/017-businessman.svg';
import business018 from '../../assets/map-icons/business/018-businesswoman.svg';
import business019 from '../../assets/map-icons/business/019-businesswoman.svg';
import business020 from '../../assets/map-icons/business/020-businesswoman.svg';
import signal from '../../assets/map-icons/signal.svg';
import ThreeLayer from './ThreeLayer';
// const escapeHtml = (unsafe) => unsafe
//   .replace(/&/g, '&amp;')
//   .replace(/</g, '&lt;')
//   .replace(/>/g, '&gt;')
//   .replace(/"/g, '&quot;')
//   .replace(/'/g, '&#039;');
const calculateEntityDiff = (indexed, list) => list.filter((entity) => !(entity.id in indexed));
const calculateOpacity = (entity) => {
    if (entity.type === 'building')
        return 1;
    return 1;
};
const calculateColor = (entity) => {
    if (entity.state.color && entity.state.color.value !== null)
        return entity.state.color.value;
    if (entity.state.occupied && entity.state.occupied.value !== null) {
        return entity.state.occupied.value ? 'red' : 'green';
    }
    if (entity.locatedstate && Object.keys(entity.locatedstate).length > 0) {
        const value = entity.locatedstate?.value?.value ?? entity.locatedstate?.temperature?.value;
        // eslint-disable-next-line no-mixed-operators
        const hue = 30 + (240 * (30 - value)) / 60;
        return `hsl(${hue}, 70%, 50%)`;
    }
    return 'white';
};
const calculateHeight = (entity) => {
    if (entity.state.height)
        return entity.state.height.value;
    return 1;
};
const calculateElevation = (entity) => {
    if (entity.state.elevation)
        return entity.state.elevation.value;
    return 0;
};
const importedIcons = {
    'mdi-car': mdiCar,
    'mdi-car-key': mdiCarKey,
    'mdi-car-electric': mdiCarElectric,
    'mdi-ev-station': mdiEvStation,
    'mdi-flash-circle': mdiFlashCircle,
    'map-marker-question-outline': mdiMapMarkerQuestionOutline,
    'mdi-file-question-outline': mdiFileQuestionOutline,
    'mdi-scooter-electric': mdiScooterElectric,
    armchair,
    circle,
    projector,
    chartBox,
    speaker,
    business001,
    business002,
    business003,
    business004,
    business005,
    business006,
    business007,
    business008,
    business009,
    business010,
    business011,
    business012,
    business013,
    business014,
    business015,
    business016,
    business017,
    business018,
    business019,
    business020,
    signal,
};
const buildRectangleAroundPoint = (point, width) => {
    const meters = width;
    const [lng, lat] = point.coordinates;
    // number of km per degree = ~111km (111.32 in google maps, but range varies
    // between 110.567km at the equator and 111.699km at the poles)
    // 1km in degree = 1 / 111.32km = 0.0089
    // 1m in degree = 0.0089 / 1000 = 0.0000089
    const coef = meters * 0.0000089;
    const rectangleAround = {
        type: 'Polygon',
        coordinates: [
            [
                [lng + coef / Math.cos(lng * 0.018), lat + coef],
                [lng + coef / Math.cos(lng * 0.018), lat - coef],
                [lng - coef / Math.cos(lng * 0.018), lat - coef],
                [lng - coef / Math.cos(lng * 0.018), lat + coef],
                [lng + coef / Math.cos(lng * 0.018), lat + coef],
            ],
        ],
    };
    return rectangleAround;
};
// const entityListDiffGenerator = (indexEntities, entityList) => function* genDiff(i) {
//   yield entityList[i]
// };
export default Vue.extend({
    data() {
        return {
            retrieveInProgress: false,
            threeLayer: new ThreeLayer('one-threebox'),
            map: null,
            updateOnBBoxChange: true,
            showDebugTools: false,
            showHeatmap: false,
            flyToUpdates: false,
            style: 'mapbox/satellite-streets-v9',
            // style: 'mapbox://styles/mapbox/light-v10',
            // style: 'mapbox://styles/mapbox/streets-v11',
            // style: 'janhaa/ckesmcnsa25cd19uimkrndtjb',
            thingsGeoJson: {
                type: 'FeatureCollection',
                features: [],
            },
            entitiesOnMap: {},
            entityListControl: new VueControl(EntityListControl),
            entityByMeshUUID: {},
            treeViewControl: new VueControl(EntityTreeView),
            popup: {
                visible: false,
                center: [0, 0],
                thing: null,
            },
        };
    },
    metaInfo() {
        return {
            title: 'ONE | map',
            // ...
        };
    },
    components: {
        VPopup,
        ThingSummaryPanel,
    },
    provide() {
        return {
            mapbox: mapboxgl,
        };
    },
    watch: {
        style() {
            this.map.setStyle(`mapbox://styles/${this.style}`);
        },
        showHeatmap() {
            this.map.setLayoutProperty('e-heatmap', 'visibility', this.showHeatmap ? 'visible' : 'none');
        },
    },
    computed: {
        center() {
            if (!('center' in this.$route.params)) {
                return new mapboxgl.LngLat(6.084403, 50.775251);
            }
            const [lat, lng] = this.$route.params.center.split(',');
            return new mapboxgl.LngLat(lng, lat);
        },
        zoom() {
            if (!('center' in this.$route.params)) {
                return 13;
            }
            return this.$route.params.center.split(',')[2];
        },
        bearing() {
            if (!('center' in this.$route.params)) {
                return 0;
            }
            return this.$route.params.center.split(',')[3];
        },
        pitch() {
            if (!('center' in this.$route.params)) {
                return 0;
            }
            return this.$route.params.center.split(',')[4];
        },
    },
    sockets: {
        stateDelta(payload) {
            if (this.flyToUpdates && payload.property === 'position') {
                const { latitude, longitude } = payload.to.value;
                this.map.flyTo({ center: [longitude, latitude], zoom: 18 });
            }
            // TODO: temporarily up here to enable live updates
            //       from mapped sonah things
            this.retrieveFromBoundingBox();
            if (!(payload.thingId in this.entitiesOnMap)) {
                return;
            }
            // update raw thing
            const { entity } = this.entitiesOnMap[payload.thingId];
            entity.state[payload.property] = payload.to;
            // update properties on map
            this.syncThingById(payload.thingId);
            // if (payload.property === 'position') { this.retrieveFromBoundingBox(); }
        },
    },
    mounted() {
        this.threeLayer.init();
        // eslint-disable-next-line
        mapboxgl.accessToken =
            'pk.eyJ1IjoiamFuaGFhIiwiYSI6ImNrOGJ2Nms1cTA4NG8zZm5yNnptdXA2bjkifQ.BQrmgNIeFRejNIhMBjDBUw';
        this.map = new mapboxgl.Map({
            container: 'map',
            style: `mapbox://styles/${this.style}`,
            antialias: true,
            light: {
                intensity: 0.5,
                color: 'white',
            },
        });
        window.tooltipHandler = (id) => {
            const selection = this.entitiesOnMap[id];
            if (!selection)
                return;
            // if (selection.entity.type !== 'building')
            // return;
            this.retrieveFromBoundingBox(selection.entity.id).then(() => {
                // // make opaque and unselectable
                selection.mesh.material.opacity = 0.3;
                selection.mesh.material.visible = false;
                console.log('clicked on', selection);
                selection.mesh.selectable = false;
                this.map.unselectObject();
            });
        };
        this.map.doubleClickZoom.disable();
        Object.entries(importedIcons).forEach(([name, icon]) => {
            const img = new Image(100, 100);
            img.onload = () => this.map.addImage(name, img, { sdf: false });
            img.src = icon;
        });
        // set initial values, which might be computed
        // based on URL parameters
        this.map.setZoom(this.zoom);
        this.map.setCenter(this.center);
        this.map.setBearing(this.bearing);
        this.map.setPitch(this.pitch);
        this.map.scrollZoom.setWheelZoomRate(1 / 100);
        const selectionControl = new VueControl(EntitySelection);
        this.map.addControl(selectionControl, 'top-right');
        this.map.addControl(this.treeViewControl, 'top-right');
        // this.map.addControl(this.entityListControl, 'top-right');
        Vue.nextTick(() => {
            // (this.entityListControl.vueInstance as Vue).$on('entrySelected', (entry) => {
            //   console.log('entrySelected', entry);
            //   this.map.selectMesh(entry);
            // // console.log(this.threeLayer.threebox.findParent3DObject);
            // // const obj = this.threeLayer.threebox.findParent3DObject({ object: entry });
            // // obj.selected = true;
            // // this.map.selectedObject = null;
            // // this.map.selectedObject = obj;
            // // this.map.repaint = true;
            // });
            this.treeViewControl.vueInstance.mouseover = async (item) => {
                // console.log('treeView mouseover', item);
                console.log(item.entity.id);
                console.log(this.entitiesOnMap[item.entity.id].object.coordinates);
                // this.entitiesOnMap[item.entity.id].mesh.material.opacity = 0;
                this.entitiesOnMap[item.entity.id].object.over = true;
                this.map.overedObject = this.entitiesOnMap[item.entity.id].object;
                this.map.repaint = true;
                // console.log(this.threeLayer.threebox.unselectFeature());
                // this.entitiesOnMap[item.entity.id].object.drawBoundingBox();
            };
            this.treeViewControl.vueInstance.mouseleave = async (item) => {
                console.log('treeView mouseleave', item);
                this.map.outFeature(this.map.overedFeature);
                this.map.outObject();
            };
            this.treeViewControl.vueInstance.eyeClick = async (item) => {
                console.log('hide', item);
                const { visible, selectable } = this.entitiesOnMap[item.entity.id].mesh;
                this.entitiesOnMap[item.entity.id].mesh.visible = !visible;
                this.entitiesOnMap[item.entity.id].mesh.selectable = !selectable;
            };
            this.treeViewControl.vueInstance.onCollapse = async (item) => {
                if (!item.parent)
                    this.entitiesOnMap[item.entity.id].mesh.visible = true;
                else {
                    item.parent.children.forEach((child) => {
                        this.entitiesOnMap[child.entity.id].mesh.visible = true;
                        this.entitiesOnMap[child.entity.id].mesh.selectable = true;
                    });
                }
                if (item.children) {
                    item.children.forEach((child) => {
                        this.entitiesOnMap[child.entity.id].mesh.visible = false;
                        this.entitiesOnMap[child.entity.id].mesh.selectable = false;
                    });
                }
                this.map.repaint = true;
            };
            this.treeViewControl.vueInstance.populateChild = async (item) => {
                if (this.map.overedFeature) {
                    this.map.outFeature(this.map.overedFeature);
                    this.map.outObject();
                }
                console.log('treeView populateChild', this.entitiesOnMap[item.entity.id]);
                const entities = await api.postResource('functions/entitiesInArea/run', {
                    enclosedIn: item.entity.id,
                });
                if (entities.length === 0)
                    return [];
                if (!item.parent) {
                    // make top-level-item invisible
                    this.entitiesOnMap[item.entity.id].mesh.visible = false;
                    this.entitiesOnMap[item.entity.id].mesh.selectable = false;
                }
                else {
                    // make item and all its siblings invisible
                    item.parent.children.forEach((child) => {
                        this.entitiesOnMap[child.entity.id].mesh.visible = false;
                        this.entitiesOnMap[child.entity.id].mesh.selectable = false;
                    });
                }
                entities.forEach((entity) => {
                    if (entity.id in this.entitiesOnMap) {
                        this.entitiesOnMap[entity.id].mesh.visible = true;
                        this.entitiesOnMap[entity.id].mesh.selectable = true;
                    }
                });
                this.map.repaint = true;
                await this.addEntitiesToMap(entities);
                console.log(this.map);
                this.map.flyTo({
                    center: this.entitiesOnMap[item.entity.id].object.coordinates,
                    zoom: this.map.getZoom() < 20 ? 20 : this.map.getZoom(),
                    // essential: true,
                });
                return entities.map((entity) => {
                    const children = [];
                    entity.relationships.forEach((relationship) => {
                        if (relationship.type === 'enclosedIn' && relationship.second === entity.id) {
                            children.push({
                                label: relationship.first,
                                expanded: false,
                                children: null,
                            });
                        }
                    });
                    return {
                        label: entity.name,
                        parent: item,
                        expanded: false,
                        entity,
                        children,
                    };
                });
            };
            selectionControl.vueInstance.$on('dirty', () => {
                this.map.repaint = true;
            });
            selectionControl.vueInstance.$on('expand', (id) => {
                const selection = this.entitiesOnMap[id];
                if (!selection)
                    return;
                // if (selection.entity.type !== 'building')
                // return;
                this.retrieveFromBoundingBox(selection.entity.id).then(() => {
                    // // make opaque and unselectable
                    selection.mesh.material.opacity = 0.3;
                    selection.mesh.material.visible = false;
                    console.log('clicked on', selection);
                    selection.mesh.selectable = false;
                    this.map.unselectObject();
                });
            });
        });
        this.threeLayer.on('SelectedChange', async ({ now, prev }) => {
            // update selection control
            const selection = this.entityByMeshUUID[now.mesh.uuid];
            selectionControl.vueInstance.selection = selection;
            console.log(selection);
            // this.entityListControl.vueInstance.selectedId = selection.entity.id;
            const children = [];
            selection.entity.relationships.forEach((relationship) => {
                if (relationship.type === 'enclosedIn' && relationship.second === selection.entity.id) {
                    children.push({
                        label: relationship.first,
                        expanded: false,
                        children: null,
                    });
                }
            });
            // this.treeViewControl.vueInstance.entities = [];
            this.treeViewControl.vueInstance.entities = [
                {
                    label: selection.entity.name,
                    expanded: false,
                    parent: null,
                    children,
                    entity: selection.entity,
                },
            ];
            console.log('selection now', now, prev);
            // this.map.flyTo({
            //   center: now.group.coordinates,
            //   zoom: 19,
            //   essential: true,
            // });
            if (prev) {
                // prev.mesh.selectable = true;
                // prev.mesh.material.opacity = 1;
            }
            // this.map.repaint = true;
            // Vue.nextTick(() => this.map.unselectObject());
        });
        this.map.addControl(new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl,
            //  clearBlur: true,
            flyTo: {
                zoom: 14,
                speed: 10000,
            },
            marker: {
                color: 'red',
            },
        }), 'top-left');
        // let numClicks = 0;
        this.map.on('click', (e) => {
            // The event object (e) contains information like the
            // coordinates of the point on the map that was clicked.
            console.log(`A click event has occurred at ${e.lngLat}`);
            // this.map.getSource('some id').setCoordinates()
            // this.map.getSource('ap-plan-source').setCoordinates([
            //   [6.042643445672439, 50.78419497194594],
            //   [6.04380421917827, 50.78419191876631],
            //   [6.04380863811366, 50.783685887609124],
            //   [6.042644272557482, 50.783684039463566],
            // ]);
            // numClicks += 1;
            /* eslint-disable */
            // if (numClicks === 1) this.map.addLayer(new ThreeDModel('/elab.glb', e.lngLat, '3d-model-elab', 0.5));
            // if (numClicks === 2) this.map.addLayer(new ThreeDModel('/fir.glb', e.lngLat, '3d-model-fir', 0.5));
            // if (numClicks === 3) this.map.addLayer(new ThreeDModel('/wind.glb', e.lngLat, '3d-model-wind', 0.5));
            // if (numClicks === 4) this.map.addLayer(new ThreeDModel('/barrier.glb', e.lngLat, '3d-model-barrier', 0.5));
        });
        this.map.addControl(new ActionBarControl({
            actions: [
                {
                    label: 'Datei',
                    key: 'file',
                    children: [{ key: 'new-view', trigger: ['click'], label: 'Neue Ansicht...' }],
                },
            ],
            onAction: (action) => { },
        }), 'top-left');
        // // Add geolocate control to the map.
        // this.map.addControl(
        //   new mapboxgl.GeolocateControl({
        //     positionOptions: {
        //       enableHighAccuracy: true,
        //     },
        //     trackUserLocation: true,
        //   }),
        //   'top-left',
        // );
        const draw = new MapboxDraw({
            displayControlsDefault: false,
            modes: { draw_array: arraymode, draw_rectangle: DrawRectangle, ...MapboxDraw.modes },
        });
        this.map.addControl(new LayerControl(), 'top-left');
        this.map.addControl(draw, 'top-left');
        // Add zoom and rotation controls to the map.
        this.map.addControl(new mapboxgl.NavigationControl(), 'top-left');
        const infrastructureCreation = new InfrastructureCreationControl(draw);
        infrastructureCreation.on('draw.infrastructure.added', async (e) => {
            let schemas = [];
            switch (e.type) {
                case 'parking-array':
                    schemas = ['@builtin/parking-single/v1'];
                    break;
                case 'ev-station':
                    schemas = ['@builtin/ev-station/v1'];
                    break;
                case 'line':
                    schemas = ['@builtin/line/v1'];
                    break;
                case 'chair':
                    schemas = ['_builtin/office/workstation'];
                    break;
                case 'person':
                    schemas = ['_builtin/office/employee@1.0.0'];
                    break;
                case 'wifi-ap':
                    schemas = ['_builtin/network/wifi-ap@1.0.0'];
                    break;
                case 'light':
                    schemas = ['_builtin/lights/lucem-rgb@1.0.0'];
                    break;
                case 'area':
                    schemas = ['_builtin/geometry/area@1.0.0'];
                    e.features[0].geometry.coordinates = e.features[0].geometry.coordinates[0];
                    break;
                case 'table':
                    schemas = ['_builtin/office/furniture/table@1.0.0'];
                    e.features[0].geometry.coordinates = e.features[0].geometry.coordinates[0];
                    break;
                case 'projector':
                    schemas = ['office/projector'];
                    break;
                case 'flipchart':
                    schemas = ['office/flipchart'];
                    break;
                case 'speaker':
                    schemas = ['office/speaker'];
                    break;
                default:
                    console.log('no schema for type');
                    break;
            }
            await api.createThingsFromGeoJSON(e.features, schemas);
            draw.delete(e.features.map((feature) => feature.id));
            this.retrieveFromBoundingBox();
        });
        this.map.addControl(infrastructureCreation, 'top-left');
        let lastUpdate = 0;
        const onMapZoomMove = (force) => {
            const isForce = force || false;
            // update when forced or every 500 ms at max
            if (!isForce && Date.now() - lastUpdate < 1000)
                return;
            // update route
            const center = this.map.getCenter();
            const lat = center.lat.toFixed(6);
            const lng = center.lng.toFixed(6);
            const zoom = this.map.getZoom().toFixed(6);
            const pitch = this.map.getPitch().toFixed(6);
            const bearing = this.map.getBearing().toFixed(6);
            this.$router
                .replace({
                name: 'map-at',
                params: {
                    center: `${lat},${lng},${zoom},${bearing},${pitch}`,
                },
            })
                .catch(() => { });
            // retrieve things
            if (this.updateOnBBoxChange) {
                this.retrieveFromBoundingBox();
            }
            lastUpdate = Date.now();
        };
        this.map.on('moveend', () => onMapZoomMove(true));
        this.map.on('move', () => onMapZoomMove(false));
        this.map.on('zoomend', () => onMapZoomMove(true));
        // The 'building' layer in the mapbox-streets vector source contains building-height
        // data from OpenStreetMap.
        this.map.on('style.load', async () => {
            // // Insert the layer beneath any symbol layer.
            // const { layers } = this.map.getStyle();
            // let labelLayerId;
            // for (let i = 0; i < layers.length; i += 1) {
            //   if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
            //     labelLayerId = layers[i].id;
            //     break;
            //   }
            // }
            // this.map.addLayer(
            //   {
            //     id: '3d-buildings',
            //     source: 'composite',
            //     'source-layer': 'building',
            //     filter: ['==', 'extrude', 'true'],
            //     type: 'fill-extrusion',
            //     minzoom: 20,
            //     paint: {
            //       'fill-extrusion-color': '#aaa',
            //       // use an 'interpolate' expression to add a smooth transition effect to the
            //       // buildings as the user zooms in
            //       'fill-extrusion-height': [
            //         'interpolate',
            //         ['linear'],
            //         ['zoom'],
            //         17.5,
            //         0,
            //         18,
            //         ['*', 1.5, ['get', 'height']],
            //       ],
            //       'fill-extrusion-base': [
            //         'interpolate',
            //         ['linear'],
            //         ['zoom'],
            //         17.3,
            //         0,
            //         20,
            //         ['get', 'min_height'],
            //       ],
            //       'fill-extrusion-opacity': [
            //         'interpolate',
            //         ['linear'],
            //         ['zoom'],
            //         16,
            //         1,
            //         19,
            //         1,
            //       ],
            //     },
            //   },
            //   labelLayerId,
            // );
            let initialTimestamp = null;
            const rotateCamera = (timestamp) => {
                if (initialTimestamp === 0)
                    initialTimestamp = timestamp;
                // clamp the rotation between 0 -360 degrees
                // Divide timestamp by 100 to slow rotation to ~10 degrees / sec
                this.map.rotateTo(((timestamp - initialTimestamp) / 90) % 360, {
                    duration: 0,
                });
                // Request the next frame of the animation .
                requestAnimationFrame(rotateCamera);
            };
            function easing(t) {
                return t * (2 - t);
            }
            // Start the animation.
            // setTimeout(() => {
            //   // this.map.zoomTo(18.5, {
            //   //   duration: 2000,
            //   //   offset: [100, 50]
            //   // });
            //   this.map.easeTo({
            //     zoom: 18.5,
            //     bearing: this.map.getBearing() - 20,
            //     easing: easing,
            //     pitch: 30,
            //     center: [6.043072, 50.784003],
            //     duration: 2000
            //   });
            //   setTimeout(() => {
            //     //requestAnimationFrame(rotateCamera);
            //   }, 2100)
            // }, 3000);
            // this.map.addLayer(new ThreeDModel(`${config.endpoints.static_assets}/3d-models/elab.glb`, [6.042374356019593, 50.78418579838171], '3d-model-elab', 0.5));
            // this.map.addLayer(
            //   new ThreeDModel(
            //     `https://static.1src.tech/3dmodels/citybus/scene.gltf`,
            //     [6.0437864178225595, 50.78369883799536],
            //     '3d-model-fir',
            //     3,
            //   ),
            // );
            // this.map.addLayer(new ThreeDModel(`${config.endpoints.static_assets}/3d-models/wind.glb`, [6.043548808479471, 50.78436917389209], '3d-model-wind', 0.5));
            // this.map.addLayer(new ThreeDModel(`${config.endpoints.static_assets}/3d-models/barrier.glb`, [6.042180129122556, 50.78334884433136], '3d-model-barrier', 0.5));
            this.map.addSource('things', {
                type: 'geojson',
                data: this.thingsGeoJson,
            });
            this.map.addSource('layout-plan-source', {
                type: 'image',
                url: 'https://static.1src.tech/Grundriss_EG.png',
                coordinates: [
                    [6.043071567098394, 50.78418663646468],
                    [6.043716270231471, 50.78418703984693],
                    [6.043716519207294, 50.78369452455368],
                    [6.04307259446594, 50.7836942331042],
                ],
            });
            this.map.addLayer({
                id: 'layout-plan',
                source: 'layout-plan-source',
                type: 'raster',
                paint: { 'raster-opacity': 1 },
            });
            // this.map.addSource('ap-plan-source', {
            //   type: 'image',
            //   url: 'https://static.1src.tech/Network%20Layout-1.png',
            //   coordinates: [
            //     [6.042643445672439, 50.78419497194594],
            //     [6.04380421917827, 50.78419191876631],
            //     [6.04380863811366, 50.783685887609124],
            //     [6.042644272557482, 50.783684039463566],
            //   ],
            // });
            // this.map.addLayer({
            //   id: 'ap-plan',
            //   source: 'ap-plan-source',
            //   type: 'raster',
            //   paint: { 'raster-opacity': 0.3 },
            // });
            // this.map.addLayer({
            //   id: 'things-point',
            //   type: 'circle',
            //   minzoom: 10,
            //   source: 'things',
            //   paint: {
            //   // make circles larger as the user zooms from z12 to z22
            //     'circle-radius': {
            //       base: 1.75,
            //       stops: [[12, 2], [15, 4], [22, 50]],
            //     },
            //     'circle-color': ['get', 'color'],
            //   },
            //   filter: ['==', '$type', 'Point'],
            // });
            // this.map.addLayer({
            //   id: 'e-polygons',
            //   type: 'fill',
            //   minzoom: 10,
            //   source: 'things',
            //   paint: {
            //     'fill-color': [
            //       'interpolate',
            //       ['linear'],
            //       ['zoom'],
            //       14,
            //       'rgba(255, 255, 255, 0)',
            //       16,
            //       ['case', ['has', 'state_color'], ['get', 'state_color'], ['get', 'color']],
            //     ],
            //     'fill-opacity': ['case', ['has', 'state_color'], 0.3, 0.4],
            //     'fill-outline-color': '#aaa',
            //   },
            //   filter: ['==', '$type', 'Polygon'],
            // });
            // this.map.addLayer({
            //   id: 'e-polygons-outline',
            //   type: 'line',
            //   source: 'things',
            //   paint: {
            //     'line-color': '#222',
            //     'line-width': ['interpolate', ['linear'], ['zoom'], 16.5, 0, 17.5, 1],
            //   },
            //   filter: ['==', '$type', 'Polygon'],
            // });
            this.map.addLayer({
                id: 'e-lines',
                type: 'line',
                source: 'things',
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                paint: {
                    'line-color': ['get', 'color'],
                    'line-width': {
                        base: 0.5,
                        stops: [
                            [15, 1],
                            [17, 3],
                            [20, 10],
                        ],
                    },
                },
                filter: ['==', '$type', 'LineString'],
            });
            this.map.addLayer({
                id: 'e-heatmap',
                type: 'heatmap',
                source: 'things',
                paint: {
                    'heatmap-opacity': 0.2,
                    'heatmap-radius': {
                        stops: [
                            [7, 10],
                            [12, 30],
                            [15, 30],
                            [16.8, 20],
                            [17, 1],
                        ],
                    },
                },
                filter: ['==', '$type', 'Point'],
            });
            this.map.setLayoutProperty('e-heatmap', 'visibility', this.showHeatmap ? 'visible' : 'none');
            this.map.addLayer({
                id: 'e-symbols',
                type: 'symbol',
                source: 'things',
                minzoom: 10,
                // fill: {
                //   'fill-antialias': true,
                // },
                paint: {
                    // 'icon-halo-color': 'black',
                    // 'icon-halo-width': 1,
                    // 'icon-halo-blur': 1000,
                    'icon-color': [
                        'case',
                        // scooters
                        ['==', ['get', 'thingtype'], '@builtin/ev-station/v1'],
                        'white',
                        // scooters
                        ['==', ['get', 'thingtype'], 'scooter'],
                        'white',
                        // non-electric cars
                        [
                            'all',
                            ['==', ['get', 'thingtype'], 'car'],
                            ['==', ['get', 'state_remainingRangeElectric'], null],
                        ],
                        'white',
                        // electric-cars
                        ['==', ['get', 'thingtype'], 'car'],
                        'white',
                        // everything else
                        ['get', 'color'],
                    ],
                },
                layout: {
                    'icon-allow-overlap': true,
                    // 'icon-image': [
                    //   'case',
                    //   // parking
                    //   ['==', ['get', 'thingtype'], '@builtin/parking-single/v1'],
                    //   'parking-15',
                    //   // scooters
                    //   ['==', ['get', 'thingtype'], '@builtin/ev-station/v1'],
                    //   'mdi-ev-station',
                    //   // scooters
                    //   ['==', ['get', 'thingtype'], 'scooter'],
                    //   'mdi-scooter-electric',
                    //   // non-electric cars
                    //   [
                    //     'all',
                    //     ['==', ['get', 'thingtype'], 'car'],
                    //     ['==', ['get', 'state_remainingRangeElectric'], null],
                    //   ],
                    //   'mdi-car',
                    //   // electric-cars
                    //   ['==', ['get', 'thingtype'], 'car'],
                    //   'mdi-car-electric',
                    //   ['==', ['get', 'thingtype'], '_builtin/office/furniture/chair@1.0.0'],
                    //   'armchair',
                    //   // everything else
                    //   'mdi-file-question-outline',
                    // ],
                    'icon-rotate': ['get', 'state_heading'],
                    'icon-rotation-alignment': 'map',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        18,
                        [
                            'case',
                            ['==', ['get', 'thingtype'], 'car'],
                            0.3,
                            ['==', ['get', 'thingtype'], '@builtin/ev-station/v1'],
                            0.3,
                            ['==', ['get', 'thingtype'], '_builtin/office/employee@1.0.0'],
                            0.1,
                            ['==', ['get', 'thingtype'], '_builtin/office/workstation'],
                            0.05,
                            ['==', ['get', 'thingtype'], '_builtin/network/wifi-ap@1.0.0'],
                            0.2,
                            0.05,
                        ],
                        20,
                        [
                            'case',
                            ['==', ['get', 'thingtype'], 'car'],
                            0.3,
                            ['==', ['get', 'thingtype'], '@builtin/ev-station/v1'],
                            0.3,
                            ['==', ['get', 'thingtype'], '_builtin/lights/lucem-rgb@1.0.0'],
                            0.1,
                            ['==', ['get', 'thingtype'], '_builtin/office/employee@1.0.0'],
                            0.3,
                            ['==', ['get', 'thingtype'], '_builtin/office/workstation'],
                            0.1,
                            0.2,
                        ],
                        22,
                        ['case', ['==', ['get', 'thingtype'], '_builtin/lights/lucem-rgb@1.0.0'], 0.3, 0.6],
                    ],
                },
                filter: ['==', '$type', 'Point'],
            });
            this.map.setLayoutProperty('e-symbols', 'icon-image', [
                'case',
                ['==', ['get', 'thingtype'], '@builtin/parking-single/v1'],
                'parking-15',
                // scooters
                ['==', ['get', 'thingtype'], '@builtin/ev-station/v1'],
                'mdi-ev-station',
                // scooters
                ['==', ['get', 'thingtype'], 'scooter'],
                'mdi-scooter-electric',
                // non-electric cars
                [
                    'all',
                    ['==', ['get', 'thingtype'], 'car'],
                    ['==', ['get', 'state_remainingRangeElectric'], null],
                ],
                'mdi-car',
                // electric-cars
                ['==', ['get', 'thingtype'], 'car'],
                'mdi-car-electric',
                ['==', ['get', 'thingtype'], '_builtin/office/workstation'],
                'armchair',
                ['==', ['get', 'thingtype'], '_builtin/lights/lucem-rgb@1.0.0'],
                'circle',
                ['==', ['get', 'thingtype'], '_builtin/network/wifi-ap@1.0.0'],
                'signal',
                ['==', ['get', 'thingtype'], 'office/projector'],
                'projector',
                ['==', ['get', 'thingtype'], 'office/flipchart'],
                'chartBox',
                ['==', ['get', 'thingtype'], 'office/speaker'],
                'speaker',
                ['==', ['get', 'thingtype'], '_builtin/office/employee@1.0.0'],
                '',
                // everything else
                'mdi-file-question-outline', // default
            ]);
            const svgPathToImage = ({ path, width, height }) => new Promise((resolve) => {
                const image = new Image(width, height);
                image.addEventListener('load', () => resolve(image));
                image.src = path;
                image.setAttribute('crossOrigin', '');
            });
            const popup = new mapboxgl.Popup({
                closeButton: true,
                closeOnClick: false,
                className: 'popup-thing-details',
                maxWidth: '500px',
            });
            const thingsMouseEnter = () => {
                // Change the cursor style as a UI indicator.
                this.map.getCanvas().style.cursor = 'pointer';
            };
            let lastClickEvent = null;
            const thingsMouseClick = (e) => {
                const { geometry, properties } = e.features[0];
                const sameEntityClicked = this.popup.visible && this.popup.thing === this.entitiesOnMap[properties.thingid].entity;
                if (sameEntityClicked) {
                    lastClickEvent = null;
                    return;
                }
                if (!sameEntityClicked && e.originalEvent.timeStamp === lastClickEvent) {
                    return;
                }
                lastClickEvent = e.originalEvent.timeStamp;
                let coordinates = [];
                if (geometry.type === 'Point') {
                    coordinates = geometry.coordinates.slice();
                }
                else if (geometry.type === 'LineString') {
                    coordinates = e.lngLat;
                }
                else {
                    coordinates = turf.centroid(geometry).geometry.coordinates;
                }
                this.popup.center = coordinates;
                this.popup.thing = this.entitiesOnMap[properties.thingid].entity;
                this.popup.visible = true;
            };
            const thingsMouseLeave = () => {
                this.map.getCanvas().style.cursor = '';
            };
            // register listeners for...
            // ... symbols
            this.map.on('mouseenter', 'e-symbols', thingsMouseEnter);
            // this.map.on('click', 'e-symbols', thingsMouseClick);
            this.map.on('touchend', 'e-symbols', thingsMouseClick);
            this.map.on('mouseleave', 'e-symbols', thingsMouseLeave);
            // ... polygons
            this.map.on('mouseenter', 'e-polygons', thingsMouseEnter);
            // this.map.on('click', 'e-polygons', thingsMouseClick);
            this.map.on('touchend', 'e-polygons', thingsMouseClick);
            this.map.on('mouseleave', 'e-polygons', thingsMouseLeave);
            // ... lines
            this.map.on('mouseenter', 'e-lines', thingsMouseEnter);
            // this.map.on('click', 'e-lines', thingsMouseClick);
            this.map.on('touchend', 'e-lines', thingsMouseClick);
            this.map.on('mouseleave', 'e-lines', thingsMouseLeave);
            // finally load data
            this.retrieveFromBoundingBox();
            this.map.addLayer(this.threeLayer);
        });
        // const models = [{
        //   path: '/elab.glb',
        //   latlng: [6.042373, 50.784187],
        // }, {
        //   path: '/fir.glb',
        //   latlng: [6.043787, 50.783694],
        // },
        // ];
    },
    methods: {
        async entityDeleted() {
            this.popup.visible = false;
            this.retrieveFromBoundingBox();
        },
        async addEntitiesToMap(entities) {
            // this.thingsGeoJson.features = [];
            // this.thingsOnMap = {};
            const newEntities = calculateEntityDiff(this.entitiesOnMap, entities);
            // this.threeLayer.clear();
            newEntities.forEach((entity) => {
                if (entity.state.bounds || entity.state.position) {
                    let geometry;
                    if (entity.state.bounds)
                        geometry = entity.state.bounds.value;
                    else if (entity.state.position) {
                        geometry = buildRectangleAroundPoint(entity.state.position.value, 0.5);
                        // geomebuildRectangleAroundPoint(geometry, 5));
                        // return;
                    }
                    const { mesh: extrusion, object } = this.threeLayer.addExtrusion({
                        opacity: calculateOpacity(entity),
                        id: 'entity:' + entity.id,
                        depthTest: false,
                        tooltip: `<div class="three-tooltip">
            <div class="header">
              <span class="tooltip-title">
                ${entity.name}
              </span>
              <!--<span style="cursor:pointer" onclick='tooltipHandler(${entity.id})'>Öffnen</span>-->
              </div>
            </div>`,
                        geometry,
                        color: calculateColor(entity),
                        height: calculateHeight(entity),
                        elevation: calculateElevation(entity),
                    });
                    const meshedEntity = { entity, mesh: extrusion, object };
                    this.entitiesOnMap[entity.id] = meshedEntity;
                    this.entityByMeshUUID[extrusion.uuid] = meshedEntity;
                }
            });
            // this.treeViewControl.vueInstance.entities = [];
            // this.treeViewControl.vueInstance.entities = this.entitiesOnMap;
            // clear and set to make inner vue instance update
            // this.entityListControl.vueInstance.entities = [];
            // this.entityListControl.vueInstance.entities = this.entitiesOnMap;
            // newEntities.forEach((entity) => {
            //   let geometry = {};
            //   Object.values(entity.state).forEach((propertyValue) => {
            //     if (propertyValue.datatype.startsWith('geography')) {
            //       geometry = propertyValue.value;
            //     }
            //   });
            //   let properties = {};
            //   properties = this.makeProperties(entity);
            //   const feature = {
            //     type: 'Feature',
            //     id: entity.id,
            //     geometry,
            //     properties,
            //   };
            //   this.thingsGeoJson.features.push(feature);
            //   this.thingsOnMap[entity.id] = { thing: entity, feature };
            // });
            // TODO: use CustomLayerInterface so we do not need to set
            //       all data every time the map gets updated.
            // this.map.getSource('things').setData(this.thingsGeoJson);
        },
        navigateToThing(id) {
            const routeData = this.$router.resolve({ name: 'thing', params: { id } });
            window.open(routeData.href, '_blank');
        },
        syncThingById(id) {
            const { entity, feature } = this.entitiesOnMap[id];
            feature.properties = this.makeProperties(entity);
            this.map.getSource('things').setData(this.thingsGeoJson);
        },
        makeProperties(thing) {
            let color = '#bbb';
            if (thing.type === 'scooter') {
                const { value } = thing.state.batteryLevel;
                if (value === '100') {
                    color = '#00ff00';
                    // layer.getElement().style.color = '#00ff00';
                }
                else if (parseInt(value, 10) < 50) {
                    color = 'orange';
                    // layer.getElement().style.color = 'orange';
                }
                else if (parseInt(value, 10) < 25) {
                    color = 'red';
                    // layer.getElement().style.color = 'red';
                }
                else {
                    color = '#009900';
                    // layer.getElement().style.color = '#009900';
                }
            }
            else if (thing.type === '@builtin/parking-single/v1') {
                if (thing.state.occupied.value === true) {
                    color = 'red';
                }
                else {
                    if (thing.state.reserved)
                        color = thing.state.reserved.value ? 'orange' : 'green';
                    else
                        color = 'green';
                }
                if (thing.id <= 12761 || (thing.id >= 12768 && thing.id <= 12771))
                    color = 'grey';
            }
            else if (thing.type === '_builtin/office/furniture/chair@1.0.0') {
                if (thing.state.occupied) {
                    if (thing.state.occupied.value === true) {
                        color = 'red';
                    }
                    else {
                        if (thing.state.reserved)
                            color = thing.state.reserved.value ? 'orange' : 'green';
                        else
                            color = 'green';
                    }
                }
            }
            const stateValues = {};
            Object.entries(thing.state).forEach(([key, entry]) => {
                stateValues[`state_${key}`] = entry.value;
            });
            return {
                color,
                thingid: thing.id,
                thingname: thing.name,
                thingtype: thing.type,
                ...stateValues,
            };
        },
        async retrieveFromBoundingBox(enclosedIn) {
            if (this.retrieveInProgress && !enclosedIn)
                return;
            this.retrieveInProgress = true;
            const bounds = this.map.getBounds();
            const { lng: xmin, lat: ymin } = bounds.getNorthWest();
            const { lng: xmax, lat: ymax } = bounds.getSouthEast();
            const zoom = this.map.getZoom();
            const entities = await api.postResource('functions/entitiesInArea/run', {
                xmin,
                ymin,
                xmax,
                ymax,
                zoom,
                enclosedIn: enclosedIn ?? null,
            });
            this.addEntitiesToMap(entities);
            this.retrieveInProgress = false;
        },
    },
    destroyed() {
        this.map.remove();
    },
});
