//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import D3Network from 'vue-d3-network';

import api from '@/api';

export default {
  components: {
    D3Network,
  },
  metaInfo() {
    return {
      title: 'ONE | relationships',
      // ...
    };
  },
  watch: {
    selectedRelationshipTypes() {
      this.syncGraph();
    },
    excludeEntities() {
      this.syncGraph();
    },
  },
  async mounted() {
    this.relationships = await api.getResource('relationships');
    this.relationshipTypes = new Set(this.relationships.map((rel) => rel.type));
    // this.selectedRelationshipTypes = Array.from(this.relationshipTypes);
    this.syncGraph();
  },
  data() {
    return {
      nodes: [],
      links: [],
      nodeSize: 20,
      canvas: false,
      relationships: [],
      relationshipTypes: [],
      excludeEntities: [],
      selectedRelationshipTypes: [],
    };
  },
  computed: {
    options() {
      return {
        force: 3000,
        strLinks: true,
        size: { w: window.innerWidth, h: window.innerHeight },
        nodeSize: this.nodeSize,
        nodeLabels: true,
        linkLabels: true,
        canvas: this.canvas,
        linkWidth: 3,
      };
    },
  },
  methods: {
    nodeClick(event, node) {
      console.log(event, node);
      if (event.shiftKey) {
        this.nodes.splice(node.index, 1);
        this.links = this.links.filter(
          (link) => link.sid !== node.id && link.tid !== node.id,
        );
      }
      if (event.ctrlKey) {
        const routeData = this.$router.resolve({
          name: 'thing',
          params: { id: node.id },
        });
        window.open(routeData.href, '_blank');
      }
    },
    lcb(link) {
      return link;
    },
    syncGraph() {
      // eslint-disable-next-line max-len
      const filteredRelationships = this.relationships.filter((rel) => this.selectedRelationshipTypes.includes(rel.type));
      const knownNodes = {};
      const nodes = [];
      filteredRelationships.forEach((relationship) => {
        if (!(relationship.first in knownNodes)) {
          knownNodes[relationship.first] = true;
          nodes.push({ id: relationship.first, name: relationship.first_name });
        }
        if (!(relationship.second in knownNodes)) {
          knownNodes[relationship.second] = true;
          nodes.push({
            id: relationship.second,
            name: relationship.second_name,
          });
        }
      });
      this.nodes = nodes;

      this.links = filteredRelationships.map((relationship) => ({
        sid: relationship.first,
        tid: relationship.second,
        name: relationship.type,
      }));
    },
  },
};
