// import VueSocketIOExt from 'vue-socket.io-extended';
// import * as io from 'socket.io-client';
// import Vue from 'vue';

// import store from '@/store';
// import config from '../config';

const init = () => {
  // if (config.offline) return;

  // const socket = io(config.endpoints.websocket);
  // socket.on('device-state-change', (changes) => {
  //   changes.forEach((change) => {
  //     if (change.isInternalStateChange) {
  //       store.commit('devices/setDeviceInternalStateAttributeById', {
  //         deviceId: change.deviceId,
  //         attribute: change.attribute,
  //         value: change.newValue,
  //       });
  //     } else {
  //       store.commit('devices/setDeviceStateAttributeById', {
  //         deviceId: change.deviceId,
  //         attribute: change.attribute,
  //         value: change.newValue,
  //       });
  //     }
  //   });
  // });

  // socket.on('new-device', (device) => {
  //   store.commit('devices/addDevice', device);
  // });

  // socket.on('processData', (state) => {
  //   store.dispatch('remoteProcesses/updateProcessFromEvent', state);
  // });

  // Vue.use(VueSocketIOExt, socket);
};

export default init;
