//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api';

export default {
  data() {
    return {
      executing: false,
      result: null,
      error: null,
      connector_types: ['mqtt-client', 'websocket-server', 'http-server', 'websocket-client'],
    };
  },
  methods: {
    async createConnector() {
      this.executing = true;
      this.result = null;
      this.error = null;
      // console.log(
      //   this.connectorType,
      //   this.connectorLabel,
      //   this.connectorParameters,
      // );
      try {
        this.result = await api.postResource('connectors', {
          type: this.connectorType,
          name: this.connectorLabel,
          parameters: JSON.parse(this.connectorParameters),
        });
        this.executing = false;
        this.$emit('close');
        this.$router.push({
          name: 'function',
          params: { path: this.functionPath },
        });
      } catch (err) {
        this.executing = false;
        this.error = err.message;
      }
    },
  },
};
