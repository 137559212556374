//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueJsonPretty from 'vue-json-pretty';

export default {
  props: ['query'],
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      data: [],
    };
  },
  methods: {
    parseJSON(str) {
      try {
        return JSON.parse(str);
      } catch (err) {
        return str;
      }
    },
  },
  mounted() {
    this.$apollo.addSmartQuery('gqlquery', {
      ...this.query.create(),
      manual: true,
      result({ data }) {
        console.log(data);
        this.data = data[this.query.key];
      },
    });
  },
};
