const createElement = (type, className, parent = undefined, id) => {
    const a = document.createElement(type);
    if (className) {
        a.className = className;
    }
    if (id) {
        a.id = id;
    }
    if (parent !== undefined) {
        parent.append(a);
    }
    return a;
};
const createButton = (className, parent, fn) => {
    const a = document.createElement('button');
    if (className) {
        a.className = className;
    }
    a.type = 'button';
    a.addEventListener('click', fn);
    parent.append(a);
    return a;
};
const createControlButton = (parent, icon, fn) => {
    const button = createButton('mapboxgl-ctrl-add-infrastructure', parent, fn);
    const iconcontainer = createElement('span', 'icon', button);
    createElement('i', `mdi mdi-${icon} mdi-24px`, iconcontainer);
};
export { createElement, createButton, createControlButton };
