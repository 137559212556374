//
//
//
//
//
//

export default {
  props: ['value'],
  methods: {
    valueChanged() {
      this.$emit('input', this.$refs.input.value);
    },
  },
};
