// TODO: move this to a different place

export default [{
  name: 'temperatureResolver',
  wants: {
    intent: 'temperature',
    position: /.*/,
  },

  onData({ resolve, reject }, store, { position }) {
    if (position === 'here') {
      resolve({ value: '25' });
    } else {
      reject();
    }
  },
}, {
  name: 'carPositionResolver',
  wants: {
    property: 'location',
    type: /car/,
    owner: /.*/,
  },
  onData({ reject, resolve }, store, { owner }) {
    let karre = null;
    if (owner === 'denis' || owner === 'dennis') {
      karre = store.state.things.things.find((thing) => thing.name === 'BMW 225xe iPerformance');
    } else if (owner === 'gerhard' || owner === 'gerhards') {
      karre = store.state.things.things.find((thing) => thing.name === 'BMW 520d');
    }
    resolve({ value: karre ? karre.state.position : 'not found' });
    reject();
  },
}, {
  name: 'carPositionResolver',
  wants: {
    property: 'location',
    type: /car/,
    subject: /.*/,
  },
  onData({ reject, resolve }, store, { subject }) {
    let karre = null;
    if (subject === 'denis' || subject === 'dennis') {
      karre = store.state.things.things.find((thing) => thing.name === 'BMW 225xe iPerformance');
    } else if (subject === 'gerhard' || subject === 'gerhards') {
      karre = store.state.things.things.find((thing) => thing.name === 'BMW 520d');
    }
    resolve({ value: karre ? karre.state.position : 'not found' });
    reject();
  },
},
];
