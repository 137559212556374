//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('providers', {
      provider(state) {
        return state.byId[this.$route.params.id];
      },
    }),
  },
};
