// @ts-ignore
import Vue from 'vue';
import { createElement, createControlButton } from '../../domUtil.ts';
import Component from './List.vue';
const EventEmitter = require('events');
export default class LayersControl extends EventEmitter {
    constructor(draw) {
        super();
        // what kind of infrastructure is currently being drawn
        // e.g. 'parking', 'charging', ...
        this.drawType = null;
        this._draw = draw;
        this._container = createElement('div', 'mapboxgl-ctrl');
        // add main buttons
        const mainButtons = createElement('div', 'mapboxgl-ctrl-group ib', this._container);
        createControlButton(mainButtons, 'layers-triple', () => {
            this._submenu.classList.toggle('extended');
        });
        // create submenu
        this._submenu = createElement('div', 'layers-submenu mapboxgl-ctrl-group', this._container);
        createElement('div', 'layers-list', this._submenu, 'layers-list');
    }
    onAdd(map) {
        this._map = map;
        this._map.on('draw.create', (e) => {
            // if it wasn't us who added something, return.
            if (this.drawType === null)
                return;
            this.emit('draw.infrastructure.added', { features: e.features, type: this.drawType });
        });
        this._map.on('draw.modechange', (e) => {
            // only handle simple_select for now, as that
            // indicates that we finished drawing
            if (e.mode !== 'simple_select')
                return;
            this.drawType = null;
        });
        Vue.nextTick(() => {
            const componentInstance = new Vue({
                ...Component,
                propsData: {
                    map,
                }, // stage-2 rest spread operator
            });
            componentInstance.$mount('#layers-list');
        });
        return this._container;
    }
    onRemove() {
        // this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}
