//
//
//
//

import Handlebars from 'handlebars';

import gql from 'graphql-tag';

const getDescendantProp = (obj, desc) => {
  const arr = desc.split('.');
  // eslint-disable-next-line no-param-reassign, no-cond-assign
  while (arr.length && (obj = obj[arr.shift()]));
  return obj;
};

export default {
  props: [
    // queries as object
    'queries',
    // single query exploded
    'queryKey',
    'path',
    'query',
    'subscription',
    // template to render
    'template',
  ],
  data() {
    return {
      interpolatedQuery: '',
      queryResults: {},
    };
  },
  methods: {
    refreshTemplate() {
      // compile and set handlebars template
      // based on provided template and query results.
      const template = Handlebars.compile(this.template);
      this.interpolatedQuery = template(this.queryResults);
    },
    addQuery(queryKey, query, path, subscription) {
      // add respective apollo queries
      this.$apollo.addSmartQuery(queryKey, {
        query() {
          // from provided query
          return gql(query);
        },
        result(result, key) {
          // update result map ..
          this.queryResults[key] = getDescendantProp(result.data, path);
          // .. and refresh the provided template.
          this.refreshTemplate();
        },
        update(data) {
          return getDescendantProp(data, path);
        },
        subscribeToMore: {
          document: gql(subscription),
          // Mutate the previous result
          updateQuery: (previousResult, { subscriptionData }) => subscriptionData.data,
        },
      });
    },
  },
  mounted() {
    // iterate all provided queries
    if (this.queries) {
      Object.entries(this.queries).forEach(([queryKey, query]) => {
        this.addQuery(queryKey, query.query, query.path, query.subscription);
      });
    }
    if (this.queryKey && this.path && this.query && this.subscription) {
      this.addQuery(this.queryKey, this.query, this.path, this.subscription);
    }
  },
};
