//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import ActionBar from '@/components/generic/ActionBar.vue';

export default {
  components: { ActionBar },
  data() {
    return {
      datacollection: null,
      checkedRows: [],
    };
  },
  computed: {
    actions() {
      return [
        {
          type: 'button',
          route: '/providers?mode=create',
          icon: 'plus',
          label: 'Create',
        },
        {
          type: 'button',
          disabled: this.checkedRows.length === 0,
          callback: this.deleteCheckedRows,
          isDanger: true,
          icon: 'delete',
          label: 'Delete',
        },
      ];
    },
    ...mapState('providers', {
      providers: (state) => state.byIndex,
    }),
  },
  methods: {
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);

      this.$buefy.snackbar.open({
        message: 'In die Zwischenablage kopiert.',
        duration: 2000,
        queue: false,
        type: 'is-info',
        position: 'is-top',
        actionText: null,
      });
    },
    deleteCheckedRows() {
      // // ask the user how to continue
      // const answer = window.confirm(
      //   `Are you sure to delete these ${this.checkedRows.length} providers?`
      // );

      // // check his answer and act accordingly
      // if (!answer) return;

      const ids = this.checkedRows.map((row) => row.id);

      this.$store.dispatch('providers/delete', ids).then(() => {
        this.checkedRows = [];
      });
    },
    tableClick(row) {
      this.$router.push({ name: 'provider', params: { id: row.id } });
    },
  },
};
