//
//
//
//
//
//
//
//
//

import ApexChart from '@components/Charts/Apex/Default.vue';
import api from '@/api';

export default {
  components: {
    ApexChart,
  },
  data() {
    return {
      ready: false,
      series: [],
      options: {
        colors: ['#d00000', '#dc2f02', '#e85d04', '#f48c06', '#faa307', '#ffba08'],
        theme: {
          mode: 'light',
          // monochrome: {
          //   enabled: true,
          //   color: '#FAA307',
          //   shadeTo: 'dark',
          //   shadeIntensity: 1,
          // },
        },
        plotOptions: {
        },
        legend: {
          show: true,
          floating: true,
          position: 'top',
        },
        yaxis: {
        //   tickAmount: 10,
          // max: 45,
          min: 0,
          tickAmount: 5,
          labels: {
            style: {
              fontSize: '12px',
            },
            formatter(value) {
              return `${Math.floor(value)}W`;
            },
          },
        },
        tooltip: {
          x: {
            format: 'dd/MM, HH:mm',
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC: false,
          },
        },
        chart: {
          // id: 'gauge',
          // group: 'dashboard-1',
          type: 'area',
        //   stacked: true,
        },
        // title: {
        //   text: 'Leistungsabnahme nach Unternehmen',
        // },
      },
    };
  },
  async mounted() {
    const result = await api.getResource('functions/zumtobel_powerhistory/run');
    // console.log(result);

    // this.series = [{
    //   name: 'Test',
    //   data: result,
    // }];
    this.series = result;
    this.ready = true;
  },
};
