import gql from 'graphql-tag';

export default ({ queryVariables, subscriptionVariables }) => ({
  create() {
    return {
      query() {
        return gql`query MyQuery($appid: Int = -1) {
          process_logs(where: {appid: {_eq: $appid}}, order_by: {time: desc}) {
            content
            time
            type
          }
        }
        `;
      },
      variables: queryVariables,
      subscribeToMore: {
        document: gql`
        subscription MyQuery($appid: Int = 22, $minTime: timestamptz = "") {
          process_logs(where: {appid: {_eq: $appid}, time: {_gte: $minTime}}, order_by: {time: desc}) {
            content
            time
            type
          }
        }

        `,
        variables: subscriptionVariables,
        updateQuery: (previousResult, { subscriptionData }) => {
          if (previousResult) {
            // append to existing array
            previousResult.process_logs.unshift(
              ...subscriptionData.data.process_logs,
            );
            return previousResult;
          }
          return { process_logs: [] };
        },
      },
    };
  },
  key: 'process_logs',
  columns: [
    { label: 'Zeitpunkt', field: 'time', type: 'datetime' },
    { label: 'Typ', field: 'type', type: 'text' },
    { label: 'Inhalt', field: 'content', type: 'text' },
  ],
});
