//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavBar from './NavBar.vue';

// const routes = [
//   { type: 'subheader', label: 'Visualisierungen' },
//   {
//     type: 'link',
//     path: '/dashboards',
//     icon: 'tablet-dashboard',
//     label: 'Dashboards',
//   },
//   {
//     type: 'link',
//     path: '/map',
//     icon: 'map',
//     label: 'Karte',
//   },
//   { type: 'subheader', label: 'Datenmanagement' },
//   {
//     type: 'link',
//     path: '/connectors',
//     icon: 'cable-data',
//     label: 'Konnektoren',
//   },
//   {
//     type: 'link',
//     path: '/things',
//     icon: 'file-tree',
//     label: 'Objekte',
//   },
//   {
//     type: 'link',
//     path: '/relationships',
//     icon: 'graph',
//     label: 'Beziehungen',
//   },
//   { type: 'subheader', label: 'Automatisierung' },
//   {
//     type: 'link',
//     path: '/functions',
//     icon: 'function-variant',
//     label: 'Funktionen',
//   },
//   {
//     type: 'link',
//     path: '/simulations',
//     icon: 'test-tube',
//     label: 'Simulationen',
//   },
//   { type: 'subheader', label: 'Administration' },
//   {
//     type: 'link',
//     path: '/api-access',
//     icon: 'key-change',
//     label: 'API',
//   },
//   {
//     type: 'link',
//     path: '/system-status',
//     icon: 'heart-pulse',
//     label: 'Systemzustand',
//   },
// ];

export default {
  data() {
    return {
      isHidden: true,
      // routes,
      // routes: this.$store.state.user.permissions.routes,
    };
  },
  computed: {
    routes() {
      return this.$store.state.user.permissions.routes;
    },
  },
  components: {
    NavBar,
  },
  methods: {
    toggle() {
      console.log('toggleSidebar');
      this.isHidden = !this.isHidden;
    },
    toggleMobile() {
      const mq = window.matchMedia('(min-width: 601px)');
      if (!mq.matches) {
        console.log('toggleSidebar2');
        this.isHidden = !this.isHidden;
      }
    },
  },
};
