const populateStore = (store) => {
  // set this explicitly to catch cases where
  // we might be repopulating (after successful
  // authentication for example)
  store.commit('bootstrap/setIsInitializing', true);

  // retrieve all necessary from backend
  const initalizations = [
    store.dispatch('user/sync'),
    // store.dispatch('things/sync'),
    // store.dispatch('actions/sync'),
    // store.dispatch('providers/sync'),
    // store.dispatch('apps/sync'),
    // store.dispatch('geographic/sync'),
    // store.dispatch('flows/sync'),
  ];
  // once all ...
  Promise.all(initalizations)
    .then(() => {
      // ... successfully finished:
      store.commit('bootstrap/setIsInitializing', false);
    })
    .catch((err) => {
      // ... at least one failed
      // TODO: store error and display it
      console.log('Error bootstrapping', err);
    });
};

export default populateStore;
