var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outer"},[_c('EditableText',{staticClass:"name title slim",attrs:{"value":_vm.name === '' ? 'Unbetitelte Aktion' : _vm.name},on:{"saved":_vm.nameChanged}}),_c('div',{staticClass:"trigger-action-composer"},[_c('div',{staticClass:"comp-box trigger"},[_c('div',{staticClass:"trigger-descriptive-title"},[_vm._v("Auslöser:")]),(_vm.trigger.type == '')?_c('div',{staticClass:"trigger-select flex-list"},_vm._l((_vm.triggerTypes),function(trigger_type,type){return _c('span',{key:type,on:{"click":function($event){return _vm.selectTrigger(type)}}},[_c('b-icon',{attrs:{"icon":trigger_type.icon}}),_vm._v(" "+_vm._s(trigger_type.label)+" ")],1)}),0):_c('div',{staticClass:"trigger-config"},[_c('div',{staticClass:"trigger-title"},[_c('span',{staticClass:"back",on:{"click":function($event){_vm.trigger.type = '';
              _vm.trigger.arguments = {};}}},[_c('b-icon',{attrs:{"icon":"arrow-left-bold-circle"}},[_vm._v("Back")])],1),_c('span',{},[_vm._v(_vm._s(_vm.triggerTypes[_vm.trigger.type].sublabel))]),_vm._v(": ")]),_c('div',{staticClass:"trigger-parameters"},_vm._l((_vm.triggerTypes[_vm.trigger.type].parameters),function(parameter,name){return _c('div',{key:name},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(parameter.label))]),_c('span',{staticClass:"selector"},[(parameter.type == 'text')?_c('input',{attrs:{"type":"text"}}):_vm._e(),(parameter.type == 'propertyValueSelect')?[(
                    parameter.for in _vm.trigger.arguments &&
                      'type' in _vm.trigger.arguments[parameter.for].property
                  )?[(_vm.trigger.arguments[parameter.for].property.type == 'enum')?_c('ValueEnumSelector',{attrs:{"thing-id":_vm.trigger.arguments[parameter.for].thingId,"property":_vm.trigger.arguments[parameter.for].property},model:{value:(_vm.trigger.arguments[name]),callback:function ($$v) {_vm.$set(_vm.trigger.arguments, name, $$v)},expression:"trigger.arguments[name]"}}):(_vm.trigger.arguments[parameter.for].property.type == 'number')?_c('ValueNumberInput',{model:{value:(_vm.trigger.arguments[name]),callback:function ($$v) {_vm.$set(_vm.trigger.arguments, name, $$v)},expression:"trigger.arguments[name]"}}):_c('ValueTextInput',{model:{value:(_vm.trigger.arguments[name]),callback:function ($$v) {_vm.$set(_vm.trigger.arguments, name, $$v)},expression:"trigger.arguments[name]"}})]:_c('span',[_vm._v("Erwarte Eigenschaft..")])]:_vm._e(),(parameter.type == 'thingAndProperty')?_c('ThingAndPropertySelector',{model:{value:(_vm.trigger.arguments[name]),callback:function ($$v) {_vm.$set(_vm.trigger.arguments, name, $$v)},expression:"trigger.arguments[name]"}}):_vm._e()],2)])}),0)])]),_c('div',{staticClass:"comp-box actions"},[_c('div',{staticClass:"action-descriptive-title"},[_vm._v("Aktion:")]),(_vm.action.type == '')?_c('div',{staticClass:"action-select flex-list"},_vm._l((_vm.actionTypes),function(action_type,type){return _c('span',{key:type,on:{"click":function($event){return _vm.selectAction(type)}}},[_c('b-icon',{attrs:{"icon":action_type.icon}}),_vm._v(" "+_vm._s(action_type.label)+" ")],1)}),0):_c('div',{staticClass:"action-config"},[_c('div',{staticClass:"action-title"},[_c('span',{staticClass:"back",on:{"click":function($event){_vm.action.type = '';
              _vm.action.arguments = {};}}},[_c('b-icon',{attrs:{"icon":"arrow-left-bold-circle"}},[_vm._v("Back")])],1),_c('span',{},[_vm._v(_vm._s(_vm.actionTypes[_vm.action.type].label))]),_vm._v(": ")]),_c('div',{staticClass:"trigger-parameters"},_vm._l((_vm.actionTypes[_vm.action.type].parameters),function(parameter,name){return _c('div',{key:name},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(parameter.label))]),_c('span',{staticClass:"selector"},[(parameter.type == 'text')?_c('input',{attrs:{"type":"text"}}):_vm._e(),(parameter.type == 'thingAndMethod')?_c('ThingAndMethodSelector',{model:{value:(_vm.action.arguments[name]),callback:function ($$v) {_vm.$set(_vm.action.arguments, name, $$v)},expression:"action.arguments[name]"}}):_vm._e(),(parameter.type == 'methodArgumentsSelect')?[(
                    parameter.for in _vm.action.arguments &&
                      'method' in _vm.action.arguments[parameter.for]
                  )?[_c('MethodArgumentsSelector',{attrs:{"method":_vm.action.arguments[parameter.for].method},model:{value:(_vm.action.arguments[name]),callback:function ($$v) {_vm.$set(_vm.action.arguments, name, $$v)},expression:"action.arguments[name]"}})]:_vm._e()]:_vm._e()],2)])}),0)])])]),_vm._v(" "+_vm._s(_vm.trigger)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.action)+" "),_c('br'),_c('button',{on:{"click":_vm.dispatch}},[_vm._v("Dispatch")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }