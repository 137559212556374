//
//
//
//
//
//
//
//
//
//
//
//

import { mergeDeep } from '@/util';

import defaultOptions from './defaultOptions';

export default {
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.mergedOptions = mergeDeep(JSON.parse(JSON.stringify(defaultOptions)), this.options);
  },
  data() {
    return {
      mergedOptions: {},
    };
  },
  methods: {
    zoomed(...ev) {
      console.log('zoomed', ev);
    },
  },
};
