//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Source from './Modals/Source.vue';
import Customization from './Modals/Customization.vue';
// import Location from "./Modals/Location.vue";
import Verification from './Modals/Verification.vue';

export default {
  components: {
    Source,
    Customization,
    // Location,
    Verification,
  },
  methods: {
    sourceCardClick(cardId) {
      this.selectedBlueprintId = cardId;
    },
    sourceSuccess() {
      this.activeStep = 1;
    },
    customizationSuccess() {
      this.activeStep = 2;
    },
    locationSuccess() {
      this.activeStep = 3;
    },
    verificationSuccess() {
      this.$store
        .dispatch('things/pushNewThing', this.newThing)
        .then((thing) => {
          this.$router.push({
            name: 'things',
            query: { highlight: thing.id },
          });
        })
        .catch(console.log);
    },
  },
  data() {
    return {
      selectedBlueprintId: -1,
      newThing: { name: 'Test', attributes: {} },
      activeStep: 0,
      blueprints: {
        1: {
          id: '1',
          name: 'Blank',
          icon: 'shape-outline',
          description: 'A white canvas ready to be filled...',
        },
        2: {
          id: '2',
          name: 'Building',
          icon: 'office-building',
          description: 'Construct a beatiful building with rooms, offices, a coffee machine ...',
          attributes: {
            owner: { type: 'string' },
            height: { type: 'number' },
            numRooms: { type: 'number' },
          },
        },
        3: {
          id: '3',
          name: 'Parking space',
          icon: 'parking',
          description: 'Parking space blueprint with fields for owner, status, reserverations, ...',
          attributes: {
            occupied: { type: 'boolean' },
            reserved: { type: 'boolean' },
          },
        },
        4: {
          id: '4',
          name: 'EV Charger',
          icon: 'battery-charging-wireless-70',
          description: 'Electric vehical charging station',
          attributes: {
            uid: { type: 'number' },
            maxPower: { type: 'number' },
            authenticatedAs: { type: 'string' },
            state: { type: 'string' },
          },
        },
        5: {
          id: '5',
          name: 'Thermometer',
          icon: 'thermometer',
          description: 'Therometer with current temperature, ..',
          attributes: {
            temperature: { type: 'number' },
            tooHot: { type: 'boolean' },
            tooCold: { type: 'boolean' },
          },
        },
        6: {
          id: '6',
          name: 'Car',
          icon: 'car',
          description: 'Nightrider',
          attributes: {
            position: { type: 'location' },
            numDoors: { type: 'number' },
            numSeats: { type: 'number' },
          },
        },
        7: {
          id: '7',
          name: 'Bus',
          icon: 'bus',
          description: '',
          attributes: {
            position: { type: 'location' },
            numDoors: { type: 'number' },
            numSeats: { type: 'number' },
            line: { type: 'number' },
          },
        },
        8: {
          id: '8',
          name: 'Light',
          icon: 'lightbulb',
          description: '',
          attributes: {
            onOff: { type: 'boolean' },
          },
        },
      },
    };
  },
};
