import api from '@/api';
import { arrayToObject } from '../../util';

// initial state
const persistedState = {
  blueprints: [],
  blueprintsByName: {},
  blueprintById: {},
  byIndex: [],
  byId: {},
  byName: {},
  error: null,
};

// getters
const getters = {};

// actions
const actions = {
  sync({ commit }) {
    return Promise.all([
      api.fetchProviderBlueprints().then((blueprints) => {
        commit('setProviderBlueprints', blueprints);
      }),
      api
        .fetchProviders()
        .then((providers) => commit('setProviders', providers))
        .catch((error) => commit('setError', error)),
    ]);
  },
  create({ commit, dispatch }, { name, options }) {
    return api
      .createProvider({ name, options })
      .then((providers) => {
        dispatch('sync');
        dispatch('things/sync', null, { root: true });
        return providers;
      })
      .catch((error) => commit('setError', error));
  },
  delete({ dispatch }, ids) {
    return api.deleteProviders(ids).then(() => {
      dispatch('sync');
    });
  },
};

// mutations
const mutations = {
  setProviderBlueprints(state, blueprints) {
    state.blueprints = blueprints;
    state.blueprintsByName = arrayToObject(blueprints, 'name');
    state.blueprintById = arrayToObject(blueprints, 'id');
  },
  setProviders(state, providers) {
    state.byIndex = providers;
    state.byId = arrayToObject(state.byIndex, 'id');
    state.byName = arrayToObject(state.byIndex, 'name');
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state: persistedState,
  getters,
  actions,
  mutations,
};
