import api from '@/api';

// initial state
const persistedState = {
  permissions: {},
};

// getters
const getters = {};

// actions
const actions = {
  async sync({ commit }) {
    const result = await api.getResource('functions/user_getPermissions/run');
    console.log(result);
    commit('setUserInformation', result);
  },
};

// mutations
const mutations = {
  setUserInformation(state, permissions) {
    state.permissions = permissions;
    // state.displayName = displayName;
    // state.givenName = givenName;
    // state.surname = surname;
  },
};

export default {
  namespaced: true,
  state: persistedState,
  getters,
  actions,
  mutations,
};
