//
//
//
//
//

import FunctionsList from './FunctionsList.vue';
import AppCreation from './Creation/AppCreation.vue';

export default {
  components: {
    FunctionsList,
    AppCreation,
  },
  data() {
    return {
      defaultRoute: 'list',
    };
  },
  computed: {
    mode() {
      const { mode } = this.$route.query;
      if (typeof mode === 'undefined' || mode.length === 0) return 'list';
      return mode; // TODO: check if route is known
    },
  },
};
