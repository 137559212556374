//
//
//
//
//
//

import axios from 'axios';

export default {
  data() {
    return {
      status: null,
    };
  },
  mounted() {
    axios.get('http://localhost:9080/api/system-status')
      .then((response) => {
        this.status = response.data;
      })
      .catch((error) => {
        this.status = error.message;
      });
  },
};
