// initial state
const persistedState = { now: new Date(), timingStarted: false };

// getters
const getters = {};

// actions
const actions = {
  startTiming(state, { commit }) {
    if (state.timingStarted) return;
    setInterval(() => {
      commit('updateTime');
    }, 100);
  },
};

// mutations
const mutations = {
  updateTime(state) {
    state.now = new Date();
  },
};

export default {
  namespaced: true,
  state: persistedState,
  getters,
  actions,
  mutations,
};
