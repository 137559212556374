import api from '@/api';
import { arrayToObject } from '../../util';

// initial state
const persistedState = {
  byIndex: [],
};

// getters
const getters = {};

// actions
const actions = {
  sync({ commit }) {
    return api
      .fetchFlows()
      .then((flows) => {
        commit('setFlows', flows);
      })
      .catch((error) => console.log(error));
  },
  remove({ dispatch }, ids) {
    return new Promise((resolve, reject) => api
      .deleteFlows(ids)
      .then(() => {
        dispatch('sync');
        resolve();
      })
      .catch((error) => reject(error)));
  },
};

// mutations
const mutations = {
  setFlows(state, flows) {
    state.byIndex = flows;
    state.byId = arrayToObject(state.byIndex, 'id');
  },
};

export default {
  namespaced: true,
  state: persistedState,
  getters,
  actions,
  mutations,
};
