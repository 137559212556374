//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import KeyValueDropdown from '@/components/generic/KeyValueDropdown.vue';

export default {
  data() {
    return {
      streams: {},
      types: ['any', 'number', 'location', 'string', 'boolean', 'json'],
    };
  },
  components: {
    KeyValueDropdown,
  },
  methods: {
    updateStream({ key, previousKey, value }) {
      delete this.streams[previousKey];
      this.streams[key] = value;
    },
    addStream() {
      const initialName = 'new-stream';
      let index = 1;
      while (initialName + index in this.streams) {
        index += 1;
      }
      this.$set(this.streams, initialName + index, 'any');
    },
    deleteStream(name) {
      delete this.streams[name];
      console.log(this.streams);
    },
  },
};
