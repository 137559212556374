//
//
//
//
//
//
//

import { FunctionLogHistory } from '../../queries';
import LiveTable from './LiveTable.vue';

export default {
  props: ['path'],
  components: {
    LiveTable,
  },
  data() {
    return {
      propertyFilter: [],
    };
  },
  computed: {
    query() {
      const context = this;
      return FunctionLogHistory({
        queryVariables() {
          return {
            path: context.path,
          };
        },
        subscriptionVariables() {
          return {
            path: context.path,
            minTime: new Date().toISOString(),
          };
        },
      });
    },
  },
};
