//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dObject from './dObject.vue';

export default {
  created() {
    window.addEventListener('keydown', this.onKey);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKey);
  },
  components: { dObject },
  computed: {
    configCircle() {
      return {
        x: this.mouseX,
        y: this.mouseY,
        radius: 3,
        fill: 'lightgrey',
        stroke: '#666',
        strokeWidth: 2,
      };
    },
    previewLine() {
      return {
        config: {
          points: [this.drawingLine.startX, this.drawingLine.startY, this.mouseX, this.mouseY],
          stroke: 'lightgrey',
        },
      };
    },
    cursor() {
      switch (this.tool) {
        case 'pointer':
          return '';
        case 'move':
          return 'move';
        case 'polygon':
          return 'none';
        default:
          return '';
      }
    },
  },
  data() {
    return {
      mouseX: 0,
      mouseY: 0,
      mouseIn: false,
      configKonva: {
        width: 800,
        height: 600,
      },
      objects: [],
      lines: [],
      heads: [],
      drawingLine: {
        drawing: false,
        startX: 0,
        startY: 0,
      },
      tool: 'polygon',
    };
  },
  methods: {
    mouseMove(event) {
      this.mouseX = event.evt.layerX;
      this.mouseY = event.evt.layerY;
      this.$store.commit('drawer/setMouseXY', {
        mouseX: event.evt.layerX,
        mouseY: event.evt.layerY,
      });
    },
    mouseLeave() {
      this.mouseIn = false;
    },
    handlePolygonClick(event) {
      if (!this.drawingLine.drawing) {
        this.drawingLine.startX = event.evt.layerX;
        this.drawingLine.startY = event.evt.layerY;
        this.drawingLine.drawing = true;
      } else {
        const newLine = {
          type: 'polygon',
          config: {
            points: this.previewLine.config.points,
            stroke: 'black',
            strokeWidth: 5,
          },
        };
        this.objects.push(newLine);
        this.$emit('polygon-added', newLine);
        this.drawingLine.drawing = false;
        this.selectTool('pointer');
      }
    },
    mouseClick(event) {
      if (this.tool === 'polygon') {
        this.handlePolygonClick(event);
      }
      this.$store.commit('drawer/mouseClicked');
    },
    draw() {
      this.$refs.objectLayer.getNode().batchDraw();
    },
    selectTool(tool) {
      switch (tool) {
        case 'pointer': {
          this.tool = 'pointer';
          break;
        }
        case 'move': {
          this.tool = 'move';
          break;
        }
        case 'polygon': {
          this.tool = 'polygon';
          break;
        }
        default:
          break;
      }
    },
    addObject(type, config) {
      this.objects.push({ type, config });
    },
    clearAll() {
      this.objects = [];
    },
    cancelAction() {
      this.drawingLine.drawing = false;
    },
    onKey(key) {
      if (key.code === 'Escape') this.cancelAction();
    },
  },
};
