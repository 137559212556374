//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import MonacoEditor from 'vue-monaco';
import axios from 'axios';
import debounce from 'debounce';
import { Splitpanes, Pane } from 'splitpanes';
// import CloudSyncButton from '@vuesence/cloud-sync-button';
import api from '@/api';
// import LogTable from '../generic/LogTable.vue';
// import LiveTable from '@components/generic/LiveTable.vue';
import ActionBar from '@/components/generic/ActionBar.vue';
import RunFunctionForm from '@/components/generic/RunFunctionForm.vue';
import FunctionLogHistoryTable from '@/components/generic/FunctionLogHistoryTable.vue';
import TestProgress from '@/components/generic/TestProgress.vue';

// eslint-disable-next-line
import * as monaco from 'monaco-editor';
import { ProcessLogHistory } from '../../queries';

import 'splitpanes/dist/splitpanes.css';

// import for button animation

const typescriptTypings = [
  'https://raw.githubusercontent.com/1source-ac/typescript-platform-api/main/index.d.ts',
  'https://raw.githubusercontent.com/DefinitelyTyped/tsd/master/typings/node/node.d.ts',
  'https://raw.githubusercontent.com/axios/axios/master/index.d.ts',
];

export default {

  // publishhButton: document.querySelector('publishh'),
  // modalBg: document.querySelector('.modal-background'),
  // modal: document.querySelector('.modal'),

  // function: publishhButton.addEventListener('click', () => {
  //   modal.classList.add('is-active');
  // }),
  // function: modalBg.addEventListener('click', () => {
  //   modal.classList.remove('is-active');
  // }),

  name: 'RegularCards',
  components: {
    // MonacoEditor,
    ActionBar,
    FunctionLogHistoryTable,
    Splitpanes,
    Pane,
    // CloudSyncButton,
    // RunFunctionForm,
    // LogTable,
    // LiveTable,
    TestProgress,
  },
  async mounted() {
    await this.addTypescriptDefinitions();
    await this.initEditor();
    window.onresize = () => this.onResize();
  },
  metaInfo() {
    return {
      title: `${this.$route.params.path}`,
      // ...
    };
  },
  computed: {
    actionBarItems() {
      return [
        {
          type: 'button',
          label: 'Ausführen',
          icon: 'play',
          disabled: false,
          callback: this.runFunction,
        },
        {
          type: 'button',
          label: 'Entfernen',
          icon: 'delete',
          disabled: false,
          callback: this.deleteFunction,
          isDanger: true,
        },
        {
          type: 'button',
          label: 'Veröffentlichen',
          icon: 'cloud-upload',
          disabled: this.func.revision === this.func.publishedRevision,
          callback: this.publishFunction, // hier die Card öffnen
        },
      ];
    },
    query() {
      const context = this;
      return ProcessLogHistory({
        queryVariables() {
          return {
            appid: context.$route.params.id,
          };
        },
        subscriptionVariables() {
          return {
            appid: context.$route.params.id,
            minTime: new Date().toISOString(),
          };
        },
      });
    },
  },
  watch: {
    code() {
      this.inSync = false;
    },
  },
  data() {
    return {
      code: null,
      inSync: true,
      syncing: false,
      app: null,
      logs: [],
      func: null,
      editor: {},
      syncProgress_Cloud: 0,
      inSync_Cloud: false,
      tests: [{ name: 'Test1' }, { name: 'Test2' }, { name: 'Test3' }],
      items: [
      ],
      newId: '',
      newInput: '',
      newOutput: '',
      publishhButton: document.querySelector('publishh'),
      modalBg: document.querySelector('.modal-background'),
      modal: document.querySelector('.modal'),

      isComponentModalActive: false,
      email: 'evan@you.com',
      password: 'testing',
      isActive: true,

      expandOnHover: false,
      expandWithDelay: false,
      mobile: 'reduce',
      reduce: false,

      unitTestToggle: false,
      revisionToggle: false,
    };
  },
  methods: {

    showUnitTestUI() {
      this.unitTestToggle = false;
      this.revisionToggle = true;
    },
    showRevisionsResults() {
      this.unitTestToggle = true;
      this.revisionToggle = false;
    },
    // ////////////////////////
    // publishhButton.addEventListener('click', () => {
    //   modal.classList.add('is-active');
    // }),
    // modalBg.addEventListener('click', () => {
    //   modal.classList.remove('is-active');
    // }),
    // ///////////////////////
    addNewItem() {
      // get all items in list and put their indexes in a list then see if 'index' is in that list
      if (this.newId === '' || this.newInput === '' || this.newOutput === '') return;
      this.items.push({
        id: this.newId,
        input: this.newInput,
        output: this.newOutput,
      });
      this.newId = '';
      this.newInput = '';
      this.newOutput = '';
    },
    removeItem(index) {
      this.$delete(this.items, index);
    },
    editItem(index) {
      this.newId = this.items[index].id;
      this.newInput = this.items[index].input;
      this.newOutput = this.items[index].output;
    },
    saveItem(index) {
      // if(index in list.items.item.index)
      this.items[index].id = this.newId;
      this.items[index].input = this.newInput;
      this.items[index].output = this.newOutput;
    },

    onResize() {
      debounce(() => this.editor.layout(), 300)();
    },
    async syncFunction() {
      this.func = await api.getFunction(this.$route.params.path);
    },
    async initEditor() {
      await this.syncFunction(this.$route.params.path);
      this.$nextTick(async () => {
        this.inSync = true;
        this.editor = monaco.editor.create(document.getElementById('monaco-container'), {
          value: this.func.codecontent,
          language: this.func.codelanguage,
          glyphMargin: false,
          lineNumbers: 'on',
          fontSize: '16px',
          lineDecorationsWidth: 10,
          // lineNumbersMinChars: 3,
          padding: {
            top: '10px',
            bottom: 10,
          },
        });
        monaco.editor.setTheme('vs-dark');
        // this.editor.getModel().onDidChangeContent(() => {
        //   this.inSync = false;
        //   debounce(() => this.save(), 5000)();
        // });
        this.editor.onMouseDown(async (e) => {
          if (!e.event.ctrlKey) return;
          const lineContent = this.editor.getModel().getLineContent(e.target.position.lineNumber);

          const after = lineContent.indexOf("'", e.target.position.column);
          console.log(lineContent[after] + 1);
          let before = null;
          for (let i = e.target.position.column; i >= 0; i -= 1) {
            if (lineContent[i] === "'") {
              before = i;
              break;
            }
          }
          if (before === null) return;
          if (lineContent.substr(before - 5, 4) === 'func') {
            const functionName = lineContent.substr(before + 1, after - before - 1);
            // this.func = this.initEditor(functionName);
            window.open(`/functions/${functionName}`, '_blank');
          }
        });
      });
    },
    addTypescriptDefinitions() {
      if (monaco.languages.typescript.typescriptDefaults.getExtraLibs().length > 0) {
        return Promise.resolve();
      }

      return Promise.all(
        typescriptTypings.map(
          (path) =>
            // eslint-disable-next-line
            axios.get(path).then(({ data }) => {
              monaco.languages.typescript.typescriptDefaults.addExtraLib(data);
            }),
          // eslint-disable-next-line
        ),
      );
    },
    async save() {
      const result = await api.setFunctionContent(this.$route.params.path, this.editor.getValue());
      if (result.revision === null) {
        this.$buefy.snackbar.open({
          message: 'Keine Änderungen vorgenommen',
          type: 'is-warning',
          queue: false,
          duration: 3000,
          position: 'is-top',
        });
      } else {
        this.syncing = true;
        await this.syncFunction();
        this.syncing = false;
        this.inSync = true;
      }
    },
    editorWillMount() {
      // console.log(monaco);
    },
    async actionClicked(index) {
      switch (index) {
        case 0:
          await this.save();
          this.$buefy.modal.open({
            parent: this,
            props: {
              functionPath: this.$route.params.path,
            },
            component: RunFunctionForm,
            hasModalCard: true,
            trapFocus: true,
          });
          break;
        case 1:
          this.deleteFunction();
          break;
        case 2:
          await this.publishFunction();
          await this.syncFunction();
          break;
        default:
          break;
      }
    },
    async publishFunction() {
      const publishResult = await api.getResource(`functions/${this.$route.params.path}/publish`);
      console.log(publishResult);
      if (publishResult && 'error' in publishResult) {
        this.$buefy.snackbar.open({
          message: 'Did not work',
          type: 'is-success',
          queue: false,
          location: 'is-top',
          duration: 3000,
        });
      } else {
        this.$buefy.snackbar.open({
          message: 'Funktion wurde veröffentlicht',
          type: 'is-success',
          queue: false,
          location: 'is-top',
          duration: 3000,
        });
      }
    },
    async deleteFunction() {
      await api.deleteResource(`functions/${this.$route.params.path}`);
      this.$router.replace({
        name: 'functions',
      });
    },
    onInput: debounce(async function onInput(event) {
      await api.setFunctionContent(this.$route.params.path, event.target.textContent);
      // await this.$store.dispatch('apps/patch', {
      //   id: this.$route.params.id,
      //   patch: {
      //     name: event.target.textContent,
      //   },
      // });
    }, 500),

    startSync_Cloud() {
      this.syncProgress = 0;
      this.inSync = true;
    },
    stopSync() {
      this.syncProgress = 0;
      this.inSync = false;
    },
    completeSync() {
      this.syncProgress = 100;
      setTimeout(() => {
        this.stopSync();
      }, 2000);
    },

    name: 'card',
    props: {
      title: {
        type: String,
        description: 'Input',
      },
      subTitle: {
        type: String,
        description: 'Card subtitle',
      },
      type: {
        type: String,
        description: 'Card type (e.g primary/danger etc)',
      },
      headerClasses: {
        type: [String, Object, Array],
        description: 'Cardheaderssclasses',
      },
      bodyClasses: {
        type: [String, Object, Array],
        description: 'Card body css classes',
      },
      footerClasses: {
        type: [String, Object, Array],
        description: 'Card footer css classes',
      },
    },
  },
};
