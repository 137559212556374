//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import api from '@/api';
import Source from './Modals/Source.vue';
import Options from './Modals/Options.vue';
import Streams from './Modals/Streams.vue';
import Processing from './Modals/Processing.vue';

export default {
  components: {
    Source,
    Options,
    Streams,
    Processing,
  },
  data() {
    return {
      selectedBlueprintId: -1,
      activeStep: 'blueprints',
      // extraStepsComponents: [],
      options: {},
      streams: {},
      processid: null,
      blueprints: [],
      // blueprints: {
      //   1: {
      //     id: "1",
      //     name: "bmw-connected-drive",
      //     label: "BMW CD",
      //     icon: "car",
      //     options: [
      //       {
      //         name: "email",
      //         type: "email"
      //       },
      //       { name: "password", type: "password" },
      //       { name: "addAllVehicles", type: "checkbox" }
      //     ],
      //     extraSteps: [
      //       {
      //         vendor: "bmw-connected-drive",
      //         name: "Setup"
      //       }
      //     ]
      //   },
      //   2: {
      //     id: "2",
      //     name: "generic",
      //     label: "Generic",
      //     icon: "",
      //     options: [{ name: "name", type: "text" }],
      //     canCustomizeStreams: true
      //   },
      //   3: {
      //     id: "3",
      //     name: "disruptive-technologies",
      //     label: "Disruptive",
      //     icon: "square-outline",
      //     options: [
      //       { label: "Service Account Email", name: "email", type: "email" },
      //       { label: "Key ID", name: "key", type: "text" },
      //       {
      //         label: "Secret",
      //         name: "secret",
      //         hide: true
      //       },
      //       { label: "Project ID", name: "projectId", type: "text" }
      //     ]
      //   }
      // }
    };
  },
  mounted() {
    // api.fetchProviderBlueprints().then((blueprints) => {
    //   this.blueprints = blueprints;
    // });
  },
  methods: {
    advanceStep() {
      if (this.activeStep === this.numberOfRegularSteps + this.extraStepsComponents.length - 1) {
        return;
      }
      this.activeStep += 1;
    },
    sourceCardClick(cardId) {
      if (this.selectedBlueprintId !== cardId) this.selectedBlueprintId = cardId;
      else this.selectedBlueprintId = -1;
    },
    createProvider(name, options) {
      this.$store
        .dispatch('providers/create', {
          name,
          options,
        })
        .then((result) => {
          this.processid = result.processid;
          this.$store.dispatch('remoteProcesses/init', this.processid);
          this.activeStep = 'processing';
        });
    },
    sourceSuccess() {
      this.activeStep = 'options';
      this.importExtraStepsComponents().then((components) => {
        this.extraStepsComponents = components;
        return undefined;
      });
    },
    processingSuccess() {
      Promise.all([
        this.$store.dispatch('providers/sync'),
        this.$store.dispatch('things/sync'),
      ]).then(() => this.$router.push({ name: 'providers' }));
    },
    optionsSuccess(values) {
      this.options = values;
      if (
        'canCustomizeStreams' in this.blueprints[this.selectedBlueprintId]
        && this.blueprints[this.selectedBlueprintId].canCustomizeStreams
      ) this.activeStep = 'streams';
      else if (this.extraStepsComponents.length > 0) this.activeStep = 'extras';

      this.createProvider(this.blueprints[this.selectedBlueprintId].name, this.options);
    },
    importExtraStepsComponents() {
      if (!('extraSteps' in this.blueprints[this.selectedBlueprintId])) return Promise.resolve([]);

      const { extraSteps } = this.blueprints[this.selectedBlueprintId];
      const importPromises = [];

      extraSteps.forEach((extraStep) => {
        importPromises.push(
          import(`../ProviderSpecifics/${extraStep.vendor}/${extraStep.name}.vue`),
        );
      });

      return Promise.all(importPromises)
        .catch((error) => console.error(error))
        .then((imports) => imports.map((imported) => imported.default));
    },
  },
};
