// @ts-ignore
import { createElement, createControlButton } from '../../domUtil.ts';
const EventEmitter = require('events');
export default class InfrastructureCreationControl extends EventEmitter {
    constructor(draw) {
        super();
        // what kind of infrastructure is currently being drawn
        // e.g. 'parking', 'charging', ...
        this.drawType = null;
        this.drawMode = null;
        this._draw = draw;
        this._container = createElement('div', 'mapboxgl-ctrl');
        // add main buttons
        const mainButtons = createElement('div', 'mapboxgl-ctrl-group ib', this._container);
        createControlButton(mainButtons, 'shape-plus', () => {
            this._submenu.classList.toggle('extended');
        });
        // create submenu
        this._submenu = createElement('div', 'submenu mapboxgl-ctrl-group', this._container);
        createControlButton(this._submenu, 'cancel', () => {
            this.drawType = null;
            this.drawMode = null;
            this._draw.changeMode(null);
        });
        createControlButton(this._submenu, 'table-furniture', () => {
            this.drawType = 'table';
            this.drawMode = 'draw_rectangle';
            this._draw.changeMode('draw_rectangle');
        });
        createControlButton(this._submenu, 'chair-rolling', () => {
            this.drawType = 'chair';
            this.drawMode = 'draw_point';
            this._draw.changeMode('draw_point');
        });
        createControlButton(this._submenu, 'wifi', () => {
            this.drawType = 'wifi-ap';
            this.drawMode = 'draw_point';
            this._draw.changeMode('draw_point');
        });
        createControlButton(this._submenu, 'led-variant-on', () => {
            this.drawType = 'light';
            this.drawMode = 'draw_point';
            this._draw.changeMode('draw_point');
        });
        createControlButton(this._submenu, 'human', () => {
            this.drawType = 'person';
            this.drawMode = 'draw_point';
            this._draw.changeMode('draw_point');
        });
        createControlButton(this._submenu, 'vector-polyline-plus', () => {
            this.drawType = 'area';
            this.drawMode = 'draw_polygon';
            this._draw.changeMode('draw_polygon');
        });
        createControlButton(this._submenu, 'parking', () => {
            this.drawType = 'parking-array';
            this.drawMode = 'draw_array';
            this._draw.changeMode('draw_array');
        });
        createControlButton(this._submenu, 'ev-station', () => {
            this.drawType = 'ev-station';
            this.drawMode = 'draw_point';
            this._draw.changeMode('draw_point');
        });
        createControlButton(this._submenu, 'projector', () => {
            this.drawType = 'projector';
            this.drawMode = 'draw_point';
            this._draw.changeMode('draw_point');
        });
        createControlButton(this._submenu, 'message-draw', () => {
            this.drawType = 'flipchart';
            this.drawMode = 'draw_point';
            this._draw.changeMode('draw_point');
        });
        createControlButton(this._submenu, 'speaker', () => {
            this.drawType = 'speaker';
            this.drawMode = 'draw_point';
            this._draw.changeMode('draw_point');
        });
    }
    onAdd(map) {
        this._map = map;
        this._map.on('draw.create', (e) => {
            // if it wasn't us who added something, return.
            if (this.drawType === null)
                return;
            this.emit('draw.infrastructure.added', { features: e.features, type: this.drawType });
            // setTimeout(() => this._draw.changeMode(this.drawMode);
        });
        this._map.on('draw.modechange', (e) => {
            // only handle simple_select for now, as that
            // indicates that we finished drawing
            if (e.mode !== 'simple_select')
                return;
            // this.drawType = null;
            setTimeout(() => this._draw.changeMode(this.drawMode), 0);
        });
        return this._container;
    }
    onRemove() {
        // this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}
