//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['items', 'populateChild', 'onCollapse', 'mouseover', 'mouseleave', 'eyeClick'],
  components: {
    // eslint-disable-next-line import/no-self-import
    'tree-item': () => import('./TreeItem.vue'),
  },
  methods: {
    async toggleExpanded(item) {
      if (!item.expanded) {
        item.children = await this.populateChild(item);
      }
      item.expanded = !item.expanded;
      if (!item.expanded) { this.onCollapse(item); }
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
};
