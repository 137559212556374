//
//
//
//
//
//
//

import dLine from './dLine.vue';
import dText from './dText.vue';

export default {
  props: ['type', 'config'],
  components: { dLine, dText },
  computed: {},
};
