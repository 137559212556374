import Action from './components/Actions/Action.vue';
import Actions from './components/Actions/Actions.vue';
import App from './components/Apps/App.vue';
import Apps from './components/Apps/Apps.vue';
import Connectors from './components/Connectors/Connectors.vue';
import Connector from './components/Connectors/Connector.vue';
import Dashboards from './components/Dashboards/Dashboards.vue';
import Functions from './components/Functions/Functions.vue';
import FunctionEditor from './components/Functions/FunctionEditor.vue';
// import Flows from "./components/Flow/Flows.vue";
// import Flow from "./components/Flow/Flow.vue";
// import GeoMap from './components/GeoMap/GeoMap.vue';
import Mapbox from './components/GeoMap/Mapbox.vue';
import Logs from './components/Logs/Logs.vue';
// import Home from './components/Home.vue';
// import Ladefoxx from './components/Demos/Ladefoxx.vue';
import Planning from './components/Planning.vue';
import Provider from './components/Providers/Provider.vue';
import Providers from './components/Providers/Providers.vue';
import Simulations from './components/Simulations/Simulations.vue';
import SystemStatus from './components/SystemStatus.vue';
import Thing from './components/Things/Thing.vue';
import Things from './components/Things/Things.vue';
import Relationships from './components/Relationships/Relationships.vue';

const routes = [
  { path: '*', redirect: '/dashboards' },
  // { path: '/', component: Home, name: 'home' },
  { path: '/', redirect: '/dashboards' },
  // { path: '/map', component: GeoMap, name: 'map' },
  { path: '/map', component: Mapbox, name: 'map' },
  { path: '/map/:center', component: Mapbox, name: 'map-at' },
  // { path: '/map/:center', component: GeoMap, name: 'map-at' },
  // { path: '/maps/:id', component: GeoMap, name: 'map' },
  { path: '/plan/:id', component: Planning, name: 'planning' },
  { path: '/things/:id', component: Thing, name: 'thing' },
  { path: '/things/:id/history', component: Thing, name: 'thing-history' },
  { path: '/things', component: Things, name: 'things' },
  { path: '/logs', component: Logs, name: 'logs' },
  { path: '/dashboards', component: Dashboards, name: 'dashboards' },
  { path: '/connectors/:id', component: Connector, name: 'connector' },
  { path: '/connectors', component: Connectors, name: 'connectors' },
  { path: '/relationships', component: Relationships, name: 'relationships' },
  // { path: "/flows", component: Flows, name: "flows" },
  // { path: "/flows/:id", component: Flow, name: "flow" },
  { path: '/providers', component: Providers, name: 'providers' },
  { path: '/providers/:id', component: Provider, name: 'provider' },
  { path: '/apps', component: Apps, name: 'apps' },
  { path: '/apps/:id', component: App, name: 'app' },
  { path: '/functions', component: Functions, name: 'functions' },
  { path: '/functions/:path', component: FunctionEditor, name: 'function' },
  { path: '/simulations', component: Simulations, name: 'simulations' },
  { path: '/actions', component: Actions, name: 'actions' },
  { path: '/actions/:id', component: Action, name: 'action' },
  { path: '/system-status', component: SystemStatus, name: 'system-status' },
  // demos
  // { path: '/demos/ladefoxx', component: Ladefoxx, name: 'demo:ladefoxx' },
];

export default routes;
