//
//
//
//

import ActionEditor from './ActionEditor.vue';

export default {
  components: {
    ActionEditor,
  },
};
