//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { defineComponent } from '@vue/composition-api';

export default {

  data() {
    return {
      isComponentModalActive: false,
    };
  },

};
