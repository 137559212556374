//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['method', 'value'],
  data() {
    return {
      values: {},
    };
  },
  beforeMount() {
    this.values = this.value || {};
    // ensure all parameters present in values array
    this.method.parameters.forEach((parameter) => {
      if (!(parameter in this.values)) this.values[parameter] = '';
    });
  },
  methods: {
    valueChanged() {
      this.$emit('input', this.values);
    },
  },
};
