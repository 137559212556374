//
//
//
//
//

import ThingsList from './ThingsList.vue';
import ThingCreation from './Creation/ThingCreation.vue';

const DEFAULT_ROUTE = 'list';

// gets called when the route is left or updated
// and displays a confirmation box in case
// unsaved changes might be lost
const creationLeaveSure = (to, from, next) => {
  // return if we are not in "create" mode
  if (typeof from.query.mode === 'undefined' || from.query.mode !== 'create') {
    next();
    return;
  }

  // // return if there are no pending changes
  // if (!this.$store.state.things.changesNotCommitedYet) return next();

  // // ask the user how to continue
  // const answer = window.confirm("Your unsaved changes will be dismissed. Are you sure?");

  // check his answer and act accordingly
  // if (answer) next();
  // else next(false);

  next();
};

export default {
  components: {
    ThingsList,
    ThingCreation,
  },
  beforeRouteLeave: creationLeaveSure,
  beforeRouteUpdate: creationLeaveSure,
  data() {
    return {
      defaultRoute: 'list',
    };
  },
  computed: {
    mode() {
      // extract the mode param from the
      const { mode } = this.$route.query;

      // figure which route is used
      if (typeof mode === 'undefined' || mode.length === 0) return DEFAULT_ROUTE;
      // TODO: check if route is known
      return mode;
    },
  },
};
