//
//
//
//
//
//
//
//
//
//

import CreateConnectorForm from '@/components/generic/CreateConnectorForm.vue';
import ResponsiveLiveTable from '../generic/tables/ResponsiveLiveTable.vue';

export default {
  components: {
    ResponsiveLiveTable,
  },
  methods: {
    navigateTo(name, params) {
      this.$router.push({ name, params });
    },
    launchCreateConnectorModal() {
      this.$buefy.modal.open({
        parent: this,
        component: CreateConnectorForm,
        hasModalCard: true,
        trapFocus: true,
      });
    },
  },
  metaInfo() {
    return {
      title: 'ONE | connectors',
      // ...
    };
  },
  data() {
    return {
      resourceName: 'connectors',
      actions: [
        {
          type: 'button',
          callback() {},
          icon: 'plus',
          label: 'Konnektor hinzufügen',
        },
      ],
      columns: {
        id: {
          label: '#',
          width: 1,
          sortable: true,
        },
        label: {
          label: 'Bezeichnung',
          width: 3,
          sortable: true,
        },
        type: {
          label: 'Art',
          width: 3,
          sortable: true,
        },
        parameters: {
          label: 'Parameter',
          width: 1,
          json: true,
          sortable: false,
        },
      },
    };
  },
};
