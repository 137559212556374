//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueJsonPretty from 'vue-json-pretty';
import EditableText from './EditableText.vue';
import 'vue-json-pretty/lib/styles.css';

export default {
  components: {
    EditableText,
    VueJsonPretty,
  },
  props: ['stateEntry'],
  methods: {
    changed(newValue) {
      let castValue = newValue;
      // TODO: handle all kinds of datatypes
      if (this.stateEntry.datatype === 'boolean') {
        if (newValue === 'true') { castValue = true; } else if (newValue === 'false') castValue = false;
        else { console.error(`Invalid boolean ${newValue}`); return; }
      } else if (this.stateEntry.datatype === 'number') {
        castValue = parseInt(newValue, 10);
      }
      this.$emit('changed', {
        property: this.stateEntry.property,
        from: this.stateEntry.value,
        to: castValue,
      });
    },
  },
};
