//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { DateTime } from 'luxon';
import api from '@/api';

export default {
  data() {
    return {
      ready: false,
      data: [],
    };
  },
  async mounted() {
    this.data = (await api.getResource('functions/getAllTransactions/run', {})).map(
      (transaction, index) => {
        const transformed = transaction;
        transformed.state.startDateTime = DateTime.fromISO(
          transformed.state.startDateTime,
        ).toLocaleString(DateTime.DATETIME_SHORT);
        transformed.state.endDateTime = DateTime.fromISO(
          transformed.state.endDateTime,
        ).toLocaleString(DateTime.DATETIME_SHORT);
        // console.log(transformed);
        transformed.state.triggeredBy = `Person ${index}`;
        return transformed;
      },
    );
  },
};
