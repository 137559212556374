//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['ignore'],
  methods: {
    deviceSelected(deviceId) {
      if (this.ignore.has(deviceId)) return;

      // make vue notify the state of the device
      // if it has never been selected yet
      if (this.selected[deviceId] === undefined) {
        const newSelected = {};
        newSelected[deviceId] = false;
        this.selected = { ...this.selected, ...newSelected };
      }
      this.selected[deviceId] = !this.selected[deviceId];
    },
  },
  computed: {
    numSelected() {
      return Object.keys(this.selected).filter((key) => this.selected[key]).length;
    },
  },
  data() {
    return {
      selected: {},
    };
  },
};
