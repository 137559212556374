//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { mapState } from "vuex";

export default {
  props: ['value'],
  // computed: {
  //   ...mapState("things", ["newThing"])
  // },
  methods: {
    addAttribute() {
      let name = 'name';
      let counter = 0;
      while (name in this.value.attributes) {
        counter += 1;
        name = `name-${parseInt(counter, 10)}`;
      }
      this.$set(this.value.attributes, name, {
        type: 'number',
      });
      this.$emit('input', this.value);
    },
  },
};
