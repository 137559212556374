//
//
//
//
//
//
//
//

import ApexChart from '@components/Charts/Apex/Default.vue';
import api from '@/api';

export default {
  components: {
    ApexChart,
  },
  data() {
    return {
      ready: false,
      series: [],
      options: {
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        yaxis: {
          tickAmount: 10,
          labels: {
            style: {
              fontSize: '12px',
            },
            formatter(value) {
              return `${Math.floor(value)}kWh`;
            },
          },
        },
        xaxis: {
          type: 'category',
        },
        chart: {
          // id: 'gauge',
          // group: 'dashboard-1',
          type: 'bar',
          stacked: true,
        },
        // title: {
        //   text: '',
        // },
      },
    };
  },
  async mounted() {
    const result = await api.getResource('functions/evTransactionStats/run');
    // this.series = [];
    const allCompanies = new Set();
    Object.entries(result.totalChargedAmountPerCompany).forEach(
      // eslint-disable-next-line no-unused-vars
      ([_, statsPerCompany]) => {
        Object.keys(statsPerCompany.ac).forEach((company) => allCompanies.add(company));
        Object.keys(statsPerCompany.dc).forEach((company) => allCompanies.add(company));
      },
    );

    const companyIndex = {};

    let companyCounter = 0;

    this.series = Array.from(allCompanies).map((company, index) => {
      companyCounter += 1;
      const companyName = `Unternehmen ${companyCounter}`;
      // blabla
      companyIndex[company] = index;
      return {
        name: companyName,
        data: [],
      };
    });

    Object.entries(result.totalChargedAmountPerCompany)
      .sort(([monthA], [monthB]) => {
        if (monthA < monthB) return -1;
        if (monthA > monthB) return 1;
        return 0;
      })
      .forEach(
        // eslint-disable-next-line no-unused-vars
        ([month, statsPerCompany]) => {
          // console.log(month);
          if (month === 'null') return;
          const visitedCompanies = {};
          Object.entries(statsPerCompany.total).forEach(([company, value]) => {
            if (value > 0) {
              this.series[companyIndex[company]].data.push(value);
            }
            visitedCompanies[companyIndex[company]] = true;
          });
          // add zero when there was no charging from company in this month
          Array.from(allCompanies).forEach((company) => {
            if (!(companyIndex[company] in visitedCompanies)) {
              this.series[companyIndex[company]].data.push(0);
            }
          });
          // this.series[comp];
        },
      );

    this.options.xaxis.categories = Object.keys(result.totalChargedAmountPerCompany)
      .sort((monthA, monthB) => {
        if (monthA < monthB) return -1;
        if (monthA > monthB) return 1;
        return 0;
      })
      .filter((month) => month !== 'null');

    // console.log(allCompanies);

    this.ready = true;
  },
};
